import React, { Component } from 'react';
import { View, SafeAreaView,Text, TextInput, Dimensions, TouchableOpacity , ScrollView, AsyncStorage , Platform } from 'react-native';
import styles from '../../style/drawer';
import stylesAlert from '../../style/scanpack';
import globalStyles from '../../style/global';
import { connect } from 'react-redux';
import * as RootNavigation from '../root_navigation';
import { SetItem , GetItem } from "../../actions/updateAsyncAction";
import { SubmitLog } from "../../actions/saveLogAction";
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let localLogCheck = false
let updateLogLocal = false

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: "",
      localLogPopUp: false,
      logTime: ""
    };
  }

  componentDidMount(){
    this.getUserInfo()
  }

  getUserInfo = async() => {
    let info = await AsyncStorage.getItem("userInfo")
    if(info){
      let userInfo  = info && JSON.parse(info)
      this.setState({userInfo})
    }
  }

  redirect(page){
    RootNavigation.reset(page)
    this.props.closeDrawer();
  }

  logout = async() =>{
    try{
      await AsyncStorage.removeItem("access_token")
      this.redirect('SignIn');
    } catch(e){
    }
  }

  componentDidUpdate(){

    if(localLogCheck && this.props && this.props.localLog && this.props.localLog.retriveData && this.props.localLog.retriveData !== this.state.localData ){
      localLogCheck = false
      let tempLocal = this.props.localLog && this.props.localLog.retriveData
      if(tempLocal !== " "){
        let tempConvert = tempLocal !== " " && JSON.parse(tempLocal)
        if(tempConvert && tempConvert.length > 0){
          this.setState({localLogPopUp: true})
        }
        this.setState({localData: tempLocal})
      }
      // else{
      //     this.discardChanges() 
      // }
    }else{
      if(localLogCheck && this.props && this.props.localLog && this.props.localLog.retriveData === false){
        localLogCheck = false
        this.discardChanges()
      } 
    }

    if(updateLogLocal && this.props.updateLog.time !== this.state.logTime){
      if(this.props.updateLog && this.props.updateLog.logs && this.props.updateLog.logs.status === "OK" && this.props.updateLog.updateLog && this.props.updateLog.time){
        updateLogLocal = false
        this.setState({logTime: this.props.updateLog.time})
        RootNavigation.reset("OrderList" , "refreshButton")
        this.props.closeDrawer(); 
      }
    }
  }

  clearStore = () => {
    localLogCheck = true
    this.props.GetItem("logsArray")
  }

  dismissPopUp = () => {
    this.setState({localLogPopUp: false})
    RootNavigation.reset("OrderList")
    this.props.closeDrawer();
  }

  discardChanges = async() => {
    await AsyncStorage.removeItem("logsArray")
    await AsyncStorage.removeItem("storeOrder")
    // await AsyncStorage.multiRemove(["logsArray"] , ["storeOrder"])
    RootNavigation.reset("OrderList" , "refreshButton")
    this.props.closeDrawer();
  }

  saveChanges = async() => {
    updateLogLocal = true
    localLogCheck = true
    let getItem = await AsyncStorage.getItem("logsArray")
    let convert = getItem && JSON.parse(getItem)
    this.props.SubmitLog(convert)
  }

  render() {
    const { navigation } = this.props;
    return (
      <React.Fragment>
      {
        this.state.localLogPopUp 
        ?
          <SafeAreaView style={[stylesAlert.alertContainer , {height: windowHeight} ]}>
            <View style={stylesAlert.alertBox}>
              <View style={globalStyles.flexDirectionRow}>
                <Text style={stylesAlert.alertText}>
                </Text>
                <TouchableOpacity onPress={() => this.dismissPopUp()}>
                  <Text style={stylesAlert.alertClose}>
                    X
                  </Text>
                </TouchableOpacity>
              </View>
              <Text style={{textAlign: "center" , fontWeight: "bold" , fontSize: 20}}>
                Are you want to save changes ?
              </Text>
              <View style={{flexDirection: "row" , justifyContent: "center" , margin: 50}}>
                <TouchableOpacity 
                  style={[stylesAlert.alertSubmitBox , {marginRight: 5 , marginLeft: 5 }]}
                  onPress={() => this.saveChanges()}
                >
                  <Text style={stylesAlert.alertSubmitBtn}>
                    Save Changes
                  </Text>
                </TouchableOpacity>
 
                <TouchableOpacity 
                  style={[stylesAlert.alertSubmitBox , {marginRight: 5 , marginLeft: 5 }]}
                  onPress={() => this.discardChanges()}
                >
                  <Text style={stylesAlert.alertSubmitBtn}>
                    Discard Changes
                  </Text>
                </TouchableOpacity>
              </View>  
            </View>
          </SafeAreaView>
        :
          <SafeAreaView style={[styles.drawerBackView , globalStyles.positionFixed]}>
            <View style={styles.drawerMainView}>
              <View style={styles.closeButtonContainer}>
                <Text style={[globalStyles.flex9, {color: "#fff" , fontWeight: "bold" , fontSize: 25 , textAlign: "center"}]}>
                  {
                    this.state.userInfo && this.state.userInfo !== "" &&
                    <Text>{`${this.state.userInfo.username} - `}{this.state.userInfo.current_tenant}</Text>
                  }
                </Text>
                <View style={globalStyles.flex1}>
                  <TouchableOpacity onPress={this.props.closeDrawer}>
                    <Text style={styles.closeButton}>
                      X
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <ScrollView>
                <TouchableOpacity onPress={() => {this.redirect('OrderList')}}>
                  <Text style={styles.textStyle}>
                    Order List
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {this.redirect('ScanPack')}}>
                  <Text style={styles.textStyle}>
                    Scan Pack
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.logout.bind(this)}>
                  <Text style={styles.textStyle}>
                    Logout
                  </Text>
                </TouchableOpacity>
              </ScrollView>
              <View style={styles.refreshButton}>
                <TouchableOpacity onPress={() =>  this.clearStore()}>
                  <Text style={styles.textStyle}>
                    Refresh Orders
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </SafeAreaView>
      }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localLog : state.updateAsync,
    updateLog: state.saveLog
  }
};

const mapDispatchToProps = {
  SetItem,
  GetItem,
  SubmitLog
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
