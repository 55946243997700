import React, { Component  , useRef} from 'react';
import { View, SafeAreaView , Text, TextInput , TouchableOpacity , ScrollView, Alert ,ListView , Platform , AsyncStorage} from 'react-native';
import { connect } from 'react-redux';
import { SearchScanpackOrder } from "../../actions/scanpackAction";
import NetInfo from "@react-native-community/netinfo";
import { GetOrderList , SearchOrder } from "../../actions/orderActions";
import { SetItem , GetItem } from "../../actions/updateAsyncAction";
import { GetGeneralSetting } from "../../actions/userAction";
import globalStyles from '../../style/global';
import styles from '../../style/orderlist';
// import jsondata from "../../../data.json"

let ordersListSuccess = true ;
let searchOrderSuccess = false;
let searchOrderCheck = false;
let generalSettingsCheck = false;
let saveData = false;
let updateComponent = true;

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{
          "filter": "awaiting",
          "order": "DESC",
          "limit": "1",
          "offset": "0",
          "app": "app"
      },
      scanpack:{
          "search": "",
          "order": "DESC",
          "limit": "1",
          "offset": "0",
          "app": "app"
      },
      connection_Status : "",
      updateData: [],
      searchOrdersCheck: []
    };
    this.searchField = React.createRef();
  }

  componentDidMount = async() => {
    if(this.props.route.params == "refreshButton"){
      this.hitApi()
    }else{
      this.checkOrder()
    }
  }

  checkOrder = async() => {
    let order = await AsyncStorage.getItem("storeOrder")
    let orderSet = order && JSON.parse(order)
    if(orderSet && orderSet.orders && orderSet.orders.length !== 0){
      this.setState({orders: orderSet.orders})
      this.getData()
    }else{
      this.hitApi()
    }
  }

  //according to the plateform call the function
  hitApi = () => {
    if(Platform.OS === "ios" || Platform.OS === "android"){
      this.InternetCheck()
    }else{
      this.apiCall()
    }
  }

  //Check the connection for the ios and android
  InternetCheck = async() => {
    const connectionInfo = await NetInfo.fetch();
    if(connectionInfo.isConnected){
      this.apiCall();
    }else{
      this.getData();
    }
  }

  //call the api for data fetch from backend
  apiCall = () => {
    // this.submitData()
    this.props.GetOrderList(this.state.data);
    ordersListSuccess = false
    // this.props.GetGeneralSetting();
  }

  // submitData = async() => {
  //   let orders = await AsyncStorage.getItem("storeOrder")
  //   let temp = JSON.parse(orders)
  //   temp.orders && temp.orders.length > 0 && temp.orders.filter((scanned , index) => {
  //   })
  // }

  componentDidUpdate (){
    let nextProps = this.props
    let scanpack = this.state.scanpack
    let searchOrder =  this.props.SearchScanPackOrder;
    let searchData = searchOrder && searchOrder.data
    let upList =  false
    if(ordersListSuccess === false && this.props && this.props.ordersList && this.state.searchOrdersCheck !== this.props.ordersList){
      ordersListSuccess = true
      scanpack["search"] = ""
      upList = true
      this.setState({searchOrdersCheck: nextProps.ordersList})
      this.updateAsync(nextProps.ordersList)
      this.searchField.current.focus();
    }

    if(nextProps.saveData && nextProps.saveData !== "" && nextProps.saveData !== '{}' && (this.state.updateData !== nextProps.saveData)){
      let temp = nextProps.saveData && JSON.parse(nextProps.saveData)
      if(temp && temp.orders && temp.orders.length > 0){
        saveData =  true
        this.setState({orders: temp.orders })
        if(temp.scan_pack_settings !== ""){
          let setting = JSON.stringify({"ScanPackSettings": temp.scan_pack_settings})
          this.props.SetItem("ScanPackSettings" , setting )
        }
        if(temp.general_settings !== ""){
          let setting = JSON.stringify({"GeneralSetting": temp.general_settings})
          this.props.SetItem("GeneralSetting" , setting )
        }
        this.setState({updateData: nextProps.saveData})
      }
    }

    if(searchOrderSuccess === false && nextProps.searchOrder){
      searchOrderSuccess = true
      scanpack["search"] = ""
      this.setState({orders: nextProps.searchOrder , scanpack })
      this.searchField.current.focus();
    }
    // if(searchOrderCheck === false && searchOrder ){
    //   if(searchData.next_state === "scanpack.rfp.default"){
    //     let data = {
    //       "input": searchData.order_num,
    //       "state": "scanpack.rfo",
    //       "id": null,
    //       "box_id": null,
    //       "store_order_id": null
    //     }
    //     this.props.navigation.navigate("ScanPackItem" , {data: data})
    //   }else if(searchData.next_state === "scanpack.rfp.product_edit"){
    //      this.props.navigation.navigate("ProductEdit" , {data: searchData})
    //   }
    //   searchOrderCheck = true
    // }
    // if(generalSettingsCheck === false && nextProps.generalSettings){
    //   this.setState({generalSettings: nextProps.generalSettings})
    //   generalSettingsCheck = true
    // }

    if(updateComponent === true && this.props && this.props.route && this.props.route.params && this.props.route.params.update === true && this.props.route.params.time){
        this.props.route.params.update = false
        updateComponent = false
        this.updateData()
    }
  }

  //after reirectform scanpack update the record in the async storage
  updateData = async() => {
    let order = await AsyncStorage.getItem("storeOrder")
    let orderSet = order && JSON.parse(order)
    if(orderSet.orders && orderSet.orders.length !== 0){
      this.setState({orders: orderSet.orders})
    }else{
      saveData = false
      this.apiCall()
    }
  }

  updateAsync = (detail) => {
    this.setState({orders: detail.orders})
    let storeDetail = JSON.stringify(detail)
    this.props.SetItem("storeOrder", storeDetail)
    // this.props.saveData = {}"
    this.getData()
  }

  getData = () => {
    this.props.GetItem("storeOrder")
    saveData = false    
  }

  logout = async() =>{
    try {
      await AsyncStorage.removeItem("access_token")
      this.props.navigation.reset({index: 0, routes: [{ name: 'SignIn' }] })
    } catch (e) {
      alert('Failed to clear the async storage.')
    }
  }

  handelChange = (name , value) => {
    let scanpack =  this.state.scanpack;
    if(name === "searchOrder")
    {
      scanpack["search"] = value
    }
    this.setState({scanpack})
  }

  searchProduct = (item) =>{
    if(item.scan_hash !== null){
      updateComponent = true
      this.props.navigation.navigate("ScanPackItem" , {data: item.scan_hash.data})
    }
  }

  searchOrder = () => {
    let scanpack = this.state.scanpack.search
    let reset =  this.state.scanpack
    let setOrder = ""
    let arr  = []
    this.state.orders && this.state.orders.filter((item) => {
      if(item.ordernum == scanpack){
        return setOrder = item
      }
      return null;
    })
    if(setOrder){
      arr.push(setOrder)
      this.setState({orders: arr})
    }else{
      alert("order is not present in the list")
    }
    reset["search"] = ""
    this.setState({reset})
    searchOrderSuccess = false
  }

  itemDetail = (item) => {
    if(item){
      this.props.navigation.navigate("OrderDetail" , {item})
    }
  }

  componentWillUnmount(){
    // ordersListSuccess = true ;
    ordersListSuccess = false;
    searchOrderSuccess = false;
    saveData = false;
  }

  render() {
    return (
      <SafeAreaView style={globalStyles.flex1}>
        <View style={globalStyles.flexDirectionRow}>
          <View style={[globalStyles.flexDirectionRow, globalStyles.flex7]}>
            <TextInput placeholder="Type order to search"
                       name="searchOrder"
                       autoFocus={true}
                       ref={this.searchField}
                       value={this.state.scanpack.search}
                       onChangeText={(text) => {this.handelChange("searchOrder", text)}}
                       onSubmitEditing={this.searchOrder.bind(this)}
                       style={styles.inputBox}/>
          </View>
        </View>
        <ScrollView style={styles.listContainer}>
          {
            this.state.orders && this.state.orders.map((item, index) => {
              return(
                <TouchableOpacity key={index}
                                  /*onPress={this.itemDetail.bind(this, item)}*/
                                  onLongPress={this.searchProduct.bind(this, item)}
                                  delayLongPress={1000}
                                >
                  <View style={styles.orderRow}>
                    <View style={[globalStyles.flex2, styles.orderColumn]}>
                      <Text style={styles.text}>S.No.</Text>
                      <Text style={styles.text}>{index+1}</Text>
                    </View>
                    <View style={[globalStyles.flex3, styles.orderColumn]}>
                      <Text style={styles.text}>Order #</Text>
                        <Text style={styles.text}>{item.order_info.ordernum}</Text>
                    </View>
                    <View style={[globalStyles.flex3, styles.orderColumn]}>
                      <Text style={styles.text}>Store</Text>
                      <Text style={styles.text}>{item.order_info.store_name}</Text>
                    </View>
                    <View style={[globalStyles.flex2, styles.orderColumn]}>
                      <Text style={styles.text}>Item</Text>
                      <Text style={styles.text}>{item.order_info.itemslength}</Text>
                    </View>
                    <View style={[globalStyles.flex3, styles.orderColumn]}>
                      <Text style={styles.text}>Status</Text>
                      <Text style={styles.text}>{item.order_info.status}</Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )
            })
          }
        </ScrollView>
        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={() => this.hitApi()}>
            <Text style={[styles.button]}>
              All Orders
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.logout.bind(this)}>
            <Text style={[styles.button, globalStyles.m_10]}>
              Logout
            </Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ordersList: state.order.list,
    searchOrder: state.order.searchResult,
    SearchScanPackOrder: state.scanpack.searchOrder,
    generalSettings: state.user.settings,
    saveData: state.updateAsync.retriveData
  }
};

const mapDispatchToProps = {
  GetOrderList,
  SearchOrder,
  SearchScanpackOrder,
  GetGeneralSetting,
  SetItem,
  GetItem
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)
