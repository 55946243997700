import React, { Component } from 'react';
import { View, Text, TextInput ,Image , Dimensions , TouchableOpacity , Platform , ScrollView } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';
import restart from "../../../assets/restart.png";
import note from "../../../assets/note.png";
import savechanges from "../../../assets/savechanges.png";
import deleteImage from "../../../assets/delete.png";
import toggle from "../../../assets/dotToggle.png";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class UnscannedItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
     actionBarShow: false
    };
  }
  
  // redirectToItemDetail = (product) => {
  //   if(product && product.product_id){
  //     this.props.navigation.navigate("ProductDetail" , {productInfo: product.product_id , orderID: this.props.order.order.id })
  //   }
  // }

  render(){
    let props =  this.props
    let Order = props.order.order
    return (
        <React.Fragment>
          {
            Order && 
              <ScrollView style={ Platform.OS === "web" ? styles.unscannedItemContainerWeb : {width: "100%", height: "100%", backgroundColor: "#666666"}}>
                {
                  Platform.OS === "web"
                  ? 
                    <View style={{flex: 1}}>
                      {
                        windowWidth > 1024
                        ?
                          <View style={styles.unscannedItemBox}>
                            <Text style={styles.unscannedItemTitleWeb}>Unscanned Items</Text>
                            {
                              props.order && props.order.order && props.order.order.unscanned_items && props.order.order.unscanned_items.length > 0
                                ?
                                props.order.order.unscanned_items && props.order.order.unscanned_items.map((item , index) => {
                                  return(
                                      <View key={index} style={{ backgroundColor: "#336598" , padding: 5 , borderWidth: 5 , borderStyle: "solid" , borderColor: "#000" , borderRadius: 20 , margin: 3}}>
                                        {
                                          ( item.product_type === "single" ) || (item.product_type === "depends")
                                            ?
                                              <View style={[globalStyles.flexDirectionRow]}>
                                                <View style={[globalStyles.flex3 , {height: 80 , width: 80 , borderRadius: 6}]}>
                                                  <Image
                                                    style={{height: "100%" , width: "100%" , borderRadius: 6}}
                                                    source={{uri: item.images.length > 0 ? item.images[0].image : ""}}
                                                  />
                                                </View>
                                                <View style={[styles.scannedItemName ,{  backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6} ]}>
                                                  <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}>
                                                    <Text numberOfLines={1} 
                                                          ellipsizeMode='tail'
                                                          style={styles.scannedItemNameTextWeb}>
                                                      {item.name}
                                                    </Text>
                                                  </TouchableOpacity>  
                                                  <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                    {item.sku}
                                                  </Text>
                                                  <View style={globalStyles.flexDirectionRow}>
                                                    <View style={styles.scannedItemSkuBoxWeb}>
                                                      <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemLocationTextWeb}>{item.location}</Text>
                                                    </View>
                                                    <View style={styles.scannedQtyBoxWeb}>
                                                      <Text style={{fontWeight: "bold"}}>
                                                        <Text> X </Text>
                                                        {item.qty_remaining}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                            :
                                              <View style={[styles.scannedItemName ,{  backgroundColor: "#fff" , height: "100%" , borderRadius: 6 } ]}>
                                                {/*<View style={{borderWidth: 1 , padding: 10 , margin: 2}}>*/}
                                                <View style={[globalStyles.flexDirectionRow , {borderBottomWidth: 1 , borderColor: "#336598" }]}>
                                                  <View style={[styles.scannedItemName,{  backgroundColor: "#fff" , height: "100%" } ]}>
                                                    <Text style={{justifyContent: "center" , fontWeight: "bold" , fontSize: 15 , textAlign: "center"}}>KIT</Text>
                                                  </View>
                                                  <View style={[styles.scannedItemName,{  backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6} ]}>
                                                    <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}>
                                                      <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                        {item.name}
                                                      </Text>
                                                    </TouchableOpacity>
                                                    <View style={globalStyles.flexDirectionRow}>
                                                      <View style={styles.scannedItemSkuBoxWeb}>
                                                        <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemLocationTextWeb}>{item.sku}</Text>
                                                      </View>
                                                      <View style={styles.scannedItemSkuBoxWeb}>
                                                        <Text style={{fontWeight: "bold"}}>
                                                          <Text style={globalStyles.p_5}> X </Text>
                                                          {item.qty_remaining}
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </View>
                                                {
                                                  item.child_items && item.child_items.length > 0 && item.child_items.map((childItem , index) => {
                                                      return(
                                                          <View style={[globalStyles.flexDirectionRow ,{ margin: 2 }]} key={index}>
                                                            <View style={[globalStyles.flex3 , {height: 80 , width: 80 , borderRadius: 6 } ]}>
                                                              <Image
                                                                style={{height: "100%" , width: "100%" , borderRadius: 6 , borderWidth: 1 , borderColor: "#eee" }}
                                                                source={{uri: childItem.images.length > 0 ? childItem.images[0].image : ""}}
                                                              />
                                                            </View>
                                                            <View style={[styles.scannedItemName,{  backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6} ]}>
                                                              <TouchableOpacity onPress={() => this.props.redirectToItemDetail(childItem)}>
                                                                <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                                  {childItem.name}
                                                                </Text>
                                                              </TouchableOpacity>  
                                                              <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                                {childItem.sku}
                                                              </Text>
                                                              <View style={globalStyles.flexDirectionRow}>
                                                                <View style={styles.scannedItemSkuBoxWeb}>
                                                                  <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemLocationTextWeb}>{childItem.location}</Text>
                                                                </View>
                                                                <View style={styles.scannedItemSkuBoxWeb}>
                                                                  <Text style={{fontWeight: "bold"}}>
                                                                    <Text style={globalStyles.p_5}> X </Text>
                                                                    {childItem.qty_remaining}
                                                                  </Text>
                                                                </View>
                                                              </View>
                                                            </View>
                                                          </View>
                                                        )
                                                   })
                                                }
                                              </View>
                                        }
                                      </View>
                                    )
                                })
                                :
                                <View style={globalStyles.p_10}>
                                  <Text style={[styles.scannedItemNameTextWeb, {color: "#fff" , textAlign: "center"}]}>
                                    No more item remaining to scan
                                  </Text>
                                </View>
                            }
                          </View>
                        :
                          <View>
                            <TouchableOpacity onPress={props.navButton}
                                              style={{ position: "fixed" ,
                                                       top: "35%" ,
                                                       backgroundColor: "rgba(255 ,255 ,255 , 0.7)" ,
                                                       writingMode: "vertical-rl" ,
                                                       right: 0 ,
                                                       borderTopLeftRadius: 5 ,
                                                       borderBottomLeftRadius: 5 ,
                                                       zIndex: 999 ,
                                                       height: 150 ,
                                                       textAlign: "center"}}
                                              >
                                                <Text style={{color: "#000" , padding: 15 }}>Scan & Pack</Text>
                            </TouchableOpacity>
                            <View style={styles.unscannedItemBox}>
                              <Text style={styles.unscannedItemTitleWeb}>Unscanned Items</Text>
                              {
                                props.order && props.order.order && props.order.order.unscanned_items.length > 0
                                  ?
                                  props.order.order.unscanned_items && props.order.order.unscanned_items.map((item , index) => {
                                    return(
                                        <View key={index} style={{ backgroundColor: "#336598" , padding: 5 , borderWidth: 5 , borderStyle: "solid" , borderColor: "#000" , borderRadius: 20 , margin: 3}}>
                                          {
                                            ( item.product_type === "single" ) || (item.product_type === "depends")
                                              ?
                                                <View style={[globalStyles.flexDirectionRow]}>
                                                  <View style={[globalStyles.flex3 , {height: 80 , width: 80 , borderRadius: 6}]}>
                                                    <Image
                                                      style={{height: "100%" , width: "100%" , borderRadius: 6}}
                                                      source={{uri: item.images.length > 0 ? item.images[0].image : ""}}
                                                    />
                                                  </View>
                                                  <View style={[styles.scannedItemName ,{  backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6} ]}>
                                                    <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}>
                                                      <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                        {item.name}
                                                      </Text>
                                                    </TouchableOpacity>  
                                                    <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                      {item.sku}
                                                    </Text>
                                                    <View style={globalStyles.flexDirectionRow}>
                                                      <View style={styles.scannedItemSkuBoxWeb}>
                                                        <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemLocationTextWeb}>{item.location}</Text>
                                                      </View>
                                                      <View style={styles.scannedQtyBoxWeb}>
                                                        <Text style={{fontWeight: "bold"}}>
                                                          <Text> X </Text>
                                                          {item.qty_remaining}
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </View>
                                              :
                                                <View style={{ backgroundColor: "#fff" , height: "100%" , borderRadius: 6 }}>
                                                  <View style={[globalStyles.flexDirectionRow , {borderBottomWidth: 1 , borderColor: "#336598" }]}>
                                                    <View style={[styles.scannedItemName,{  backgroundColor: "#fff" , height: "100%" } ]}>
                                                      <Text style={{justifyContent: "center" , fontWeight: "bold" , fontSize: 15 , textAlign: "center"}}>KIT</Text>
                                                    </View>
                                                    <View style={[styles.scannedItemName,{  backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6} ]}>
                                                      <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}>
                                                        <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameTextWeb}>
                                                          {item.name}
                                                        </Text>
                                                      </TouchableOpacity>
                                                      <View style={globalStyles.flexDirectionRow}>
                                                        <View style={styles.scannedItemSkuBoxWeb}>
                                                          <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemLocationTextWeb}>{item.sku}</Text>
                                                        </View>
                                                        <View style={styles.scannedItemSkuBoxWeb}>
                                                          <Text style={{fontWeight: "bold"}}>
                                                            <Text style={globalStyles.p_5}> X </Text>
                                                            {item.qty_remaining}
                                                          </Text>
                                                        </View>
                                                      </View>
                                                    </View>
                                                  </View>

                                                  {
                                                    item.child_items && item.child_items.length > 0 && item.child_items.map((childItem , index) => {
                                                        return(
                                                          <View style={[globalStyles.flexDirectionRow ,{ margin: 2 }]} key={index}>
                                                            <View style={[globalStyles.flex3 , {height: 80 , width: 80 , borderRadius: 6 }]}>
                                                              <Image
                                                                style={{height: "100%" , width: "100%" , borderRadius: 6 , borderWidth: 1 , borderColor: "#eee" }}
                                                                source={{uri: childItem.images.length > 0 ? childItem.images[0].image : ""}}
                                                              />
                                                            </View>
                                                            <View style={[styles.scannedItemName , { backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6}]}>
                                                              <TouchableOpacity onPress={() => this.props.redirectToItemDetail(childItem)}>
                                                                <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameText}>
                                                                  {childItem.name}
                                                                </Text>
                                                              </TouchableOpacity>  
                                                              <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameText}>
                                                                {childItem.sku}
                                                              </Text>
                                                              <View style={globalStyles.flexDirectionRow}>
                                                                <View style={styles.scannedItemSkuBox}>
                                                                  <Text numberOfLines={1} ellipsizeMode='tail' style={styles.scannedItemNameText}>{childItem.location}</Text>
                                                                </View>
                                                                <View style={styles.scannedQtyBox}>
                                                                  <Text style={{fontWeight: "bold"}}>
                                                                    <Text style={globalStyles.p_5}> X </Text>
                                                                    {childItem.qty_remaining}
                                                                  </Text>
                                                                </View>
                                                              </View>
                                                            </View>
                                                          </View>
                                                          )
                                                     })
                                                  }
                                                </View>
                                          }
                                        </View>
                                      )
                                  })
                                  :
                                  <View style={globalStyles.p_10}>
                                    <Text style={[styles.scannedItemNameTextWeb, {color: "#fff" , textAlign: "center"}]}>
                                      No more item remaining to scan
                                    </Text>
                                  </View>
                              }
                            </View>
                          </View>
                      }   
                    </View>
                  :
                    <View>
                      <View style={{height: windowHeight-90 ,width: "100%"}}>
                          <View style={styles.actionButtonsView}>
                            <View style={{position: "relative"}}>
                              <TouchableOpacity onPress={() => this.orderDetail(Order)} style={{marginRight: 45 , marginLeft: 45}}>
                                <Text numberOfLines={1} ellipsizeMode='tail' style={[styles.nowScanningTextView , {textAlign: "center", margin: 8}]}>
                                  Order {Order.increment_id}
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity onPress={() => { let actionBarShow = !this.state.actionBarShow
                                                                  this.setState({actionBarShow})
                                                              }
                                                        }
                                                 style={{position: "absolute" , right: 10}}>
                                <Image style={[styles.nowScanningTextView , {textAlign: "center", marginBottom: 8 , marginTop: 8}]} source={toggle ? toggle : ""} />
                              </TouchableOpacity>
                            </View>
                            {
                              this.state.actionBarShow &&
                                <View style={styles.actionButtonInnerView}>
                                  <TouchableOpacity style={styles.restartButtonDesign} 
                                                    onPress={this.props.restartButton}
                                                    >
                                    <View style={{display: "flex", flexDirection: "row" , alignItems: "center"}}>
                                      <Image style={styles.actionImages} 
                                             source={restart ?  restart : ""} />
                                      {
                                        windowWidth > 375 &&
                                          <React.Fragment>
                                            <View style={{flexDirection: "column" , alignItems: "center"}}> 
                                            <Text style={styles.actionButtonText}>Restart</Text>
                                            <Text style={styles.actionButtonText}>Order</Text>
                                            </View>
                                          </React.Fragment>
                                      }
                                    </View>
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                      onPress={this.props.saveChanges}
                                                      >
                                    <View style={{display: "flex", flexDirection: "row" , alignItems: "center"}}>

                                      <Image style={styles.actionImages} 
                                             source={deleteImage ? deleteImage : ""} />
                                      {
                                        windowWidth > 375 && 
                                          <React.Fragment>
                                            <View style={{flexDirection: "column" , alignItems: "center"}}> 
                                            <Text style={styles.actionButtonText}>Remove</Text>
                                            <Text style={styles.actionButtonText}>Item</Text>
                                            </View>
                                          </React.Fragment>
                                      }
                                    </View>
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                    onPress={this.props.addNote}
                                                    >
                                    <View style={{display: "flex", flexDirection: "row" , alignItems: "center"}}>
                                      <Image style={styles.actionImages}
                                             source={note ?  note : ""} />
                                      {
                                        windowWidth > 375 &&
                                          <React.Fragment>
                                            <View style={{flexDirection: "column" , alignItems: "center", marginLeft: 2}}> 
                                            <Text style={styles.actionButtonText}>Add</Text>
                                            <Text style={styles.actionButtonText}>Note</Text>
                                            </View>
                                          </React.Fragment>
                                      }
                                    </View>
                                  </TouchableOpacity>
                                </View>
                            }    
                          </View>
                          <View style={{backgroundColor:"#2c2c2c" , padding: 10}}>
                              <View style={{flexDirection: "row"}}>
                                <View style={{display: "flex" , alignItems: "center" , justifyContent: "center"}}>
                                  <View style={{height: 10, width: 10, backgroundColor: "#5f80ff", borderRadius: 30, marginRight: 10 , shadowOffset:{  width: 0,  height: 0,  }, shadowColor: '#fff', shadowOpacity: 1.0, }}>
                                  </View>
                                </View>
                                {
                                  Order && 
                                    Order.unscanned_items && 
                                      Order.unscanned_items[0] && 
                                        Order.unscanned_items[0].product_type === "individual"
                                    ?
                                    <React.Fragment>
                                      <TextInput value={this.props.barcodeState}
                                                 autoFocus={true}
                                                 ref={this.props.barcode}
                                                 onSubmitEditing={this.props.scanBarcode}
                                                 onChangeText={this.props.stateUpdate}
                                                 style={{flex:15, paddingLeft: 20 , padding: 5, backgroundColor: "#cbcbca", fontSize: 15, fontWeight: "bold", color: "black", width: "100%", textAlign: "left", borderWidth: 1, borderColor: "#cbcbca", borderRadius: 5}}
                                                 placeholder="Ready For Product Scan"
                                                 placeholderTextColor={"#4c4c4b"}
                                                />
                                      <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0].child_items[0], "unscan") }
                                                        onLongPress={this.props.scanAllItem}
                                                        delayLongPress={1000}
                                                        style={{flex: 4, marginLeft: 5, backgroundColor: "#41970f", textTransform: "uppercase", justifyContent:"center", alignItems: "center", padding: 5, borderRadius: 5}}>
                                        <Text style={{fontWeight: "bold", fontSize: 20, color: "#fff", textShadowColor:'#000', textShadowOffset:{width: 1, height: 1}, textAlign: "center", fontSize: 20 }}>Pass</Text>
                                      </TouchableOpacity>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                      <TextInput value={this.props.barcodeState}
                                                 autoFocus={true}
                                                 ref={this.props.barcode}
                                                 onSubmitEditing={this.props.scanBarcode}
                                                 onChangeText={this.props.stateUpdate}
                                                 style={{flex:15, paddingLeft: 20 , padding: 5, backgroundColor: "#cbcbca", fontSize: 15, fontWeight: "bold", color: "black", width: "100%", textAlign: "left", borderWidth: 1, borderColor: "#cbcbca", borderRadius: 5}}
                                                 placeholder="Ready For Product Scan"
                                                 placeholderTextColor={"#4c4c4b"}
                                                />
                                      <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0] , "unscan") }
                                                        onLongPress={this.props.scanAllItem}
                                                        delayLongPress={1000}
                                                        style={{flex: 4, marginLeft: 5, backgroundColor: "#41970f", textTransform: "uppercase", justifyContent:"center", alignItems: "center", padding: 5, borderRadius: 5}}>
                                        <Text style={{fontWeight: "bold", fontSize: 20, color: "#fff", textShadowColor:'#000', textShadowOffset:{width: 1, height: 1}, textAlign: "center", fontSize: 20 }}>Pass</Text>
                                      </TouchableOpacity>
                                    </React.Fragment>
                                }
                              </View>
                            </View>
                          <ScrollView>
                          {
                            props.order && props.order.order && props.order.order.unscanned_items && props.order.order.unscanned_items.length > 0
                              ?
                              props.order.order.unscanned_items && props.order.order.unscanned_items.map((item , index) => {
                                return(
                                  <View>
                                  {
                                    ( item && item.product_type === "single" ) || (item && item.product_type === "depends")
                                      ?
                                      <View key={index} style={{flexDirection: "row" , margin: 0 , borderRadius:30 , borderWidth: 4  , backgroundColor: "#336598"  , borderColor: "black" , padding:10}}>
                                        <View style={{flex: 20}}>
                                          <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)} 
                                                            style={{backgroundColor: "#fff" , padding: 5 , borderTopLeftRadius: 20 , borderTopRightRadius: 20 }}>
                                            <Text numberOfLines={2}
                                                  style={item.name && item.name.length <= 32 
                                                          ? 
                                                            {fontSize: 20 , maxWidth: "75%"}
                                                          : 
                                                            {fontSize: 16 , maxWidth: "75%"}}
                                                  >
                                                    {item.name}
                                            </Text>
                                          </TouchableOpacity> 
                                          <Text numberOfLines={1}
                                                ellipsizeMode='tail' 
                                                style={{color: "#fff" , fontSize: 20 , fontWeight: "600" , maxWidth: "70%"}}
                                                >
                                                  {item.sku}
                                          </Text>
                                          <View style={{backgroundColor: "#95b0e5" , paddingLeft: 3 , paddingRight: 3}}>
                                            <View style={{maxWidth: "70%" , flexDirection: "row"}}> 
                                              {
                                                item.location !== null && item.location !== "" && item.location !== undefined &&
                                                  <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25 }}>
                                                    <Text>L1:</Text>
                                                    <Text numberOfLines={1}
                                                          ellipsizeMode='tail'
                                                          style={{fontSize: 20 , fontWeight: "bold" , paddingRight: 10}}
                                                          >
                                                            {item.location}
                                                    </Text>
                                                  </View>
                                              }
                                              {
                                                item.custom_product_1 !== null && item.custom_product_1 !== "" && item.custom_product_1 !== undefined &&
                                                  <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                    <Text>C1:</Text>
                                                    <Text numberOfLines={1}
                                                          ellipsizeMode='tail'
                                                          style={{fontSize: 20 , fontWeight: "bold"}}
                                                          >
                                                            {item.custom_product_1}
                                                    </Text>
                                                  </View>
                                              }
                                            </View>
                                          </View>
                                          <View style={{backgroundColor: "#d3def4" , padding: 3 , borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                                            <Text style={{fontSize: 20 , maxWidth: "75%"}}
                                                  numberOfLines={1}
                                                  ellipsizeMode='tail'
                                                  >
                                              {
                                                item &&
                                                  item.barcodes.length > 0 &&
                                                    item.barcodes[0].barcode
                                              }
                                            </Text>
                                          </View>
                                        </View>
                                        
                                        <View style={{flex: 5 , borderBottomRightRadius: 12 , borderTopRightRadius: 12 , position: "absolute" , right: 6, width: "25%" , height: "100%", bottom: 0 , top: 10}}>
                                          <View style={{backgroundColor: "#fff" ,  shadowColor: "#000",shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 8  , borderColor: "#336598"}}>
                                              <Text style={{position: "absolute" , textAlign: "center", fontSize: 18 , fontWeight: "600" , backgroundColor: "#fff" , opacity: 0.7, bottom: 0 , right: 0 , left: 0 , marginLeft: "auto" , marginRight: "auto" , zIndex: 999}}>
                                                {item.scanned_qty}/{item.qty_remaining + item.scanned_qty}
                                              </Text>
                                              <Image source={{uri: item.images.length > 0 ? item.images[0].image : ""}} 
                                                     style={{ height: "100%"  , width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                          </View>
                                        </View>
                                      </View>
                                      :
                                      <View key={index} style={{margin: 0 , borderRadius: 30 ,borderWidth: 4 , borderColor: "black" , backgroundColor: "#3f5163" , padding:10}}>
                                        <View style={{flexDirection: "row"}}>
                                            <View style={{height: 5 , flex: 8 , backgroundColor: "#fff" , borderRadius: 3 , position: "relative"}}>
                                              <Text style={{fontSize: 18 , fontWeight: "bold" , position: "absolute" , left: "50%" , top: -7 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "#3f5163" , paddingLeft: 4 , paddingRight: 4 , color: "#fff"}}>
                                                KIT
                                              </Text>
                                            </View>
                                            <View style={{flex: 2}}>
                                              <Text style={{textAlign: "center" , color: "#fff" , fontSize: 18 , marginTop: -6 , fontWeight: "bold"}}>{item.scanned_qty}/{item.qty_remaining+item.scanned_qty}</Text>
                                            </View>
                                        </View>
                                        <View style={{flexDirection: "row"}}>
                                          <View style={{flex: 8}}>
                                            <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}>
                                              <Text numberOfLines={2} 
                                                    ellipsizeMode='tail'
                                                    style={{fontSize: 16 , color: "#fff" , fontWeight: "600" , paddingRight: 3, paddingLeft: 3 }}
                                                    >
                                                      {item.name}
                                              </Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0].child_items[0]) }> 
                                              <Text numberOfLines={1}
                                                    ellipsizeMode='tail'
                                                    style={{fontSize: 20 , color: "#fff" , fontWeight: "600" , paddingRight: 3 , paddingLeft: 3 , marginBottom: 2 }}>{item.sku}</Text>
                                            </TouchableOpacity>
                                          </View>
                                          {
                                            item.images.length > 0 && item.images[0].image && 
                                              <View style={{flex: 2 , borderRadius: 12 , borderWidth: 12  , maxHeight: 100 , borderColor: "#3f5163"}}>
                                                <Image source={{uri: item.images.length > 0 ? item.images[0].image : ""}} 
                                                       style={{height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                              </View>
                                          }
                                        </View>
                                        {
                                          item && item.child_items && item.child_items.length > 0 && item.child_items.map((childItem , index) => {
                                              return(
                                                <View key={index} style={{flexDirection: "row" , marginBottom: 5 , backgroundColor: "#fff" , borderBottomRightRadius: 20 , borderTopRightRadius: 20}}>
                                                  <View style={{flex: 20}}>
                                                    <View style={{backgroundColor: "#fff"}}></View>
                                                    <TouchableOpacity onPress={() => this.props.redirectToItemDetail(childItem)} style={{backgroundColor: "#fff" , paddingLeft: 5 , paddingTop: 10 }}>
                                                      <Text numberOfLines={2}
                                                            ellipsizeMode='tail'
                                                            style={childItem.name && childItem.name.length <= 32 
                                                              ? 
                                                                {fontSize: 20 , width: "75%"}
                                                              :  
                                                                {fontSize: 16 , width: "75%"}
                                                              }
                                                              >
                                                                {childItem.name}
                                                      </Text>
                                                    </TouchableOpacity>  
                                                    <Text numberOfLines={1} 
                                                          ellipsizeMode='tail'
                                                          style={{backgroundColor: "#3f5163", color: "#fff" , fontSize: 20 , fontWeight: "600" , width: "75%"}}
                                                          >
                                                            {childItem.sku}
                                                    </Text>
                                                    <View style={{backgroundColor: "#a4b4c5" , height: 25}}>
                                                      <View style={{flexDirection: "row" , width: "65%" }}>
                                                      {
                                                        childItem.location !== null && childItem.location !== "" &&
                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                            <Text>L1:</Text>
                                                            <Text numberOfLines={1}
                                                                  ellipsizeMode='tail'
                                                                  style={{fontSize: 20 , fontWeight: "bold" , paddingRight: 10}}
                                                                  >
                                                                    {childItem.location}
                                                            </Text>
                                                          </View>
                                                      }
                                                      {
                                                        childItem.custom_product_1 !== null && childItem.custom_product_1 !== "" &&
                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                            <Text>C1:</Text>
                                                            <Text numberOfLines={1}
                                                                  ellipsizeMode='tail'
                                                                  style={{fontSize: 20 , fontWeight: "bold"}}
                                                                  >
                                                                    {childItem.custom_product_1}
                                                            </Text>
                                                          </View>
                                                      }
                                                      </View>
                                                    </View>
                                                    <View style={{backgroundColor: "#cdd7e2" , borderBottomRightRadius: 20}}>
                                                    <Text numberOfLines={1}
                                                          ellipsizeMode='tail'
                                                          style={{width: "75%" , fontSize: 20}}>
                                                      {
                                                        childItem &&
                                                          childItem.barcodes.length > 0 &&
                                                            childItem.barcodes[0].barcode
                                                      }
                                                    </Text>
                                                    </View>
                                                  </View>
                                                  <View style={{flex: 5 , borderBottomRightRadius: 12 , paddingTop: 10 , position: "absolute" , width: "25%" , right: 0 , top: 0 , bottom: 0 }}>
                                                    <View style={{backgroundColor: "#fff" ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 8  , borderColor: "#3f5163"}}>
                                                        <Text style={{position: "absolute" , textAlign: "center", fontSize: 18 , fontWeight: "600" , backgroundColor: "#fff" , opacity: 0.7, bottom: 0 , right: 0 , left: 0 , marginLeft: "auto" , marginRight: "auto" , zIndex: 999}}>
                                                          {childItem.scanned_qty}/{childItem.qty_remaining+childItem.scanned_qty}
                                                        </Text>
                                                        <Image source={{uri: childItem.images.length > 0 ? childItem.images[0].image : ""}} 
                                                               style={{ height: "100%", width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                                    </View>
                                                  </View>
                                                </View>
                                                )
                                           })
                                        }
                                      </View>
                                  }
                                  </View>    
                                )  
                              })
                              :
                              <View style={globalStyles.p_10}>
                                <Text style={styles.scannedItemNameText}>
                                  No more item remaining to scan
                                </Text>
                              </View>
                          }
                          </ScrollView>
                      </View>
                    </View>
                }
              </ScrollView>
          }    
        </React.Fragment>
    )
  }
}
