//Asyncstorage
export const SET_ITEM_SUCCESS = "SET_ITEM_SUCCESS";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";
export const GET_ITEM_FAIL = "GET_ITEM_FAIL";

//user
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GENERAL_SETTINGS = "GENERAL_SETTINGS";
export const SCANPACK_SETTINGS = "SCANPACK_SETTINGS";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";

//url
export const UPDATE_URL = "UPDATE_URL";
export const GET_URL = "GET_URL";

//scanpack
export const CLICK_SCAN = "CLICK_SCAN";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SEARCH_SCANPACK_ORDER = "SEARCH_SCANPACK_ORDER";
export const SUBMIT_LOG = "SUBMIT_LOG";

//product
export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_SEARCH_PRODUCT = "GET_SEARCH_PRODUCT";
export const UPDATE_PRODUCT_INFO = "UPDATE_PRODUCT_INFO";

//headers
export const ORDER_BACK_BUTTON = "ORDER_BACK_BUTTON";

//order
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_DETAIL = 'ORDER_DETAIL';
export const SEARCH_ORDER = "SEARCH_ORDER";
export const UPDATE_ORDER_QUANTITY = "UPDATE_ORDER_QUANTITY";
export const ADD_ORDER_ITEMS = "ADD_ORDER_ITEMS";
export const REMOVE_ORDER_ITEMS = "REMOVE_ORDER_ITEMS";
export const UPDATE_ORDER_INFO = "UPDATE_ORDER_INFO";