import React from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';

export default function Alert(props){
  return (
    <View style={styles.alertContainer}>
      <View style={styles.alertBox}>
        <View style={globalStyles.flexDirectionRow}>
          <Text style={styles.alertText}>
            Notes
          </Text>
          <TouchableOpacity onPress={props.closeAlert}>
            <Text style={styles.alertClose}>
              X
            </Text>
          </TouchableOpacity>
        </View>
        <TextInput value={props.notes_fromPacker}
                   onChangeText={props.noteUpdate}
                   style={styles.alertInput}
                   onSubmitEditing={props.submitAlert}
                   ref={props.alertFocus}
                   >
        </TextInput>
        <TouchableOpacity onPress={props.submitAlert}
                          style={styles.alertSubmitBox}>
          <Text style={styles.alertSubmitBtn}>
            Submit
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
