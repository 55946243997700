import React, { Component , useRef } from 'react';
import { SafeAreaView , View, Text, TextInput , TouchableOpacity , AsyncStorage , Platform } from 'react-native';
import GestureRecognizer, {swipeDirections} from 'react-native-swipe-gestures';
import { connect } from 'react-redux';
import NetInfo from "@react-native-community/netinfo";
import { GetOrderList , SearchOrder } from "../../actions/orderActions";
import {SearchScanpackOrder} from "../../actions/scanpackAction";
import { SetItem , GetItem } from "../../actions/updateAsyncAction";
import {GetGeneralSetting , GetScanPackSetting} from "../../actions/userAction";
import globalStyles from '../../style/global';
import styles from '../../style/scanpack';

let ordersListSuccess = true ;
let searchOrderCheck = false;
let getOrdersSuccess =  false;
let permit = false;
let saveData = false;
let updateComponent = false;
let formOrderDetailPage = true;
let searchOrderWith = ""

class ScanPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{
        "input": "",
        "state": "scanpack.rfo",
        "id": null,
        "box_id": null,
        "store_order_id": null
      },
      dataList:{
          "filter": "awaiting",
          "order": "DESC",
          "limit": "1",
          "offset": "0",
          "app": "app"
      },
      notPresentOrder:{
        "input": "",
        "state": "scanpack.rfo",
        "id": null,
        "box_id": null,
        "store_order_id": null,
        "app": "app"
      },
      rfoTitle: "Scan the Packing Slip of an Order you'd like to Pack.",
      time: new Date()
    };
    this.inputFocus = React.createRef();
    this.redirect = this.redirect.bind(this);
    this.searchOrder = this.searchOrder.bind(this);
  }

  componentDidMount(){
    this.checkOrder()
  }

  checkOrder = async() => {
    let order = await AsyncStorage.getItem("storeOrder")
    let orderSet = order && JSON.parse(order)
    if(orderSet && orderSet.orders && orderSet.orders.length !== 0){
      // this.setState({orders: orderSet.orders})
      this.setState({rfoTitle: orderSet.orders[0].ordernum})
      this.getData()
    }else{
      this.getOrder()
    }
  }

  componentDidUpdate(){
    let data = this.state.data
    let nextProps = this.props
    let searchOrder =  this.props.searchOrder;
    let searchData = searchOrder.searchOrder && searchOrder.searchOrder.data;
    if(this.props.fetchData.retriveData){
      let tempData = this.props.fetchData.retriveData
      let temp = tempData && JSON.parse(tempData)
      if(getOrdersSuccess === false && temp.orders){
        temp.orders && temp.orders.length > 0
          ?
            this.setState({rfoTitle: temp.orders[0].ordernum })
          :
            this.getOrder()
         getOrdersSuccess = true
      }
    }
    if(formOrderDetailPage && this.props.route.params && this.props.route.params.data && this.props.route.params.data.from && this.props.route.params.data.from === "orderDetailPage"){
      let order_number = this.props.route.params.data.id.basicinfo.increment_id
      let tracking_num = this.props.route.params.data.id.basicinfo.tracking_num
      formOrderDetailPage = false
      if(searchOrderWith === "ordernum"){
        this.callShipmentOrder(order_number)
      }
      if(searchOrderWith === "both"){
        this.callShipmentOrder(order_number)
      }
      if(searchOrderWith === "trackingnum"){
        this.callShipmentOrder(tracking_num)
      }
      // else{
      //   this.callShipmentOrder(order_number)
      // }
    }

    // if(searchOrderCheck === true && searchOrder && searchOrder.time === this.state.time){
    //   formOrderDetailPage = true
    // }

    //update the search order and open the order in scanpack if order is not present on asyncstorage
    if(searchOrderCheck === false && searchOrder && searchOrder.time > this.state.time){
      if(searchData && searchData.order_num){
        if(searchData.next_state === "scanpack.rfp.default"){
          this.updateOrderList(searchData.order)
          // this.props.navigation.navigate("ScanPackItem" , {data: searchData.order[0].scan_hash.data})
          // this.inputFocus.current.focus();
        }else if(searchData.next_state === "scanpack.rfp.product_edit"){
           this.props.navigation.navigate("ProductEdit" , {data: searchData})
        }else{
          this.setState({error: `This order is ${searchData.status}`})
        }
      }
      else{
        this.setState({error: searchOrder && searchOrder.searchOrder.error_messages[0]})
      }
      searchOrderCheck = true
      // data["input"] = ""
      this.setState({time: searchOrder.time})
    }

    //OrderList + scanpacksetting + general setting
    if(ordersListSuccess === false && nextProps.ordersList){
      ordersListSuccess = true
      this.updateAsync(nextProps.ordersList)
    }

    //Save The GeneralSetting & ScanPackSettings to asyncstorage
    if(saveData === false && nextProps.saveData && nextProps.saveData !== "" && nextProps.saveData !== '{}'){
      let temp = nextProps.saveData && JSON.parse(nextProps.saveData)
      if(temp.orders && temp.orders.length > 0){
        saveData =  true
        this.setState({orders: temp.orders })
        if(temp.scan_pack_settings !== ""){
          let setting = JSON.stringify({"ScanPackSettings": temp.scan_pack_settings})
          this.props.SetItem("ScanPackSettings" , setting )
        }
        if(temp.general_settings !== ""){
          let setting = JSON.stringify({"GeneralSetting": temp.general_settings})
          this.props.SetItem("GeneralSetting" , setting )
        }
      }
    }

    //Save general setting to async 
    if(this.props && this.props.generalSettings && this.props.generalSettings !== this.state.generalSettings){
      this.setState({generalSettings: this.props.generalSettings})
      let setting = JSON.stringify({"GeneralSetting": this.props.generalSettings.data.settings})
      this.props.SetItem("GeneralSetting" ,  setting)
    }

    //Save scanpack setting to async 
    if(this.props && this.props.scanpackSettings && this.props.scanpackSettings !== this.state.scanpackSettings){
      this.setState({scanpackSettings: this.props.scanpackSettings})
      let setting = JSON.stringify({"ScanPackSettings": this.props.scanpackSettings.data.settings})
      this.props.SetItem("ScanPackSettings" , setting )
    }

    //Update Component After Scanpack
    if(updateComponent === true && this.props && this.props.route && this.props.route.params && this.props.route.params.update === true && this.props.route.params.time){
      this.props.route.params.update = false
      updateComponent = false
      getOrdersSuccess =  false
      this.updateData()
      // this.getData()
    }
    this.inputFocus.current.focus()
  }

  updateOrderList(searchOrder){
    let orderList = this.state.orders
    let orderFound = false
    if(this.props.fetchData && this.props.fetchData.retriveData){
      let localData = this.props.fetchData.retriveData && JSON.parse(this.props.fetchData.retriveData)
      let searchOrderNumber = searchOrder[0].ordernum
      localData.orders && localData.orders.map((orders ) => {
        if(orders.ordernum === searchOrderNumber){
          orders = searchOrder
          orderFound = true
        }
      })
      if(orderFound === false){
        let updatedList = localData.orders && localData.orders.push(searchOrder[0])
      }
      let finalList = JSON.stringify(localData)
      this.props.SetItem( "storeOrder" , finalList)
      this.props.navigation.navigate("ScanPackItem" , {data: searchOrder[0].scan_hash.data})
    }
  }

  // updateListData = async() => {
  //   let order = await AsyncStorage.getItem("storeOrder")
  //   let orderSet = JSON.parse(order)
  //   if(orderSet && orderSet.length !== 0){
  //     this.setState({orders: orderSet , rfoTitle: orderSet[0].ordernum})
  //     this.getData()
  //   }else{
  //     saveData = false
  //     this.setState({rfoTitle: "Scan the Packing Slip of an Order you'd like to Pack."})
  //     this.apiCall()
  //   }
  // }

  //according to the plateform call the function
  getOrder = () => {
    if(Platform.OS === "ios" || Platform.OS === "android"){
      this.checkConnection()
    }else{
      this.apiCall()
    }
  }

  //Check the connection for the ios and android
  checkConnection = async() => {
    const connectionInfo = await NetInfo.fetch();
    if(connectionInfo.isConnected){
      this.apiCall();
    }else{
      this.getData()
    }
  }

  //call the api for data fetch from backend
  apiCall = () => {
    ordersListSuccess = false
    saveData = false
    updateComponent = true
    this.props.GetOrderList(this.state.dataList);
  }

  //Get the data from async storage
  getData = () => {
    saveData = false
    updateComponent = true
    this.props.GetItem("storeOrder")
  }

  //after reirectform scanpack update the record in the async storage
  updateData = async() => {
    let order = await AsyncStorage.getItem("storeOrder")
    let orderSet = order && JSON.parse(order)
    if(orderSet.orders && orderSet.orders.length !== 0){
      this.setState({orders: orderSet.orders , rfoTitle: orderSet.orders[0].ordernum})
      this.getData()
    }else{
      saveData = false
      this.setState({rfoTitle: "Scan the Packing Slip of an Order you'd like to Pack."})
      this.apiCall()
    }
  }

  //save the list data in the asyncstorage
  updateAsync = (detail) => {
    let storeDetail = JSON.stringify(detail)
    this.props.SetItem("storeOrder", storeDetail)
    this.getData()
  }


  handelChange = (name , value) => {
    let order =  this.state.data;
    if(name === "searchOrder")
    {
      order["input"] = value
    }
    this.setState({order})
  }

  componentWillUnmount(){
    // getOrdersSuccess = false;
    permit = false;
    updateComponent = true;
    formOrderDetailPage = true;
  }

  //Order search
  searchOrder = () => {
    let data = this.state.data
    if(data.input !== ""){
      this.redirect(data.input)
    }
  }

  //filter the order form async storage if present it shows if not we call the api
  redirect = (ordernum) => {
    let data = this.props.fetchData.retriveData
    let temp = data && JSON.parse(data)
    let scanpackSetting = temp.scan_pack_settings
    let sendData = ""
    let availableData = false
    let searchOrderWithNumber = scanpackSetting.scan_by_packing_slip //Order number
    let searchOrderWithTrackingNumber = scanpackSetting.scan_by_shipping_label //Tracking number  
    let withBoth = scanpackSetting.scan_by_packing_slip_or_shipping_label
    //hexadecimal check start//
    if((ordernum.slice(0, 3) === "^#^" && searchOrderWithNumber === true) || (ordernum.slice(0, 3) === "^#^" && withBoth === true) ){
      let hexaDecimal = ordernum.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      let decimal = parseInt(hexaDecimal, 16);
      let decimalStringify = JSON.stringify(decimal)
      if(temp && temp.orders && temp.orders.length > 0){
        temp.orders.filter((order , length) => {
          if(order.order_info.store_order_id === decimalStringify){
            return sendData = order , permit = true
          }
        })
        if(permit === true && sendData !== ""){
          this.props.navigation.navigate("ScanPackItem" , {data: sendData.scan_hash.data})
        }else{
          this.hitApi(ordernum)
        }
        getOrdersSuccess = false
      }else{
        this.hitApi(ordernum)
      }
    }
    //hexadecimal check end//

    //search with order number start//
    if(searchOrderWithNumber === true){
      if(temp && temp.orders && temp.orders.length > 0){
        temp.orders.filter((order , length) => {
          if(order.ordernum === ordernum){
            return sendData = order , permit = true
          }
        })
        if(permit === true && sendData !== ""){
          this.props.navigation.navigate("ScanPackItem" , {data: sendData.scan_hash.data})
        }else{
          this.hitApi(ordernum)
        }
        getOrdersSuccess = false
      }else{
        this.hitApi(ordernum)
      }
      searchOrderWith = "ordernum"
    }
    //search with order number end//

    //search with tracking number start//
    if(searchOrderWithTrackingNumber === true){
      if(temp && temp.orders && temp.orders.length > 0){
        let trackingNum = ordernum
        temp.orders.filter((order , length) => {
          let prefixLength = ordernum.length
          if(order.order_info.tracking_num){
            let ifTrue = ordernum && ordernum.length >= order.order_info.tracking_num.length
            if(ifTrue){
              let trackLength = order.order_info.tracking_num.length
              let trackingLength = ordernum.length
              let cut = trackingLength - trackLength 
              let final = ordernum.slice(cut)
              if(cut === 0){
                if(order.order_info.tracking_num === ordernum){
                  return sendData = order , permit = true , find = true  
                } 
              }else{
                if(final === order.order_info.tracking_num){
                  return sendData = order , permit = true , find = true  
                }
              }
            }
          }
        })
        if(permit === true && sendData !== ""){
          this.props.navigation.navigate("ScanPackItem" , {data: sendData.scan_hash.data})
        }else{
          this.hitApi(ordernum)
        }
        getOrdersSuccess = false
      }else{
        this.hitApi(ordernum)
      } 
      searchOrderWith = "trackingnum"
    }
    //search with tracking number end//

    //search with order & tracking number start//
    if(withBoth === true){
      if(temp && temp.orders && temp.orders.length > 0){
        let trackingNum = ordernum
        let prefixLength = trackingNum.length
        let find = false
        temp.orders.filter((order , length) => {
          if(temp && temp.orders && temp.orders.length > 0){
            temp.orders.filter((order , length) => {
              if(order.ordernum === ordernum && find === false){
                return sendData = order , permit = true , find = true
              }
            })
            if(permit === true && sendData !== ""){
              this.props.navigation.navigate("ScanPackItem" , {data: sendData.scan_hash.data})
            }
            getOrdersSuccess = false
          }
          
          if(order.order_info.tracking_num){
            let ifTrue = trackingNum && trackingNum.length >= order.order_info.tracking_num.length
            if(ifTrue && find === false){
              let trackLength = order.order_info.tracking_num.length
              let trackingLength = trackingNum.length
              let cut = trackingLength - trackLength
              let final = ordernum.slice(cut)
              if(cut === 0){
                if(order.order_info.tracking_num === trackingNum){
                  return sendData = order , permit = true , find = true  
                } 
              }else{
                if(final === order.order_info.tracking_num){
                  return sendData = order , permit = true , find = true  
                }
              }

            }
          }

        })
        if(permit === true && sendData !== ""){
          this.props.navigation.navigate("ScanPackItem" , {data: sendData.scan_hash.data})
        }else{
          this.hitApi(trackingNum)
        }
        getOrdersSuccess = false
      }else{
        this.hitApi(trackingNum)
      } 
      searchOrderWith = "both"
    }
    //search with order & tracking number end//
    let resetInput = this.state.data
    resetInput["input"] = ""
    this.setState({resetInput})
  }

  callShipmentOrder = (order) => {
    let shipmentOrder = this.state.notPresentOrder
    if(order !== ""){
      shipmentOrder.input = order
      this.props.SearchScanpackOrder(shipmentOrder)
      this.props.GetGeneralSetting()
      this.props.GetScanPackSetting()
      searchOrderCheck = false
    }
  }

   //according to the plateform call the function
  hitApi = (ordernum) => {
    if(Platform.OS === "ios" || Platform.OS === "android"){
      this.InternetCheck(ordernum)
    }else{
      this.callShipmentOrder(ordernum)
    }
  }

  //Check the connection for the ios and android
  InternetCheck = async(ordernum) => {
    const connectionInfo = await NetInfo.fetch();
    if(connectionInfo.isConnected){
      this.callShipmentOrder(ordernum)
    }else{
      this.setState({error: "Please check your Internet Connection"})
    }
  }

  rfoTitle(title){
    if(title !== "Scan the Packing Slip of an Order you'd like to Pack."){
      let data = this.props.fetchData.retriveData
      let temp = data && JSON.parse(data)
      let sendData = ""
      // if(searchOrderWithNumber === true){
        if(temp && temp.orders && temp.orders.length > 0){
          temp.orders.filter((order , length) => {
            if(order.ordernum === title){
              return sendData = order , permit = true
            }
          })
          if(permit === true && sendData !== ""){
            this.props.navigation.navigate("ScanPackItem" , {data: sendData.scan_hash.data})
          }else{
            if(ordernum){
              this.hitApi(ordernum)
            }
          }
          getOrdersSuccess = false
        }else{
          if(ordernum){
            this.hitApi(ordernum)
          }
        }
      let resetInput = this.state.data.input
      this.setState({resetInput: ""})
      searchOrderWith = "ordernum"
    }
  }

  render() {
    return (
      <SafeAreaView style={globalStyles.flex1}>
        <View style={globalStyles.flexDirectionRow}>
          <TextInput placeholder="Type order to search"
                     name="searchOrder"
                     autoFocus={true}
                     ref={this.inputFocus}
                     value={this.state.data.input}
                     onChangeText={(text) => {this.handelChange("searchOrder", text)}}
                     onSubmitEditing={this.searchOrder.bind(this)}
                     style={styles.inputBox}/>
        </View>
        <View style={styles.container}>
          {
            this.state.error
            ?
              <Text style={styles.text}>
                {this.state.error}
              </Text>
            :
              <TouchableOpacity 
                onPress={() => this.rfoTitle(this.state.rfoTitle)}
                >
                <Text style={styles.text}>
                    {this.state.rfoTitle}
                </Text>
              </TouchableOpacity>
          }
        </View>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ordersList: state.order.list,
    searchOrder: state.scanpack,
    fetchData: state.updateAsync,
    saveData: state.updateAsync.retriveData,
    generalSettings: state.user.settings,
    scanpackSettings: state.user.scanpackSettings
  }
};

const mapDispatchToProps = {
  GetOrderList,
  SearchScanpackOrder,
  GetItem,
  SetItem,
  GetGeneralSetting,
  GetScanPackSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanPack)
