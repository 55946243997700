import React from 'react';
import { View, Text, Image, Dimensions , TouchableOpacity , Platform , ScrollView } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function ScannedItems(props){
  return (
    <ScrollView style={ Platform.OS === "web" ? styles.scannedItemContainerWeb : styles.scannedItemContainer }>
      {
        Platform.OS === "web"
        ?
          <View style={{flex: 1}}>
            {
              windowWidth > 1024
                ?
                  <View style={styles.scannedItemBox}>
                    <Text style={styles.scannedItemTextWeb}>Scanned Items</Text>
                    {
                      props.order && props.order.order && props.order.order.scanned_items && props.order.order.scanned_items.length > 0
                      ?
                        props.order.order.scanned_items.map((item , index) => {
                          return(
                              <React.Fragment key={index}>
                                {
                                  item.scanned_qty > 0
                                  &&
                                  <View key={index} style={{ backgroundColor: "#336598" , padding: 5 , borderWidth: 5 , borderStyle: "solid" , borderColor: "#000" , borderRadius: 20 , margin: 3}}>
                                    <View style={globalStyles.flexDirectionRow}>
                                      <View style={[globalStyles.flex3 , {height: 80 , width: 80 , borderRadius: 6}]}>
                                        <Image
                                          style={{height: "100%" , width: "100%" , borderRadius: 6}}
                                          source={{uri: item.images && item.images.length > 0 ? item.images[0].image : ""}}
                                        />
                                      </View>
                                      <View style={[styles.scannedItemName ,{ backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6}]}>
                                        <TouchableOpacity>
                                          <Text numberOfLines={1} style={styles.scannedItemNameTextWeb}>
                                            {item.name}
                                          </Text>
                                        </TouchableOpacity>
                                        <Text numberOfLines={1} style={styles.scannedItemNameTextWeb}>
                                          {item.sku}
                                        </Text>
                                        <View style={globalStyles.flexDirectionRow}>
                                          <View style={styles.scannedItemSkuBoxWeb}>
                                            <Text numberOfLines={1} style={styles.scannedItemLocationTextWeb}>{item.location}</Text>
                                          </View>
                                          <View style={styles.scannedQtyBoxWeb}>
                                            <Text style={{fontWeight: "bold"}}>
                                              <Text> X </Text>
                                              {item.scanned_qty}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>                                  
                                }
                              </React.Fragment>
                            )
                        })
                      :
                      <View style={globalStyles.p_10}>
                        <Text style={[styles.scannedItemNameTextWeb , {color: "#fff" , textAlign: "center"}]}>
                          Item not available in the scanned list
                        </Text>
                      </View>
                    }
                  </View>
                :
                  <React.Fragment>
                    <TouchableOpacity onPress={props.navButton} 
                                      style={{ position: "fixed" , 
                                               top: "35%" ,
                                               backgroundColor: "rgba( 225 ,225 ,225 , 0.7)" ,
                                               writingMode: "vertical-rl" ,
                                               left: 0 , 
                                               borderTopRightRadius: 5 , 
                                               borderBottomRightRadius: 5 ,
                                               zIndex: 999 ,
                                               height: 150 ,
                                               textAlign: "center"}}
                                      >
                                        <Text style={{color: "#000" , padding: 15 }}>Scan & Pack</Text>
                    </TouchableOpacity>
                    <View style={styles.scannedItemBox}>
                      <Text style={styles.scannedItemTextWeb}>Scanned Items</Text>
                      {
                        props.order && props.order.order && props.order.order.scanned_items.length > 0
                        ?
                          props.order.order.scanned_items.map((item , index) => {
                            return(
                                <React.Fragment key={index}>
                                  {
                                    item.scanned_qty > 0
                                    &&
                                      <View key={index} style={{ backgroundColor: "#336598" , padding: 5 , borderWidth: 5 , borderStyle: "solid" , borderColor: "#000" , borderRadius: 20 , margin: 3}}>
                                        <View style={globalStyles.flexDirectionRow}>
                                          <View style={[globalStyles.flex3 , {height: 80 , width: 80 , borderRadius: 6}]}>
                                            <Image
                                              style={{height: "100%" , width: "100%" , borderRadius: 6}}
                                              source={{uri: item.images.length > 0 ? item.images[0].image : ""}}
                                            />
                                          </View>
                                          <View style={[styles.scannedItemName ,{ backgroundColor: "#fff" , height: "100%" , margin: "auto" , borderTopRightRadius: 6 , borderBottomRightRadius: 6}]}>
                                            <Text numberOfLines={1} style={styles.scannedItemNameTextWeb}>
                                              {item.name}
                                            </Text>
                                            <Text numberOfLines={1} style={styles.scannedItemNameTextWeb}>
                                              {item.sku}
                                            </Text>
                                            <View style={globalStyles.flexDirectionRow}>
                                              <View style={styles.scannedItemSkuBoxWeb}>
                                                <Text numberOfLines={1} style={styles.scannedItemLocationTextWeb}>{item.location}</Text>
                                              </View>
                                              <View style={styles.scannedQtyBoxWeb}>
                                                <Text style={{fontWeight: "bold"}}>
                                                  <Text> X </Text>
                                                  {item.scanned_qty}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                  }
                                </React.Fragment>    
                              )
                          })
                        :
                        <View style={globalStyles.p_10}>
                          <Text style={[styles.scannedItemNameTextWeb , {color: "#fff" , textAlign: "center"}]}>
                            Item not available in the scanned list
                          </Text>
                        </View>
                      }
                    </View>
                 </React.Fragment>  
            }
          </View>
        :
          <View style={{height: windowHeight-90 ,width: "100%"}}>
            {
              props.order && props.order.order && props.order.order.scanned_items.length > 0
              ?
                props.order.order.scanned_items.map((item , index) => {
                  return(
                    <React.Fragment key={index}>
                      {
                        item.scanned_qty > 0
                        &&
                          <View key={index} style={{flexDirection: "row" , margin: 0 , borderRadius:30 , borderWidth: 4  , backgroundColor: "#336598"  , borderColor: "black" , padding:10}}>
                            <View style={{flex: 20}}>
                              <TouchableOpacity onPress={() => this.redirectToItemDetail(item)} style={{backgroundColor: "#fff" , padding: 5 , borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                <Text numberOfLines={2}
                                      ellipsizeMode='tail'
                                      style={item.name && item.name.length <= 32 ? {fontSize: 20 , width: "75%"} : {fontSize: 16, width: "75%"}}>
                                  {item.name}
                                </Text>
                              </TouchableOpacity>  
                              <Text numberOfLines={1}
                                    ellipsizeMode='tail'
                                    style={{color: "#fff" , fontSize: 20 , fontWeight: "600" , width: "75%"}}
                                    >
                                      {item.sku}
                              </Text>
                              <View style={{backgroundColor: "#95b0e5" , paddingLeft: 3 , paddingRight: 3}}> 
                                <View style={{flexDirection: "row", width: "70%"}}>
                                {
                                  item.location !== null && item.location !== "" &&
                                    <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                      <Text>L1:</Text>
                                      <Text numberOfLines={1} 
                                            ellipsizeMode='tail'
                                            style={{fontSize: 20 , fontWeight: "bold" , paddingRight: 10}}
                                            >
                                              {item.location}
                                      </Text>
                                    </View>
                                }
                                {
                                  item.custom_product_1 !== null && item.custom_product_1 !== "" &&
                                    <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                      <Text>C1:</Text>
                                      <Text numberOfLines={1}
                                            ellipsizeMode='tail'
                                            style={{fontSize: 20 , fontWeight: "bold"}}
                                            >
                                              {item.custom_product_1}
                                      </Text>
                                    </View>
                                }
                                </View>
                              </View>
                              <View style={{backgroundColor: "#d3def4" , padding: 3 , borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                                <Text style={{fontSize: 20, maxWidth: "75%"}} numberOfLines={1} ellipsizeMode='tail'>
                                  {
                                    item &&
                                      item.barcodes.length > 0 &&
                                        item.barcodes[0].barcode
                                  }
                                </Text>
                              </View>
                            </View>
                            
                            <View style={{flex: 5 , backgroundColor: "#d3def4" , borderBottomRightRadius: 12 , borderTopRightRadius: 12 , position: "absolute" , right: 6, width: "25%" , height: "100%", bottom: 0 , top: 10}}>
                              <View style={{backgroundColor: "#fff" ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 8  , borderColor: "#336598"}}>
                                  <Text style={{position: "absolute" , textAlign: "center", fontSize: 18 , fontWeight: "600" , backgroundColor: "#fff" , opacity: 0.7, bottom: 0 , right: 0 , left: 0 , marginLeft: "auto" , marginRight: "auto" , zIndex: 999}}>
                                    {item.scanned_qty}/{item.qty_remaining+item.scanned_qty}
                                  </Text>
                                  <Image source={{uri: item.images.length > 0 ? item.images[0].image : ""}} 
                                         style={ item.name && item.name.length <= 32 
                                                  ? 
                                                    { height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12 }
                                                  : 
                                                    { height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12 }
                                               } />
                              </View>
                            </View>
                          </View>
                      }
                    </React.Fragment>      
                    )
                })
              :
              <View style={globalStyles.p_10}>
                <Text style={styles.scannedItemNameText}>
                  Item not available in the scanned list
                </Text>
              </View>
            }
          </View>
      }    
    </ScrollView>
  );
}
