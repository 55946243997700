import { PRODUCT_DETAIL , GET_ALL_PRODUCT , GET_SEARCH_PRODUCT , UPDATE_PRODUCT_INFO } from '../constants';

const initialState = {};

 const productReducer = (state = initialState, action) => {
  switch(action.type) {
    case PRODUCT_DETAIL:
      return { ...state, product:action.payload };
    case GET_ALL_PRODUCT:
      return { ...state, allProduct: action.payload };
    case GET_SEARCH_PRODUCT: 
      return { ...state, searchProduct: action.payload };	
    case UPDATE_PRODUCT_INFO:
      return { ...state, updateProduct: action.payload };
    default:
      return state;
  }
}

export default productReducer;
