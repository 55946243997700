import React, { useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet,SafeAreaView , Text, View , Alert , Image ,Platform ,Button, TouchableOpacity ,AsyncStorage} from 'react-native';
import * as Linking from 'expo-linking';
import { NavigationContainer , useNavigation } from '@react-navigation/native';
import { createStackNavigator, HeaderBackButton } from '@react-navigation/stack';
import SignIn from './login/signin';
import OrderList from './orders/orderlist';
import OrderDetail from './orders/orderdetail';
import ItemsDetail from './orders/items_detail';
import AddItemToOrder from './orders/add_item_to_order';
import ScanPack from './scanpack/scanpack';
import ScanPackItem from './scanpack/scanpack_item';
import ProductEdit from './scanpack/product_edit';
import ProductDetail from "./products/product_detail";
import PutWall  from  "./putwall/put_wall";
import Drawer from "./drawer/drawer";
import toggle from "../../assets/toggle1.png"
import restart from "../../assets/restart.png"
import note from "../../assets/note.png"
import savechanges from "../../assets/savechanges.png";
import { navigationRef } from './root_navigation';

const Stack = createStackNavigator();
let name = "Next Item"

// const prefix = Linking.makeUrl('https://23a7badd6881.ngrok.io')

export default function Main({ navigation }){
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false)
  let [navShow, setNavShow] = useState(true)
  let [openDrawer, handelDrawer] = useState(false)
  let [restartFlag, restartHandel] = useState(false)
  let restartButtonRef = React.createRef();
  AsyncStorage.getItem('access_token').then((keyValue) => {
    setToken(keyValue)
    setShow(true)
    return keyValue
  });
  // const linking = {
  //   prefixes: [prefix],
  //    config: {
  //     screens: {
  //       OrderList: "/OrderList",
  //       OrderDetail: "/OrderDetail",
  //       ScanPack: "/ScanPack",
  //       ScanPackItem: "/ScanPackItem",
  //       ProductEdit: "/ProductEdit",
  //       ProductDetail: "/ProductDetail",
  //       ItemsDetail: "/ItemsDetail",
  //       // PutWall: "",
  //     }
  //   }
  // };

  function DrawerButton(){
    return (
      <TouchableOpacity onPress={() => {handelDrawer(openDrawer = true)} }
                        style={{flex: 1 , marginLeft: 20  , justifyContent: "center" , height: 20 , width: "100%"}}
                       >
        <Image
          style={{width: "100%" , height: "50%" , color: "#fff" }}
          source={toggle ? toggle : ""}
        />
      </TouchableOpacity>
    );
  }

  function closeDrawer(){
    handelDrawer(openDrawer = false)
  }

  function navBarShow(position){
    setNavShow(navShow = position)
  }

  function restartButton(){
    restartHandel(restartFlag = !restartFlag)
  }

  function restartButtonReset(){
    restartHandel(restartFlag = false) 
  }

  function nav() {
    navigation.canGoBack
  }

  function changeHeading(num) {
    if(num === 0){
      name = "Scanned Item"
    }
    if(num === 1 ){
      name = "Next Item"
    }
    if(num === 2 ){
      name = "Unscanned Item"
    }
  }

  return (
    show &&
    (
      <React.Fragment>
        {/*<NavigationContainer linking={linking} ref={navigationRef}>*/}
        <NavigationContainer ref={navigationRef} style={{zIndex: -1}}>
          <Stack.Navigator initialRouteName={token ? "ScanPack" : "SignIn"} style={{zIndex: -1}}>
            <Stack.Screen name="SignIn"
                          component={SignIn}
                          options={{
                            title: 'Sign In',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            // headerLeft: null,
                            // headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="OrderList"
                          component={OrderList}
                          path="/123"
                          options={{
                            title: 'List',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            headerLeft: null,
                            headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="OrderDetail"
                          component={OrderDetail}
                          options={{
                            title: 'Order Detail',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            headerLeft: null
                            // headerLeft: (navigation) => (<HeaderBackButton  onPress={() => nav(navigation) } />)
                          }}
                          />
            <Stack.Screen name="ScanPack"
                          component={ScanPack}
                          options={{
                            title: 'ScanPack',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            headerLeft: null,
                            headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="ScanPackItem"
                          component={ScanPackItem}
                          initialParams={{ item: navBarShow , restart: restartFlag , restartButton: restartButton , changeHeading: changeHeading}}
                          options={{
                            title: 'ScanPackItem',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            headerLeft: null,
                            headerLeft: () => (<DrawerButton/>),
                            headerRight: () => (<View style={{marginRight: 20}}>{ Platform.OS !== "web" && <Image style={{width: 22, height: 22}} source={savechanges ? savechanges : ""} />}</View>)
                          }}
                          // options={{header: () => (
                          //           <SafeAreaView>
                          //           {
                          //             Platform.OS === "web"
                          //               ?
                          //               <View style={{flexDirection: "row", alignItems: "center" , justifyContent: "space-between" ,backgroundColor: "#336599" , padding: 3 }}>
                          //                 <View style={{height: 50}}>
                          //                   {
                          //                     navShow === true &&
                          //                       <DrawerButton/>
                          //                   }
                          //                 </View>
                          //                 {/*<View style={{ flexDirection: "row", width: "90%" , display: "flex" , alignItems: "center" , justifyContent: "space-around" , marginLeft: 80}}>
                          //                   <TouchableOpacity style={{backgroundSize: "contain" , alignItems: "center" , marginRight: 15}} onPress={(e) => restartButton(e) }>
                          //                     <Image style={{ position: "relative" , width: 20 , height: 20 , marginBottom: 2}} source={restart} />
                          //                     <Text style={{fontSize: 10 , fontWeight: "bold" , color: "#fff"}}>Restart</Text>
                          //                   </TouchableOpacity>
                          //                   <TouchableOpacity style={{alignItems: "center"}}>
                          //                     <Image style={{ position: "relative" , width: 22 , height: 22}} source={note} />
                          //                     <Text style={{fontSize: 10 , fontWeight: "bold" , color: "#fff"}}>Note</Text>
                          //                   </TouchableOpacity>
                          //                 </View>*/}
                          //               </View>
                          //               :
                          //               <View style={{flexDirection: "row", alignItems: "center" , justifyContent: "space-between" ,backgroundColor: "#1e5b60" , padding: 3 }}>
                          //                 <View style={{height: 50}}>
                          //                   {
                          //                     navShow === true &&
                          //                       <DrawerButton/>
                          //                   }
                          //                 </View>
                          //                 <View style={{ flexDirection: "row", justifyContent: "center" , marginLeft: 80}}>
                          //                   <TouchableOpacity style={{backgroundSize: "contain" , alignItems: "center" , marginRight: 15}}>
                          //                     <Image style={{ position: "relative" , width: 20 , height: 20 , marginBottom: 2}} source={restart} />
                          //                     <Text style={{fontSize: 10 , color: "#fff"}}>Restart</Text>
                          //                   </TouchableOpacity>
                          //                   <TouchableOpacity style={{alignItems: "center"}}>
                          //                     <Image style={{ position: "relative" , width: 22 , height: 22}} source={note} />
                          //                     <Text style={{fontSize: 10 , color: "#fff"}}>Note</Text>
                          //                   </TouchableOpacity>
                          //                 </View>
                          //                 <View style={{textAlign: "right" , marginLeft: 10 , marginRight: 10}}>
                          //                   <Text style={{fontSize: 16 , color: "#fff" , fontWeight: "bold"}}>Scan & Pack</Text>
                          //                 </View>
                          //               </View>
                          //           }
                          //           </SafeAreaView>
                          //           )}
                          // }
                          />
            <Stack.Screen name="ProductEdit"
                          component={ProductEdit}
                          options={{
                            title: 'Edit Product',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            // headerLeft: null,
                            // headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="ProductDetail"
                          component={ProductDetail}
                          options={{
                            title: 'Product Detail',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            // headerLeft: null,
                            // headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="ItemsDetail"
                          component={ItemsDetail}
                          options={{
                            title: 'Items Detail',
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            // headerLeft: null,
                            // headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="PutWall"
                          component={PutWall}
                          options={{
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            // headerLeft: null,
                            // headerLeft: () => (<DrawerButton/>)
                          }}
                          />
            <Stack.Screen name="Select Product to add to Order"
                          component={AddItemToOrder}
                          options={{
                            headerStyle: {
                              backgroundColor: '#336599',
                            },
                            headerTintColor: '#fff',
                            headerTitleStyle: {
                              fontWeight: 'bold',
                              textAlign: "center"
                            },
                            // headerLeft: null,
                            // headerLeft: () => (<DrawerButton/>)
                          }}
                          />
          </Stack.Navigator>
        </NavigationContainer>
        {
          openDrawer &&
            <Drawer closeDrawer={closeDrawer} />
        }
      </React.Fragment>
    )
  );
}
