import axios from 'axios';
import { AsyncStorage } from 'react-native';
import { LOGIN_SUCCESS , LOGIN_FAIL , GENERAL_SETTINGS , SCANPACK_SETTINGS ,USER_INFO_SUCCESS} from '../constants';

export function Login(data){
  return async(dispatch) => {
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'post',
          url: `${url}/oauth/token`,
          headers: {"Access-Control-Allow-Origin": "*"},
          data: data
        })
        .then(async (res) => {
          await AsyncStorage.setItem("access_token" , res.data.access_token).then(() => console.log("token====>" , res.data.access_token))
          dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.status,
                    time: new Date()
                  })

        })
        .catch((error) => {
          dispatch({
                    type: LOGIN_FAIL,
                    payload: error
                  })
        })
  }
}

export function UserInfo(){
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'get',
          url: `${url}/home/userinfo.json`,
          headers: {'Authorization': `Bearer ${access_token}`}
        })
        .then( async(res) => {
          let data = JSON.stringify(res.data)
          await AsyncStorage.setItem("userInfo", data)
          dispatch({
                    type: USER_INFO_SUCCESS,
                    payload: res.data
                  })
        })
  }
}

export function GetGeneralSetting(){
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'get',
          url: `${url}/settings/get_settings.json`,
          headers: {'Authorization': `Bearer ${access_token}`}
        })
        .then((res) => {
          dispatch({
                    type: GENERAL_SETTINGS,
                    payload: {data: res.data , time: new Date()}
                  })
        })
        .catch((error) => {
        })
  }
}

export function GetScanPackSetting(){
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'get',
          url: `${url}/settings/get_scan_pack_settings.json`,
          headers: {'Authorization': `Bearer ${access_token}`}
        })
        .then((res) => {
          dispatch({
                    type: SCANPACK_SETTINGS,
                    payload: {data: res.data , time: new Date()}
                  })
        })
        .catch((error) => {
        })
  }
}

