import React from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import styles from '../../style/popupmodel';
import globalStyles from '../../style/global';

export default function PopUpModel(props){
  return (
    <View style={styles.alertContainer}>
      <View style={styles.alertBox}>
        <View style={globalStyles.flexDirectionRow}>
          <Text style={styles.alertText}>
            {props.message}
          </Text>
          <TouchableOpacity onPress={props.closeAlert}>
            <Text style={styles.alertClose}>
              X
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
