import React, { Component , useRef } from 'react';
import { View, Text, TextInput , Image , Dimensions ,TouchableOpacity , ScrollView, Platform , AsyncStorage , Keyboard} from 'react-native';
import GestureRecognizer, {swipeDirections} from 'react-native-swipe-gestures';
import axios from "axios";
import {SearchScanpackOrder} from "../../actions/scanpackAction";
import { Audio } from 'expo-av';
import { connect } from 'react-redux';
import { GetOrderDetail } from '../../actions/orderActions';
import completeSound from "../../../assets/sounds/_order_complete.mp3";
import scanSuccessSound from "../../../assets/sounds/scan_success_pop.mp3";
import scanFailSound from "../../../assets/sounds/scan_fail_shorter.mp3";
import { SetItem , GetItem } from "../../actions/updateAsyncAction";
import { SubmitLog } from "../../actions/saveLogAction";
import {GetGeneralSetting , GetScanPackSetting} from "../../actions/userAction";
import NetInfo from "@react-native-community/netinfo";
import ScannedItems from "./scanned_items";
import UnscannedItems from "./unscanned_items";
import NextItems from "./next_items";
import Notes from "./internal_notes";
import Alert from "./alert";
import PopUpModel from "./pop_up_model";
import ShipmentRecord  from "./shipmentsRecord";
import TypeScanAlert from "./type_scan_alert";
import ResponseView from "./response_view";
import LogView from "./log";
import SerialRecord from "./serial_record";
import ClickScanConfirmationPopUp from "./click_scan_confirmation_pop_up";
import globalStyles from '../../style/global';
import styles from '../../style/scanpack';
import SwipeGesture from '../../helpers/swipe-gesture'
import Swiper from 'react-native-swiper/src';

let searchOrderCheck = false;
let ScanPackSettingsAccess = false;
let GeneralSettingAccess = false;
let orderSaveSuccess = false;
let updateLogSuccess =  true;
let callOrderSuccess = true;
let formOrderDetailPage = true;
let updateSerialLog = {};
let enableSerial = false
let myInput = ""
// let scanSuccessAction = false
// let scanFailAction = false
// let orderCompleteAction = false
// let responseShowAction = false

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

class ScanPackItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipmentOrder:{
        "input": "",
        "state": "scanpack.rfo",
        "id": null,
        "box_id": null,
        "store_order_id": null,
        "app": "app"
      },
      data:{
        "input": "",
        "state": "scanpack.rfo",
        "id": null,
        "box_id": null,
        "store_order_id": null
      },
      orderDetail:{
          "filter": "awaiting",
          "order": "DESC",
          "limit": "1",
          "offset": "0",
          "type": "app"
      },
      type: "",
      log: [],
      localLogs: [],
      firstScreen: true ,
      secondScreen: false ,
      thirdScreen: false,
      logScreen: false,
      bar_code: "",
      alert: false,
      notes_fromPacker: "",
      typeScanAlert: false,
      typeScanCount: "" ,
      notes_toPacker_enable: true,
      showshipments: true,
      errorMessage:  "Please check the internet connection",
      errorMessageShow: false,
      matchOrderShow: true,
      typeScanState: "",
      message: "",
      unscannedItemShow: false,
      nextItemShow: true,
      scannedItemShow: false,
      typeScanDeductCountState: "",
      issueFlag: false,
      noteFlag: false,
      trackingOrderInput: "",
      trackingNumberView: false,
      postScanningFieldLabel: "",
      postScanningMessageDetail: "",
      timeSecond: false,
      postScan: false,
      serialRecordPopUpShow: false,
      serialRecordInput: "",
      serialRecordTwo: "",
      clickScanConfirmInput: "",
      serialRecordOne: "",
      notes_toPackerFlag: true,
      notes_internalFlag: true,
      customer_commentsFlag: true,
      scanSuccessAction: false,
      scanFailAction: false,
      orderCompleteAction: false,
      responseShowAction: false,
      errorMessageClickScanConfirmation: false
    };
    this.barcode = React.createRef();
    this.serialRecord = React.createRef();
    this.multipleTime = this.multipleTime.bind(this);
    this.responseAction = this.responseAction.bind(this);
    this.redirectFun = this.redirectFun.bind(this);
  }

  componentDidMount(){
    this.props.GetItem("ScanPackSettings");
    this.getUserInfo()
    //old functionality
    /*let data = this.props.route && this.props.route.params && this.props.route.params.data
    if(data){
      this.setState({order: data})
    }*/
    //old functionality end

    // if(data){
    //   this.props.SearchScanpackOrder(data)
    // }
    // searchOrderCheck = false
    // this.retrieveData()
  }

  /*RETRIVE THE USER INFO FROM LOCAL*/
  getUserInfo = async() => {
    let info = await AsyncStorage.getItem("userInfo")
    let userInfo  = info && JSON.parse(info)
    this.setState({userInfo})
  }
  /*RETRIVE THE USER INFO FROM LOCAL*/

  componentDidUpdate(){
    let searchOrder =  this.props.searchOrder;
    let searchData = searchOrder && searchOrder.data
    let asyncData =  this.props && this.props.saveData
    let updateLog = this.props && this.props.updateLog
    let order = {"order" : this.props && this.props.route.params && this.props.route.params.data}

    // if(updateFromOrderDetailPage && this.props.route.params.data.from === "orderDetailPage"){
    //   this.setState({order: this.props.route.params.data.order.order})
    //   updateFirstData =  false
    //   updateFromOrderDetailPage = false
    // }

    if(order.order !== this.state.actualOrder){
      this.setState({order: order.order , actualOrder: order.order , localLogs: order.order.order && order.order.order.activities})
    }

    if(callOrderSuccess && this.props.callOrder){
      callOrderSuccess = false
      let order = {order: this.props.callOrder.data &&
                            this.props.callOrder.data.order &&
                              this.props.callOrder.data.order[0].scan_hash.data.order
                  }
      if(order){
        this.setState({order: order , matchOrderShow: true})
      }
    }

    if(asyncData !== ""){
      let convert =  asyncData && JSON.parse(asyncData)
      if(ScanPackSettingsAccess === false && convert.ScanPackSettings){
        ScanPackSettingsAccess = true
        if(convert.ScanPackSettings && convert.ScanPackSettings.post_scanning_option !== "None"){
          let type = convert.ScanPackSettings.post_scanning_option 
          if(type === "Record"){
            this.setState({ postScanningFieldLabel: `Scan Tracking Number for Order ${order.order.order.increment_id}` , postScanningMessageDetail: "Please create the shipping label, adhere it to the package, and scan the tracking number to continue." })
          }
          if(type === "Verify"){
            let postScanMessage = ""
            if(order.order.order.tracking_num !== null && order.order.order.tracking_num !== ""){
              postScanMessage = "Please scan the shipping label."              
            }else{
              postScanMessage = "Your Scan & Pack settings call for shipping label verification but no tracking information was imported with this order. Please scan your confirmation code or press enter to continue."
            }
            this.setState({ postScanningFieldLabel: `Scan Shipping Label for Order ${order.order.order.increment_id}` , postScanningMessageDetail: postScanMessage })
          }
        }
        this.setState({scan_pack_settings: convert.ScanPackSettings})
        this.props.GetGeneralSetting();
      }
      if(GeneralSettingAccess === false && convert.GeneralSetting !== undefined){
        GeneralSettingAccess = true
        this.setState({general_settings: convert.GeneralSetting})
      }
    }

    //Save general setting to async 
    if(this.props && this.props.generalSettings && this.props.generalSettings !== this.state.generalSettings){
      this.setState({generalSettings: this.props.generalSettings , general_settings: this.props.generalSettings.data.data.settings})
    }

    if(this.props.updateLog.time !== this.state.logTime){
      if(this.props.updateLog && this.props.updateLog.logs && this.props.updateLog.logs.status === "OK" && this.props.updateLog.updateLog && this.props.updateLog.time){
        this.setState({logTime: this.props.updateLog.time})
      }
    }

    if(this.props.route.params.restart === true){
      this.props.route.params.restartButton
    }

  }

  nav = async(order , completeOrder) => {
    let id = order && order.order.id
    let arr = []
    let newarr = []
    let orders = await AsyncStorage.getItem("storeOrder")
    arr = orders && JSON.parse(orders)
    arr.orders && arr.orders.map((order , index) => {
        if(order.id && order.id !== id){
          return newarr.push(order)
        }
        return null
    })
    arr.orders = newarr
    let storeDetail = JSON.stringify(arr)
    this.props.SetItem("storeOrder" , storeDetail)
    setTimeout(() => this.redirectFun() , 1000);
    completeOrder && this.multipleTime()
  }

  redirectFun(){
    this.props.navigation.navigate("ScanPack" , {update: true , time: new Date()})
  }

  /*RESPONSE SOUND AND IMAGE START*/
  responseAction = (response) => {
    let { play_success_sound ,show_success_image , success_image_time, play_fail_sound , show_fail_image , fail_image_time, play_order_complete_sound ,show_order_complete_image , order_complete_image_time } = this.state.scan_pack_settings;
    let popup_show_time = 1; // default time
    if(response === "scan_fail"){
      popup_show_time = fail_image_time;
      show_fail_image ?this.setState({scanFailAction: true , responseShowAction: true}) : null
      play_fail_sound ? this.playSound(scanFailSound) : null
      this.setState({bar_code: ""})
    }
    if(response === "scan_success"){
      popup_show_time = success_image_time;
      show_success_image ? this.setState({scanSuccessAction: true , responseShowAction: true}) : null
      play_success_sound ? this.playSound(scanSuccessSound) : null
      this.setState({bar_code: ""})
    }
    if(response === "order_complete"){
      if(this.state.scan_pack_settings.post_scanning_option === "None"){
        response = "complete_order"
      }else{
        let scanType = this.state.scan_pack_settings.post_scanning_option
        this.postScanFunction(scanType)
      }
    }
    if(response === "complete_order" ){
      popup_show_time = order_complete_image_time;
      show_order_complete_image ? this.setState({orderCompleteAction: true , responseShowAction: true }) : null
      play_order_complete_sound ? this.playSound(completeSound) : null
      this.nav(this.state.order , true)
      this.setState({bar_code: ""})
    }
    if(response === "restart_action_complete"){
      this.nav(this.state.order)
      this.setState({bar_code: ""})
    }
    let showTime = popup_show_time*1000
    setTimeout(() => {this.setState({ scanFailAction: false, scanSuccessAction: false, orderCompleteAction: false, responseShowAction: false}) }, showTime); 
    this.updateAction.bind(this);
  }
  /*RESPONSE SOUND AND IMAGE END*/

  playSound = async(response) => {
    const soundObject = new Audio.Sound();
    await soundObject.loadAsync(response);
    await soundObject.playAsync();
    await this.sleep(1000);
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /* SUBMIT TRACKING START */
  trackingSubmit(trackingNumber){
    let setting = this.state.scan_pack_settings
    let trackingLength = trackingNumber.length
    let temp_order = {}
    let num = ""
    let order = this.state.order
    let order_num = order.order.id
    let localLogs = this.state.localLogs
    let strict_cc = this.state.general_settings.strict_cc
    if(setting.post_scanning_option !== "None"){
      if(this.state.timeSecond === true){
        let type = setting.post_scanning_option_second
        if(type === "Record"){
          if(setting.tracking_number_validation_enabled){
            let prefix = setting.tracking_number_validation_prefixes.split(",")
            let validate = ""
            let scan = false
            let scanAccess = false
            prefix.map((prefix , index) => {
              let prefixLength = prefix.length
              validate = trackingNumber.slice(0,prefixLength)
              if(scanAccess === false){
                if(validate === prefix){
                  temp_order["id"] = order_num
                  temp_order["input"] = trackingNumber
                  temp_order["state"] =  'scanpack.rfp.default'
                  temp_order["event"] = "record"
                  localLogs.push(temp_order)
                  scan = true
                  scanAccess = true
                }else{
                  scan = false
                }
              }
            })
            if(scan === true){
              localLogs.push(temp_order)
              this.updateLog(temp_order , "tracking")
              this.responseAction("complete_order")
            }else{
              this.responseAction("scan_fail")
              this.alertBox(`Enter the valid prefix to scan the order`, true)
            }
          }else{
            temp_order["id"] = order_num
            temp_order["input"] = trackingNumber
            temp_order["state"] =  'scanpack.rfp.default'
            temp_order["event"] = "record"
            localLogs.push(temp_order)
            this.updateLog(temp_order , "tracking")
            this.responseAction("complete_order")
          }  
        }
        if(type === "Verify"){
          let trackNumber = order.order.tracking_num
          if(trackNumber !== null){
            let scan = false
            if(this.state.scanSecondTime === true){
              if(trackingNumber === this.state.userInfo.confirmation_code || (strict_cc === false && trackingNumber === "")){
                scan = true
              }
            }
            if(trackingNumber === trackNumber || scan === true){
              temp_order["id"] = order_num
              temp_order["input"] = trackingNumber
              temp_order["state"] =  this.state.trackingStateSecond === "scanpack.rfp.no_match" ? "scanpack.rfp.no_match" : "scanpack.rfp.verifying"
              temp_order["state"] =  
              temp_order["event"] = "verify"
              localLogs.push(temp_order)
              this.updateLog(temp_order , "tracking")
              this.responseAction("complete_order")
              this.setState({trackingStateSecond: ""})
            }else{
              this.responseAction("scan_fail")
              this.setState(
                { trackingStateSecond: "scanpack.rfp.no_match" ,
                  scanSecondTime: true ,
                  postScanningMessageDetail: "The tracking number on the shipping label you just scanned does not match the tracking number imported with that order. Please verify that you have the correct shipping label and try your scan again. If you are aware of the issue and would like to proceed with the next order please scan your confirmation code to continue."
                }
              )
            }
          }else{
            let message =  ""
            if(order.order.tracking_num !== null && order.order.tracking_num !== ""){
              message = "The tracking number on the shipping label you just scanned does not match the tracking number imported with that order. Please verify that you have the correct shipping label and try your scan again. If you are aware of the issue and would like to proceed with the next order please scan your confirmation code to continue."
            }else{
              message = "Your Scan & Pack settings call for shipping label verification but no tracking information was imported with this order. Please scan your confirmation code or press enter to continue."
            }
            if(this.state.postScanSecond){
              if(trackingNumber === this.state.userInfo.confirmation_code || (strict_cc === false && trackingNumber === "")){
                temp_order["id"] = order_num
                temp_order["input"] = trackingNumber
                temp_order["state"] =  'scanpack.rfp.no_tracking_info'
                temp_order["event"] = "verify"
                localLogs.push(temp_order)
                this.updateLog(temp_order , "tracking")
                this.responseAction("complete_order")
                this.setState({postScanSecond: false })
              }else{
                this.responseAction("scan_fail")
              }
            }
            this.setState({postScanSecond: true , postScanningMessageDetail: message})
          }  
        }
      }else{
        let type = setting.post_scanning_option
        if(type === "Record"){
          if(setting.tracking_number_validation_enabled){
            let prefix = setting.tracking_number_validation_prefixes.split(",")
            let validate = ""
            let scan = false
            let scanAccess = false
            prefix.map((prefix , index) => {
              let prefixLength = prefix.length
              validate = trackingNumber.slice(0,prefixLength)
              if(scanAccess === false){
                if(validate === prefix){
                  temp_order["id"] = order_num
                  temp_order["input"] = trackingNumber
                  temp_order["state"] =  'scanpack.rfp.default'
                  temp_order["event"] = "record"
                  scan = true
                  scanAccess = true
                }else{
                  scan = false
                }
              }
            })
            if(scan === true){
              localLogs.push(temp_order)
              this.updateLog(temp_order , "tracking")
              if(setting.post_scanning_option_second !== "None" && setting.post_scanning_option_second !== setting.post_scanning_option){
                this.postScanFunction(setting.post_scanning_option_second , true)
                this.setState({trackingOrderInput: "" })
              }else{
                this.responseAction("complete_order")
              }
            }else{
              this.responseAction("scan_fail")
              this.alertBox(`Enter the valid prefix to scan the order`, true)
            }
          }else{
            temp_order["id"] = order_num
            temp_order["input"] = trackingNumber
            temp_order["state"] =  'scanpack.rfp.default'
            temp_order["event"] = "record"
            if(setting.post_scanning_option_second !== "None" && setting.post_scanning_option_second !== setting.post_scanning_option){
              this.postScanFunction(setting.post_scanning_option_second , true)
              this.setState({trackingOrderInput: "" })
            }else{
              localLogs.push(temp_order)
              this.updateLog(temp_order , "tracking")
              this.responseAction("complete_order")
            }
          }  
        }  
        if(type === "Verify"){
          let trackNumber = order.order.tracking_num
          if( order.order.tracking_num !== null ){
            let scan = false
            if(this.state.scanSecondTime === true){
              if(trackingNumber === this.state.userInfo.confirmation_code || (strict_cc === false && trackingNumber === "")){
                scan = true
              }
            }
            if((trackingNumber.length > order.order.tracking_num.length) || (trackingNumber.length === order.order.tracking_num.length)){
              let tempNum = trackingLength - order.order.tracking_num.length
              num =  trackingNumber.slice(tempNum)
            }
            if(num === order.order.tracking_num || scan === true){
              temp_order["id"] = order_num
              temp_order["input"] = trackingNumber
              temp_order["state"] =  this.state.trackingState === "scanpack.rfp.no_match" ? "scanpack.rfp.no_match" : "scanpack.rfp.verifying"
              temp_order["event"] = "verify"
              if(setting.post_scanning_option_second !== "None" && setting.post_scanning_option_second !== setting.post_scanning_option){
                this.postScanFunction(setting.post_scanning_option_second , true)
              }else{
                localLogs.push(temp_order)
                this.updateLog(temp_order , "tracking")
                this.responseAction("complete_order")
              }
            }else{
              this.responseAction("scan_fail")
              this.setState({trackingState: "scanpack.rfp.no_match" ,  scanSecondTime: true  , postScanningMessageDetail: "The tracking number on the shipping label you just scanned does not match the tracking number imported with that order. Please verify that you have the correct shipping label and try your scan again. If you are aware of the issue and would like to proceed with the next order please scan your confirmation code to continue."})
            }
          }else{
            let message =  ""
            if(order.order.tracking_num !== null && order.order.tracking_num !== ""){
              message = "The tracking number on the shipping label you just scanned does not match the tracking number imported with that order. Please verify that you have the correct shipping label and try your scan again. If you are aware of the issue and would like to proceed with the next order please scan your confirmation code to continue."              
            }else{
              message = "Your Scan & Pack settings call for shipping label verification but no tracking information was imported with this order. Please scan your confirmation code or press enter to continue."
            }
            if(this.state.postScan){
              if(trackingNumber === this.state.userInfo.confirmation_code || (strict_cc === false && trackingNumber === "") ){
                temp_order["id"] = order_num
                temp_order["input"] = trackingNumber
                temp_order["state"] =  'scanpack.rfp.no_tracking_info'
                temp_order["event"] = "verify"
                if(setting.post_scanning_option_second !== "None" && setting.post_scanning_option_second !== setting.post_scanning_option){
                  this.postScanFunction(setting.post_scanning_option_second , true)
                  this.setState({postScan: false })
                }else{
                  localLogs.push(temp_order)
                  this.updateLog(temp_order , "tracking")
                  this.responseAction("complete_order")
                  this.setState({postScan: false })
                }
              }else{
                this.responseAction("scan_fail")
                this.setState({postScanningMessageDetail: message})
              }               
            }
            this.setState({postScan: true , postScanningMessageDetail: message})
          }  
        } 
      }
    }
    this.setState({trackingOrderInput: "" })
  }
  /* SUBMIT TRACKING END */

  postScanFunction = (type , timeSecond) => {
    let scanPack = this.state.scan_pack_settings
    let Order = this.state.order && this.state.order.order
    if(timeSecond){
      if(type === "Record"){
        this.setState(
          { trackingNumberView: true ,
            timeSecond: true ,
            postScanningFieldLabel: `Scan Tracking Number for Order ${Order.increment_id}`,
            postScanningMessageDetail: "Please create the shipping label, adhere it to the package, and scan the tracking number to continue."
          }
        )
      }   
      if(type === "Verify"){
        Order.tracking_num !== null
        ?
          this.setState(
            { trackingNumberView: true ,
              timeSecond: true ,
              postScanningFieldLabel: `Scan Shipping Label for Order ${Order.increment_id}`,
              postScanningMessageDetail: "Please scan the shipping label."
            }
          )
        :
          this.setState(
            { trackingNumberView: true ,
              timeSecond: true ,
              postScanningFieldLabel: `Scan Shipping Label for Order ${Order.increment_id}`,
              postScanSecond: true,
              postScanningMessageDetail: "Your Scan & Pack settings call for shipping label verification but no tracking information was imported with this order. Please scan your confirmation code or press enter to continue." 
            }
          )
      }
    }else{
      if(type === "Record"){
        this.setState(
          { trackingNumberView: true ,
            postScanningFieldLabel: `Scan Tracking Number for Order ${Order.increment_id}`,
            postScanningMessageDetail: "Please create the shipping label, adhere it to the package, and scan the tracking number to continue." 
          }
        )
      }   
      if(type === "Verify"){
        Order.tracking_num !== null
        ?
          this.setState(
            { trackingNumberView: true ,
              postScanningFieldLabel: `Scan Shipping Label for Order ${Order.increment_id}`,
              postScanningMessageDetail: "Please scan the shipping label." 
            }
          )
        :
          this.setState(
            { trackingNumberView: true ,
              postScanningFieldLabel: `Scan Shipping Label for Order ${Order.increment_id}`,
              postScan: true ,
              postScanningMessageDetail: "Your Scan & Pack settings call for shipping label verification but no tracking information was imported with this order. Please scan your confirmation code or press enter to continue." 
            }
          )

      }
    }
  }

  updateAction = async(time) => {
    const soundObject = new Audio.Sound();
    try {
      this.barcode.current.focus()
    } catch (error) {
      }
    await soundObject.unloadAsync();
    this.setState({bar_code: ""})
  }

  /*Send log at the back end method start*/
  multipleTime = async() => {
    let getItem = await AsyncStorage.getItem("logsArray")
    let convert = getItem && JSON.parse(getItem)
    this.props.SubmitLog(convert)
  }
  /*Send log at the back end method end*/

  /* FOR SUBMIT THE NOTE AND ISSUE ACTION BARCODE START */
  submitAlert = () => {
    let order_num =  this.state.order.order.id
    let order = this.state.order
    let notes_fromPacker = this.state.notes_fromPacker
    order.order.notes_fromPacker = notes_fromPacker
    let localLogs = this.state.localLogs
    let temp_order = {}
    let event = ""
    temp_order["message"] = notes_fromPacker;
    temp_order["input"] = "";
    temp_order["id"] = order_num;
    temp_order["order_item_id"] = "";
    temp_order["time"] =  new Date();
    temp_order["state"] =  "";
    temp_order["event"] =  "note"
    temp_order["SKU"] = ""
    temp_order["name"] = this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ""
    if(this.state.issueFlag){
      this.updateLog(temp_order , "issue")
    }
    if(this.state.noteFlag){
      this.updateLog(temp_order , "note")
    }
    // localLogs.push(temp_order)
    this.setState({issueFlag: false , noteFlag: false , alert: false , notes_fromPacker: "" , order})
  }
  /* FOR SUBMIT THE NOTE AND ISSUE ACTION BARCODE END*/

  /* BULK SCAN START */
  scanAllItem = () => {
    let order = this.state.order
    let count = parseInt(this.state.typeScanCount)
    let bar_code = this.state.scan_pack_settings.type_scan_code
    let unscanned_items = order.order.unscanned_items
    let items =  unscanned_items &&  unscanned_items[0]
    let scanned_items = order.order.scanned_items
    let log =  this.state.log
    let order_num =  order.order.id
    let next_state = order.next_state
    let temp_order = {}
    let itemFound = false
    let localLogs = this.state.localLogs
    let SKU = ""
    let orderComplete = false
    if(items.product_type === "individual" && items.child_items && items.child_items.length > 0){
      let serialScanAccess = false
      if( enableSerial || items.child_items[0].record_serial === false && items.child_items[0].second_record_serial === false){
        serialScanAccess =  true
      }else{
        if((items.child_items[0].record_serial) || (items.child_items[0].second_record_serial)) {
          let eventType =  "scan_all_item"
          this.setState({serialRecordPopUpShow: true , type: eventType })
        }
      }
      if(serialScanAccess){
        items.child_items && items.child_items.length> 0 && items.child_items.map((childItem , index) => {
          if(index === 0){
            if(scanned_items.length > 0){
              scanned_items.map((scan) => {
                if(childItem.product_id === scan.product_id && itemFound === false){
                  scan.scanned_qty = childItem.scanned_qty + childItem.qty_remaining
                  childItem.qty_remaining = 0
                  items.child_items.splice(index, 1)
                  itemFound = true
                  SKU = childItem.sku
                  items.child_items.length === 0 && unscanned_items.splice(index , 1)
                }else{
                  if(childItem.scanned_qty === 0 && itemFound === false){
                    childItem.scanned_qty = childItem.scanned_qty + childItem.qty_remaining
                    childItem.qty_remaining = 0
                    scanned_items.push(childItem)
                    items.child_items.splice(index, 1)
                    itemFound = true
                    SKU = childItem.sku
                    items.child_items.length === 0 && unscanned_items.splice(index , 1)                    
                  }
                }
              })
            }else{
              if(itemFound === false){
                childItem.scanned_qty = childItem.scanned_qty + childItem.qty_remaining
                childItem.qty_remaining = 0
                scanned_items.push(childItem)
                items.child_items.splice(index,1)
                itemFound =  true
                SKU = childItem.sku
                items.child_items.length === 0 && unscanned_items.splice(index , 1)
              }
            }
            if(unscanned_items.length === 0){
              orderComplete = true
            }
          }
          return childItem
        })
      }  
    }else{
      if( items && items.qty_remaining > 0 ){
        let serialScanAccess = false
        if( enableSerial || items.record_serial === false && items.second_record_serial === false){
          serialScanAccess =  true
        }else{
          if((items.record_serial) || (items.second_record_serial)) {
            let eventType =  "submit_type_scan"
            this.setState({serialRecordPopUpShow: true , type: eventType })
          }
        }
        if(serialScanAccess){
          unscanned_items.map((unscan , index) => {
            if(index === 0){
              if(scanned_items.length > 0){
                scanned_items.map((scan) => {
                  if(unscan.order_item_id === scan.order_item_id && itemFound === false){
                    scan.scanned_qty = unscan.scanned_qty + unscan.qty_remaining
                    scan.qty_remaining = 0
                    unscanned_items.splice(index, 1)
                    itemFound = true
                    SKU = unscan.sku
                  }else{
                    if(unscan.scanned_qty === 0 && itemFound === false){
                      unscan.scanned_qty = unscan.scanned_qty + unscan.qty_remaining
                      scan.qty_remaining = 0
                      scanned_items.push(unscan)
                      unscanned_items.splice(index, 1)
                      itemFound = true
                      SKU = unscan.sku
                    }
                  }
                })
              }else{
                unscan.scanned_qty = unscan.scanned_qty + unscan.qty_remaining
                unscan.qty_remaining = 0  
                scanned_items.push(unscan)
                unscanned_items.splice(index, 1)
                SKU = unscan.sku
              }
              if(unscanned_items.length === 0){
                orderComplete = true
              }
            }
          return unscan
          })
        }  
      }
    }
    if(this.state.type == "scan_all_item"){
    }else{
      temp_order["input"] = bar_code;
      temp_order["id"] = order_num;
      temp_order["order_item_id"] = items && items.order_item_id;
      temp_order["time"] =  new Date();
      temp_order["state"] =  next_state;
      temp_order["event"] =  "bulk_scan"
      temp_order["SKU"] = SKU ? SKU : ""
      temp_order["name"] = this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ""
      this.updateLog(temp_order , "skip_curser")
      localLogs.push(temp_order)
    }
    this.setState({typeScanAlert: false , typeScanCount: "" })
    enableSerial = false
    if(orderComplete === true){
      this.updateLog(temp_order, "order_complete")
    }else{
      this.responseAction("scan_success")
    }
  }
  /* BULK SCAN END */

  /* TYPE SCAN START*/
  submitTypeScan = () => {
    let order = this.state.order
    let count = parseInt(this.state.typeScanCount)
    let bar_code = this.state.scan_pack_settings.type_scan_code
    let unscanned_items = order.order.unscanned_items
    let items =  unscanned_items &&  unscanned_items[0]
    let scanned_items = order.order.scanned_items
    // let log =  this.state.log
    let order_num =  order.order.id
    let next_state = order.next_state
    let temp_order = {}
    let remain_qty = unscanned_items.length > 0 ? unscanned_items[0].qty_remaining : null
    let scanAccess = false
    let scanpush = false
    let match =  false
    let skip =  false
    let localLogs = this.state.localLogs
    let orderInputType = items.product_type === "individual" && items.child_items.length > 0 ? items.child_items[0].barcodes[0].barcode : items.barcodes[0].barcode 
    let SKU = ""
    if(this.state.type === "submit_type_scan"){
      count = 1 
    }else{
      count = this.state.typeScanState !== this.state.typeScanDeductCountState ? count - 1 : count 
    }
    if(items.product_type === "individual" && items.child_items.length > 0){
      if(items.child_items[0].qty_remaining >= count){
        let serialScanAccess = false
        if( enableSerial || items.child_items[0].record_serial === false && items.child_items[0].second_record_serial === false){
          serialScanAccess =  true
        }else{
          if((items.child_items[0].record_serial) || (items.child_items[0].second_record_serial)) {
            let eventType =  "submit_type_scan"
            this.setState({serialRecordPopUpShow: true , type: eventType })
          }
        }
        if(serialScanAccess){
          items.child_items && items.child_items.map((child , index) => {
            if(index === 0){
              if(scanned_items.length > 0){
                scanned_items.map((scan) => {
                  let count_item = count
                  if(child.product_id === scan.product_id){
                    child.qty_remaining = child.qty_remaining - count_item
                    child.scanned_qty = child.scanned_qty + count_item
                    scan.qty_remaining = child.qty_remaining
                    scan.scanned_qty = child.scanned_qty
                    child.qty_remaining === 0 && items.child_items.splice(index , 1)
                    SKU = child.sku
                  }else{
                    if(child.qty_remaining === count && child.scanned_qty === 0){
                      child.qty_remaining = child.qty_remaining - count_item
                      child.scanned_qty = child.scanned_qty + count_item
                      scan.qty_remaining = child.qty_remaining
                      scan.scanned_qty = child.scanned_qty
                      scanned_items.push(child)
                      child.qty_remaining === 0 && items.child_items.splice(index , 1)
                      items.child_items.length === 0 && unscanned_items.splice(index, 1)
                      SKU = child.sku
                    }else{
                      if(child.qty_remaining >= count && child.scanned_qty === 0){
                        child.qty_remaining = child.qty_remaining - count_item
                        child.scanned_qty = child.scanned_qty + count_item
                        scan.qty_remaining = child.qty_remaining
                        scan.scanned_qty = child.scanned_qty
                        scanned_items.push(child)
                        child.qty_remaining === 0 && items.child_items.splice(index , 1)
                        SKU = child.sku
                      }
                    }
                  }
                })
                if(child.qty_remaining === 0){
                  items.child_items.length === 0 && unscanned_items.splice(index, 1)
                }
              }else{
                if(child.qty_remaining === count){
                  let count_item = count
                  child.qty_remaining = child.qty_remaining - count_item
                  child.scanned_qty = child.scanned_qty + count_item
                  scanned_items.push(child)
                  child.qty_remaining === 0 && items.child_items.splice(index , 1)
                  items.child_items.length === 0 && unscanned_items.splice(index, 1)
                  SKU = child.sku
                }else{
                  if(child.qty_remaining >= count && child.scanned_qty === 0){
                    let count_item = count
                    child.qty_remaining = child.qty_remaining - count_item
                    child.scanned_qty = child.scanned_qty + count_item
                    child.qty_remaining === 0 && items.child_items.splice(index , 1)
                    scanned_items.push(child)
                    SKU = child.sku
                  }
                }
              }
            }  
          })
          if(unscanned_items.length > 0){
            this.responseAction("scan_success")
          }else{
            this.responseAction("order_complete")
          }
        }  
      }else{
        this.alertBox(`The quantity entered exceeds the remaining ${items.child_items[0].qty_remaining} pieces in the order. Please try again` , true)
          skip = true
      }
    }else{
      if( items.qty_remaining >= count ){
        let serialScanAccess = false
        if( enableSerial || items.record_serial === false && items.second_record_serial === false){
          serialScanAccess =  true
        }else{
          if((items.record_serial) || (items.second_record_serial)) {
            let eventType =  "submit_type_scan"
            this.setState({serialRecordPopUpShow: true , type: eventType })
          }
        }
        if(serialScanAccess){
          unscanned_items.map((unscan , index) => {
            if(index === 0){
              if(scanned_items.length > 0){
                scanned_items.map((scan) => {
                  let count_item = count
                  if(unscan.order_item_id === scan.order_item_id){
                    unscan.qty_remaining = unscan.qty_remaining - count_item
                    unscan.scanned_qty = unscan.scanned_qty + count_item
                    scan.qty_remaining = unscan.qty_remaining
                    scan.scanned_qty = unscan.scanned_qty
                    SKU = unscan.sku
                  }else{
                    if(unscan.qty_remaining === count && unscan.scanned_qty === 0){
                      unscan.qty_remaining = unscan.qty_remaining - count_item
                      unscan.scanned_qty = unscan.scanned_qty + count_item
                      scan.qty_remaining = unscan.qty_remaining
                      scan.scanned_qty = unscan.scanned_qty
                      scanned_items.push(unscan)
                      unscanned_items.splice(index, 1)
                      SKU = unscan.sku
                    }else{
                      if(unscan.qty_remaining >= count && unscan.scanned_qty === 0){
                        unscan.qty_remaining = unscan.qty_remaining - count_item
                        unscan.scanned_qty = unscan.scanned_qty + count_item
                        scan.qty_remaining = unscan.qty_remaining
                        scan.scanned_qty = unscan.scanned_qty
                        scanned_items.push(unscan)
                        SKU = unscan.sku
                      }
                    }
                  }
                })
                if(unscan.qty_remaining === 0){
                  unscanned_items.splice(index , 1 )
                }
              }else{
                if(unscan.qty_remaining === count){
                  let count_item = count
                  unscan.qty_remaining = unscan.qty_remaining - count_item
                  unscan.scanned_qty = unscan.scanned_qty + count_item
                  scanned_items.push(unscan)
                  unscanned_items.splice(index, 1)
                  SKU = unscan.sku
                }else{
                  if(unscan.qty_remaining >= count && unscan.scanned_qty === 0){
                    let count_item = count
                    unscan.qty_remaining = unscan.qty_remaining - count_item
                    unscan.scanned_qty = unscan.scanned_qty + count_item
                    scanned_items.push(unscan)
                    SKU = unscan.sku
                  }
                }
              }
              if(unscanned_items.length > 0){
                this.responseAction("scan_success")
              }else{
                this.responseAction("order_complete")
              }
            }
          })
        }  
      }else{
          this.alertBox(`The quantity entered exceeds the remaining ${items.qty_remaining} pieces in the order. Please try again` , true)
          skip = true
      }
    }
    if(this.state.type === "submit_type_scan"){

    }else{
      temp_order["input"] = orderInputType
      temp_order["id"] = order_num
      temp_order["order_item_id"] = items && items.order_item_id
      temp_order["time"] =  new Date()
      temp_order["box_id"] = null
      temp_order["state"] =  'scanpack.rfp.default'
      temp_order["count"] =  count;
      temp_order["event"] =  "type_scan"
      temp_order["SKU"] = SKU ? SKU : ""
      temp_order["name"] = this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ""
      // if(this.state.typeScanState !== unscanned_items[0] && unscanned_items[0].sku){
      //   this.setState({typeScanState: ""})
      // }
      if(skip === false){
        this.updateLog(temp_order)
        localLogs.push(temp_order)
      }
      try {
        this.barcode.current.focus()
      } catch (error) {
        }
    }
    if(count <= remain_qty){
      this.setState({typeScanAlert: false , typeScanCount: ""})
    }
      enableSerial = false
      this.setState({typeScanCount: "" , typeScanDeductCountState: this.state.typeScanState , typeScanAlert: false})
  }
  /* TYPE SCAN END */

  alertBox = (message , skip) => {
    this.setState({popUpModelShow: true , message: message})
    setTimeout(function(){
      this.setState({popUpModelShow: false , message: ""});
      if(skip !== true){
        this.barcode.current.focus()
      }
    }.bind(this),4000);
  }

  closeAlert = () => {
    this.setState({ alert: false ,
                    notes_fromPacker: "" ,
                    typeScanAlert: false ,
                    typeScanCount: 0 ,
                    popUpModelShow: false,
                    message: "",
                    serialRecordPopUpShow: false,
                    serialRecordInput: "",
                    serialRecordTwo: "",
                    serialRecordOne: "",
                    clickScanConfirmation: false,
                    confirmationCheckItem: null,
                    clickScanConfirmInput: "",
                    errorMessageClickScanConfirmation: false
                  })
    enableSerial = false
    this.barcode.current.focus()
  }

  /* CLICK SCAN METHOD START */
  clickScan = (item , fromActionBarcode ) => {
    let scan_pack_settings = this.state.scan_pack_settings
    let access = fromActionBarcode === "actionbarcode" ? scan_pack_settings.click_scan : scan_pack_settings.enable_click_sku 
    if(access && item){
      if(item.click_scan_enabled === "on" || item.click_scan_enabled === "on_with_confirmation"){
        if(item.click_scan_enabled === "on_with_confirmation"){
          this.setState({clickScanConfirmation: true , confirmationCheckItem: item})
        }else{
          let barcode = item.barcodes[0].barcode
          this.scanBarcode(barcode , "item_present")
        }
      }else{
        this.alertBox("Click scan is not enable")
      }
    }
  }
  /* CLICK SCAN METHOD END*/

  /*Click Scan Confirmation Pop Up Start*/
  clickScanConfirmFun = () => {
    let userInfo = this.state.userInfo
    let item = this.state.confirmationCheckItem
    if(item && userInfo){
      if(this.state.userInfo.confirmation_code === this.state.clickScanConfirmInput){
          let barcode = item.barcodes[0].barcode
          this.scanBarcode(barcode , "item_present")
          this.setState({clickScanConfirmation: false, confirmationCheckItem: null , clickScanConfirmInput: ""})
      }else{
        if(this.state.general_settings && this.state.general_settings.strict_cc){
          this.setState({errorMessageClickScanConfirmation: true})
        }else{
          let barcode = item.barcodes[0].barcode
          this.scanBarcode(barcode , "item_present")
          this.setState({clickScanConfirmation: false, confirmationCheckItem: null , clickScanConfirmInput: ""})
        }
      }
    }
  }
  /*Click Scan Confirmation Pop Up End*/

  /*Click Scan Confirmation On Change Start*/
  handleClickScanConfirmInput = (e) => {
    this.setState({clickScanConfirmInput: e , errorMessageClickScanConfirmation: false})
  }
  /*Click Scan Confirmation On Change End*/


  /* RESTART ACTION BARCODE METHOD START*/
  // restart = () => {
  //   let order = this.state.order
  //   if(order){
  //     let temp = []
  //     let unscanned_items = order.order.unscanned_items
  //     let scanned_items = order.order.scanned_items
  //     order.order.scanned_items.map((scan , index) => {
  //         if(scan.qty_remaining !== 0){
  //           scan.qty_remaining = scan.scanned_qty
  //           scan.scanned_qty = 0
  //           unscanned_items.unshift(scan)
  //         }
  //     })
  //     order.order.scanned_items = []
  //   }
  // }
  /* RESTART ACTION BARCODE METHOD END*/

  restartButton = () => {
    let order = this.state.order
    let scanned_items = order.order.scanned_items
    let unscanned_items = order.order.unscanned_items
    let scan_pack_settings = this.state.scan_pack_settings
    let order_num =  order.order.id
    let Log_count = ""
    let SKU = ""
    let product_name = ""
    let localLogs = this.state.localLogs
    let remain_qty = unscanned_items.length > 0 ? unscanned_items[0].qty_remaining : null
    let temp_order = {}
    let ActionBarcode = true
    temp_order["input"] = scan_pack_settings.restart_code;
    temp_order["id"] = order_num;
    temp_order["order_item_id"] = unscanned_items.length > 0 ? unscanned_items[0].order_item_id : (scanned_items.length > 0 ? scanned_items[0].order_item_id : "")
    temp_order["time"] =  new Date()
    temp_order["rem_qty"] = remain_qty
    temp_order["SKU"] = SKU ? SKU : unscanned_items[0].sku
    temp_order["Log_count"] = Log_count
    temp_order["product_name"] = product_name
    temp_order["name"] = this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ""
    temp_order["state"] =  'scanpack.rfp.default'
    temp_order["event"] =  "regular"
    this.updateLog(temp_order , "RESTART")
    if(this.state.serialRecordInput === ""){
      localLogs.push(temp_order)
    }
  }

  addNote = () => {
    if(this.state.order){
      let order = this.state.order
      let notes_fromPacker = order.order.notes_fromPacker
      this.setState({alert: true , notes_fromPacker: notes_fromPacker === null ? "" : notes_fromPacker , noteFlag: true })
    }
  }

  saveChanges = async() => {
    let getItem = await AsyncStorage.getItem("logsArray")
    let count = getItem !== null && JSON.parse(getItem)
    if(count && count.length > 0){
      this.alertBox(`Save Changes Successful`, true)
      this.multipleTime()
    }
  }

  /*SCAN BARCODE METHOD START*/
  scanBarcode = (clickBarcode , type) => {
    if(this.state.order){
      let order = this.state.order
      let scan_pack_settings = this.state.scan_pack_settings
      let localScannedItems =  this.state.scanned_items
      let scanned_items = order.order.scanned_items
      let notes_fromPacker = order.order.notes_fromPacker
      let unscanned_items = order.order.unscanned_items
      let bar_code = this.state.bar_code
      let event_code = this.state.bar_code
      let ActionBarcode =  false
      let remain_qty = unscanned_items.length > 0 ? unscanned_items[0].qty_remaining : null
      if(type === "item_present"){
        bar_code = clickBarcode
        event_code = clickBarcode
      }else{
        bar_code = this.state.bar_code
      }
      let findKit3 = false
      let log =  this.state.log
      let order_num =  order.order.id
      let next_state = order.next_state
      let master_switch = this.state.general_settings.master_switch
      let general_settings = this.state.general_settings
      let temp_order = {}
      let stop_add_log = false;
      let lowercase = false;
      let skipError = false;
      let event_scanned = false;
      let SKU = "";
      let Log_count = "";
      let localLogs = this.state.localLogs
      let product_name = ""
      let rejectLocalLog = true
      let Action_Type = ""
      let order_complete_flag = false
      if(bar_code !== ""){
        if(scan_pack_settings.escape_string_enabled && type !== "item_present"){
          let removeSpaceBarCode = bar_code
          if(scan_pack_settings.first_escape_string_enabled){
            let check = scan_pack_settings.escape_string
            if(removeSpaceBarCode && removeSpaceBarCode.includes(check) && scan_pack_settings.first_escape_string_enabled !== ""){
              let tempIndex = removeSpaceBarCode.indexOf(check)
              bar_code = removeSpaceBarCode.slice(0 , tempIndex)
            }
          }
          if(scan_pack_settings.second_escape_string_enabled){   
            let check = scan_pack_settings.second_escape_string
            if(removeSpaceBarCode && removeSpaceBarCode.includes(check) && scan_pack_settings.second_escape_string !== ""){
              let tempIndex = removeSpaceBarCode.indexOf(check)
              bar_code = removeSpaceBarCode.slice(0 , tempIndex)
            }
          }
        }
        if(scan_pack_settings.note_from_packer_code_enabled){
          if(scan_pack_settings.note_from_packer_code === bar_code){
            stop_add_log = true
            skipError =  true
            this.setState({alert: true , notes_fromPacker: notes_fromPacker === null ? "" : notes_fromPacker , noteFlag: true , bar_code: ""})
          }
        }
        if(scan_pack_settings && scan_pack_settings.restart_code_enabled && scan_pack_settings.restart_code === bar_code){
          skipError =  true
          Action_Type =  "RESTART"
          ActionBarcode = true
          // this.responseAction("restart_action_complete")
        }
        if(scan_pack_settings.type_scan_code_enabled){
          if(scan_pack_settings.type_scan_code === bar_code){
            if(this.state.typeScanState !== ""){
              if(unscanned_items[0].product_type === "individual" && unscanned_items[0].child_items.length > 0){
                if(this.state.typeScanState === unscanned_items[0].child_items[0].sku){
                  if((unscanned_items[0].child_items[0].record_serial) || (unscanned_items[0].child_items[0].second_record_serial)) {
                    this.setState({typeScanAlert: true, type: "submit_type_scan"})
                  }else{
                    this.setState({ typeScanAlert: true })
                  }
                }else{
                  this.alertBox("Please scan the item once before triggering a type-in count.")
                }
              }else{
                if(this.state.typeScanState === unscanned_items[0].sku){
                  if((unscanned_items[0].record_serial) || (unscanned_items[0].second_record_serial)) {
                    this.setState({typeScanAlert: true, type: "submit_type_scan"})
                  }else{
                    this.setState({ typeScanAlert: true })
                  }
                }else{
                  this.alertBox("Please scan the item once before triggering a type-in count.")
                }
              }
            }else{
              this.alertBox("Please scan the item once before triggering a type-in count.")
            }
            skipError =  true
            stop_add_log = true
          }
        }
        if(scan_pack_settings.scanned){
            if(scan_pack_settings.scanned_barcode === bar_code){
              skipError =  true
              event_scanned = true
              let Order = this.state.order && this.state.order.order
              Order["unscanned_items"] = []
              this.setState({Order})
              this.responseAction("order_complete")
              ActionBarcode = true
              // stop_add_log = true
            }
        }
        if(scan_pack_settings.service_issue_code_enabled){
          if(scan_pack_settings.service_issue_code === bar_code){
            this.setState({alert: true , notes_fromPacker: notes_fromPacker === null ? "" : notes_fromPacker , issueFlag: true })
            skipError =  true
          }
        }
        if(scan_pack_settings.click_scan){
          if(scan_pack_settings.click_scan_barcode === bar_code){
            let item = unscanned_items && unscanned_items[0]
            this.clickScan(item , "actionbarcode")
            skipError =  true
            ActionBarcode = true
          }
        }
        if(unscanned_items[0].product_type === "individual"){
          if(unscanned_items[0].child_items && unscanned_items[0].child_items.length > 0){
            if(scan_pack_settings.remove_barcode && scan_pack_settings.remove_barcode === bar_code){
              unscanned_items[0].child_items.shift()
              unscanned_items[0].child_items.length === 0 && unscanned_items.shift()
              this.responseAction("scan_success")
              skipError =  true
              ActionBarcode = true
            }
          }
        }else{
          if(unscanned_items && unscanned_items.length > 0){
            if(scan_pack_settings.remove_barcode && scan_pack_settings.remove_barcode === bar_code){
              unscanned_items.shift()
              this.responseAction("scan_success")
              skipError =  true
              ActionBarcode = true
            }
          }
        }
        if(scan_pack_settings.scanning_sequence === "any_sequence" && master_switch === false){
          bar_code = bar_code.toLowerCase()
          lowercase = true
        }
        if(unscanned_items.length > 0){
          if(scan_pack_settings.scanning_sequence === "any_sequence" ? "any_sequence" : "items_sequence"){
            let scanpush = false
            let itemFound = false
            let scanAccess
            unscanned_items.length > 0 && unscanned_items.map((item , index) => {
              if(item.product_type === "individual" && item.child_items && item.child_items.length > 0){
                if(item.child_items[0].skippable){
                  let skip_code = lowercase === true ? scan_pack_settings.skip_code.toLowerCase() : scan_pack_settings.skip_code
                  if(scan_pack_settings.skip_code_enabled && skip_code === bar_code){
                    item.child_items.splice(index , 1 )
                    itemFound = true
                    this.responseAction("scan_success")
                    if(unscanned_items.length === 0){
                      order_complete_flag = true
                    }
                    ActionBarcode = true
                  }
                }
              }else{
                if(item.skippable){
                  let skip_code = lowercase === true ? scan_pack_settings.skip_code.toLowerCase() : scan_pack_settings.skip_code
                  if(scan_pack_settings.skip_code_enabled && skip_code === bar_code){
                    unscanned_items.splice(index , 1 )
                    itemFound = true
                    this.responseAction("scan_success")
                    if(unscanned_items.length === 0){
                      order_complete_flag = true
                    }
                    ActionBarcode = true
                  }
                }
              }
              scan_pack_settings.scanning_sequence === "items_sequence" ? scanAccess = 0 : scanAccess = index
              if(index === scanAccess){
                {
                  item && item.product_type === "individual" && item.child_items && item.child_items.length > 0
                  ?
                    item && item.child_items.map((childItem , index) => {
                      childItem.barcodes.map((barcode ) => {
                        let Barcode =  lowercase === true ? barcode.barcode.toLowerCase() : barcode.barcode
                        if(Barcode === bar_code && scanpush === false){
                          let serialScanAccess = false
                          if( enableSerial || childItem.record_serial === false && childItem.second_record_serial === false){
                            serialScanAccess =  true
                          }else{
                            if((childItem.record_serial) || (childItem.second_record_serial)) {
                              let eventType =  type === "item_present" ? "clickscan" : "barcode"
                              rejectLocalLog = false
                              this.setState({serialRecordPopUpShow: true , type: eventType })
                            }
                          }

                          if(serialScanAccess){
                            if(scanned_items.length > 0 ){
                              scanned_items.map((scan ) => {
                                scan.barcodes.map((scancode) => {
                                  let Scancode =  lowercase === true ? scancode.barcode.toLowerCase() : scancode.barcode
                                  if(type === "item_present" ? childItem.qty_remaining >= 1 : childItem.qty_remaining >= parseInt(barcode.packing_count)){
                                    if(Scancode === Barcode && Barcode === bar_code){
                                      if(scan.qty_remaining === 1 && scanpush === false){
                                        if(type === "item_present"){
                                          childItem.qty_remaining = childItem.qty_remaining - 1
                                          childItem.scanned_qty = childItem.scanned_qty + 1
                                          scan.qty_remaining = childItem.qty_remaining
                                          scan.scanned_qty = childItem.scanned_qty
                                        }else{
                                          if(childItem.qty_remaining > 0){
                                            childItem.scanned_qty = childItem.scanned_qty + parseInt(barcode.packing_count)
                                            childItem.qty_remaining = childItem.qty_remaining - parseInt(barcode.packing_count)
                                            scan.qty_remaining = childItem.qty_remaining
                                            scan.scanned_qty = childItem.scanned_qty
                                          }
                                        }
                                        item.child_items.splice(scanAccess , 1 )
                                        item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                        this.setState({typeScanState: childItem.sku})
                                        scanpush = true
                                        SKU = childItem.sku
                                        Log_count = barcode.packing_count
                                        product_name = childItem.name
                                      }else{
                                        if(scan.qty_remaining > 0 && scanpush === false){
                                          if(type === "item_present"){
                                            childItem.scanned_qty = childItem.scanned_qty + 1
                                            childItem.qty_remaining = childItem.qty_remaining - 1
                                            scan.qty_remaining = childItem.qty_remaining
                                            scan.scanned_qty = childItem.scanned_qty
                                          }else{
                                            childItem.scanned_qty = childItem.scanned_qty + parseInt(barcode.packing_count)
                                            childItem.qty_remaining = childItem.qty_remaining - parseInt(barcode.packing_count)
                                            scan.qty_remaining = childItem.qty_remaining
                                            scan.scanned_qty = childItem.scanned_qty
                                          }
                                          this.setState({typeScanState: childItem.sku})
                                          scanpush = true
                                          SKU = childItem.sku
                                          Log_count = barcode.packing_count
                                          product_name = childItem.name
                                        }
                                        if(childItem.qty_remaining === 0){
                                          item.child_items.splice(scanAccess , 1 )
                                          item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                        }
                                      }
                                    }else{
                                      if(scancode !== bar_code && childItem.scanned_qty === 0 ){
                                        if(childItem.qty_remaining === 1 && scanpush === false){
                                          if(type === "item_present"){
                                            childItem.qty_remaining = childItem.qty_remaining - 1
                                            childItem.scanned_qty = childItem.scanned_qty + 1
                                            scan.qty_remaining = childItem.qty_remaining
                                            scan.scanned_qty = childItem.scanned_qty
                                          }else{
                                            childItem.qty_remaining = childItem.qty_remaining - parseInt(barcode.packing_count)
                                            childItem.scanned_qty = childItem.scanned_qty + parseInt(barcode.packing_count)
                                            scan.qty_remaining = childItem.qty_remaining
                                            scan.scanned_qty = childItem.scanned_qty
                                          }
                                          scanned_items.push(childItem)
                                          item.child_items.splice(scanAccess , 1 )
                                          item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                          this.setState({typeScanState: childItem.sku})
                                          scanpush = true
                                          SKU = childItem.sku
                                          Log_count = barcode.packing_count
                                          product_name = childItem.name
                                        }else{
                                          if(scanpush === false){
                                            if(type === "item_present"){
                                              childItem.qty_remaining = childItem.qty_remaining - 1
                                              childItem.scanned_qty = childItem.scanned_qty + 1
                                            }else{
                                              childItem.qty_remaining = childItem.qty_remaining - parseInt(barcode.packing_count)
                                              childItem.scanned_qty = childItem.scanned_qty + parseInt(barcode.packing_count)
                                            }
                                            scanned_items.push(childItem)
                                            this.setState({typeScanState: childItem.sku})
                                            scanpush = true
                                            SKU = childItem.sku
                                            Log_count = barcode.packing_count
                                            product_name = childItem.name
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                              })
                            }else{
                              if(Barcode === bar_code && childItem.qty_remaining >= parseInt(barcode.packing_count)){
                                if(childItem.qty_remaining === 1 && scanpush === false ){
                                  if(type === "item_present"){
                                    childItem.qty_remaining = childItem.qty_remaining - 1
                                    childItem.scanned_qty = childItem.scanned_qty + 1
                                  }else{
                                    childItem.qty_remaining = childItem.qty_remaining - parseInt(barcode.packing_count)
                                    childItem.scanned_qty = childItem.scanned_qty + parseInt(barcode.packing_count)
                                  }
                                  scanned_items.push(childItem)
                                  item.child_items.splice(scanAccess , 1 )
                                  item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                  this.setState({typeScanState: childItem.sku})
                                  scanpush = true
                                  SKU = childItem.sku
                                  Log_count = barcode.packing_count
                                  product_name = childItem.name
                                }else{
                                  if(scanpush === false){
                                    if(type === "item_present"){
                                      childItem.qty_remaining = childItem.qty_remaining - 1
                                      childItem.scanned_qty = childItem.scanned_qty + 1
                                    }else{
                                      childItem.qty_remaining = childItem.qty_remaining - parseInt(barcode.packing_count)
                                      childItem.scanned_qty = childItem.scanned_qty + parseInt(barcode.packing_count)
                                    }
                                    scanned_items.push(childItem)
                                    this.setState({typeScanState: childItem.sku})
                                    scanpush = true
                                    SKU = childItem.sku
                                    Log_count = barcode.packing_count
                                    product_name = childItem.name
                                  }
                                }
                              }
                            }
                          }  
                          if(unscanned_items.length > 0){
                            if(serialScanAccess){
                              if(type === "item_present"){
                                this.responseAction("scan_success")
                              }else{
                                if(remain_qty >= parseInt(barcode.packing_count)){
                                  this.responseAction("scan_success")
                                }else{
                                  this.alertBox(`The multi-pack barcode scanned exceeds the quantity remaining`)
                                }
                              }
                            }
                          }else{
                            order_complete_flag = true
                            // this.responseAction("order_complete")
                          }
                          itemFound =  true
                        }
                      })
                    })
                   :
                      item && item.product_type === "depends"
                      ?
                        item && item.barcodes.map((barcode) => {
                          let Barcode =  lowercase === true ? barcode.barcode.toLowerCase() : barcode.barcode
                          if(Barcode === bar_code && scanpush === false){
                            let serialScanAccess = false
                            if( enableSerial || item.record_serial === false && item.second_record_serial === false){
                              serialScanAccess =  true
                            }else{
                              if((item.record_serial) || (item.second_record_serial)) {
                                let eventType =  type === "item_present" ? "clickscan" : "barcode"
                                rejectLocalLog = false
                                this.setState({serialRecordPopUpShow: true , type: eventType })
                              }
                            }
                            if(serialScanAccess){
                              if(scanned_items.length > 0 ){
                                scanned_items.map((scan ) => {
                                  scan.barcodes.map((scancode) => {
                                    let Scancode =  lowercase === true ? scancode.barcode.toLowerCase() : scancode.barcode
                                    if(type === "item_present" ? item.qty_remaining >= 1 : item.qty_remaining >= parseInt(barcode.packing_count)){
                                      if(Scancode === Barcode && Barcode === bar_code){
                                        if(scan.qty_remaining === 1 && scanpush === false){
                                          if(type === "item_present"){
                                            item.qty_remaining = item.qty_remaining - 1
                                            item.scanned_qty = item.scanned_qty + 1
                                            scan.qty_remaining = item.qty_remaining
                                            scan.scanned_qty = item.scanned_qty
                                          }else{
                                            item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                            item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                            scan.qty_remaining = item.qty_remaining
                                            scan.scanned_qty = item.scanned_qty
                                          }
                                          unscanned_items.splice(scanAccess , 1 )
                                          this.setState({typeScanState: item.sku})
                                          scanpush = true
                                          SKU = item.sku
                                          Log_count = barcode.packing_count
                                          product_name = item.name
                                        }else{
                                          if(scan.qty_remaining > 0 && scanpush === false){
                                            if(type === "item_present"){
                                              item.scanned_qty = item.scanned_qty + 1
                                              item.qty_remaining = item.qty_remaining - 1
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }else{
                                              item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                              item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }
                                            this.setState({typeScanState: item.sku})
                                            scanpush = true
                                            SKU = item.sku
                                            Log_count = barcode.packing_count
                                            product_name = item.name
                                          }
                                          if(item.qty_remaining === 0){
                                            unscanned_items.splice(scanAccess , 1 )
                                          }
                                        }
                                      }else{
                                        if(scancode !== bar_code && item.scanned_qty === 0 ){
                                          if(item.qty_remaining === 1 && scanpush === false){
                                            if(type === "item_present"){
                                              item.qty_remaining = item.qty_remaining - 1
                                              item.scanned_qty = item.scanned_qty + 1
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }else{
                                              item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                              item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }
                                            scanned_items.push(item)
                                            unscanned_items.splice(scanAccess , 1 )
                                            this.setState({typeScanState: item.sku})
                                            scanpush = true
                                            SKU = item.sku
                                            Log_count = barcode.packing_count
                                            product_name = item.name
                                          }else{
                                            if(scanpush === false){
                                              if(type === "item_present"){
                                                item.qty_remaining = item.qty_remaining - 1
                                                item.scanned_qty = item.scanned_qty + 1
                                                scan.qty_remaining = item.qty_remaining
                                                scan.scanned_qty = item.scanned_qty
                                              }else{
                                                item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                                item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                                scan.qty_remaining = item.qty_remaining
                                                scan.scanned_qty = item.scanned_qty
                                              }
                                              scanned_items.push(item)
                                              this.setState({typeScanState: item.sku})
                                              scanpush = true
                                              SKU = item.sku
                                              Log_count = barcode.packing_count
                                              product_name = item.name
                                            }
                                          }
                                        }
                                      }
                                    }
                                  })
                                })
                              }else{
                                if(Barcode === bar_code && item.qty_remaining >= parseInt(barcode.packing_count)){ 
                                  if(item.qty_remaining === 1 && scanpush === false ){
                                    if(type === "item_present"){
                                      item.qty_remaining = item.qty_remaining - 1
                                      item.scanned_qty = item.scanned_qty + 1
                                    }else{
                                      item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                      item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                    }
                                    scanned_items.push(item)
                                    unscanned_items.splice(scanAccess , 1 )
                                    this.setState({typeScanState: item.sku})
                                    scanpush = true
                                    SKU = item.sku
                                    Log_count = barcode.packing_count
                                    product_name = item.name
                                  }else{
                                    if(scanpush === false){
                                      if(type === "item_present"){
                                        item.qty_remaining = item.qty_remaining - 1
                                        item.scanned_qty = item.scanned_qty + 1
                                      }else{
                                        item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                        item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                      }
                                      scanned_items.push(item)
                                      this.setState({typeScanState: item.sku})
                                      scanpush = true
                                      SKU = item.sku
                                      Log_count = barcode.packing_count
                                      product_name = item.name
                                    }
                                  }
                                }
                              }
                            }  
                            if(unscanned_items.length > 0 ){
                              if(serialScanAccess){
                                if(type === "item_present"){
                                  this.responseAction("scan_success")
                                }else{
                                  if(remain_qty >= parseInt(barcode.packing_count)){
                                    this.responseAction("scan_success")
                                  }else{
                                    this.alertBox(`The multi-pack barcode scanned exceeds the quantity remaining`)
                                  }
                                }
                              }
                               
                            }else{
                              order_complete_flag = true
                              // this.responseAction("order_complete")
                            }
                            itemFound =  true    
                          }else{
                            item && item.child_items.map((childItem ,index) => {
                              childItem.barcodes.map((barcode ) => {
                              let Barcode =  lowercase === true ? barcode.barcode.toLowerCase() : barcode.barcode
                              let serialScanAccess = false
                              if(Barcode === bar_code && scanpush === false){
                                let tempItem = Object.assign({}, item);
                                let tempItem1 = Object.assign({}, item);
                                let tempItemChildArr = []
                                let tempItem1ChildArr = []
                                let arr = []
                                tempItem.qty_remaining = 1
                                tempItem.product_type = "individual"
                                let kitDivide =  tempItem1.qty_remaining
                                tempItem.child_items.map((child) => {
                                  let newCopy = Object.assign({}, child)
                                  newCopy.qty_remaining = newCopy.qty_remaining/kitDivide
                                  tempItemChildArr.push(newCopy)
                                })
                                tempItem.child_items = tempItemChildArr
                                tempItem1.qty_remaining = tempItem1.qty_remaining - 1
                                tempItem1.child_items.map((childTemp) => {
                                  let newCopy = Object.assign({}, childTemp)
                                  let sub = newCopy.qty_remaining / kitDivide
                                  let final = newCopy.qty_remaining - sub
                                  newCopy.qty_remaining = final
                                  tempItem1ChildArr.push(newCopy)
                                })
                                tempItem1.child_items = tempItem1ChildArr
                                arr.push(tempItem , tempItem1)
                                unscanned_items[0] = arr[0]
                                if(arr[1].qty_remaining >= 1){
                                  unscanned_items.splice(1 , 1 , arr[1])
                                }
                                arr.map((updateProduct) => {
                                  updateProduct.child_items.map((updateChildItem , index) => {
                                    updateChildItem.barcodes.map((barcode ) => {
                                      let Barcode =  lowercase === true ? barcode.barcode.toLowerCase() : barcode.barcode
                                      if( Barcode === bar_code && scanpush === false){
                                        let serialScanAccess = false
                                        if( enableSerial || updateChildItem.record_serial === false && updateChildItem.second_record_serial === false){
                                          serialScanAccess =  true
                                        }else{
                                          if((updateChildItem.record_serial) || (updateChildItem.second_record_serial)) {
                                            let eventType =  type === "item_present" ? "clickscan" : "barcode"
                                            rejectLocalLog = false
                                            this.setState({serialRecordPopUpShow: true , type: eventType })
                                          }
                                        }
                                        if(serialScanAccess){
                                          if(scanned_items.length > 0 ){
                                            scanned_items.map((scan ) => {
                                              scan.barcodes.map((scancode) => {
                                                let Scancode =  lowercase === true ? scancode.barcode.toLowerCase() : scancode.barcode
                                                if(type === "item_present" ? updateChildItem.qty_remaining >= 1 : updateChildItem.qty_remaining >= parseInt(barcode.packing_count)){
                                                  if(Scancode === Barcode && Scancode === bar_code){
                                                    if(scan.qty_remaining === 1 && scanpush === false){
                                                      if(type === "item_present"){
                                                        updateChildItem.scanned_qty = updateChildItem.scanned_qty + 1
                                                        updateChildItem.qty_remaining = updateChildItem.qty_remaining - 1
                                                        scan.scanned_qty = updateChildItem.scanned_qty
                                                        scan.qty_remaining = updateChildItem.qty_remaining
                                                        updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                      }else{
                                                        updateChildItem.scanned_qty = updateChildItem.scanned_qty + parseInt(barcode.packing_count)
                                                        updateChildItem.qty_remaining = updateChildItem.qty_remaining - parseInt(barcode.packing_count)
                                                        scan.scanned_qty = updateChildItem.scanned_qty
                                                        scan.qty_remaining = updateChildItem.qty_remaining
                                                        updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                      }
                                                      item.child_items.splice(scanAccess , 1 )
                                                      item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                                      this.setState({typeScanState: updateChildItem.sku})
                                                      scanpush = true
                                                      SKU = updateChildItem.sku
                                                      Log_count = barcode.packing_count
                                                      product_name = updateChildItem.name
                                                    }else{
                                                      if(scan.qty_remaining > 0 && scanpush === false){
                                                        if(type === "item_present"){
                                                          updateChildItem.scanned_qty = updateChildItem.scanned_qty + 1
                                                          updateChildItem.qty_remaining = updateChildItem.qty_remaining - 1
                                                          scan.scanned_qty = updateChildItem.scanned_qty
                                                          scan.qty_remaining = updateChildItem.qty_remaining
                                                          updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                        }else{
                                                          updateChildItem.scanned_qty = updateChildItem.scanned_qty + parseInt(barcode.packing_count)
                                                          updateChildItem.qty_remaining = updateChildItem.qty_remaining - parseInt(barcode.packing_count)
                                                          scan.scanned_qty = updateChildItem.scanned_qty
                                                          scan.qty_remaining = updateChildItem.qty_remaining
                                                          updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                        }
                                                        this.setState({typeScanState: updateChildItem.sku})
                                                        scanpush = true
                                                        SKU = updateChildItem.sku
                                                        Log_count = barcode.packing_count
                                                        product_name = updateChildItem.name
                                                      }
                                                      if(updateChildItem.qty_remaining === 0){
                                                        item.child_items.splice(scanAccess , 1 )
                                                        item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                                      }
                                                    }
                                                  }else{
                                                    if(scancode !== bar_code && updateChildItem.scanned_qty === 0 ){
                                                      if(updateChildItem.qty_remaining === 1 && scanpush === false){
                                                        if(type === "item_present"){
                                                          updateChildItem.qty_remaining = updateChildItem.qty_remaining - 1
                                                          updateChildItem.scanned_qty = updateChildItem.scanned_qty + 1
                                                          scan.scanned_qty = updateChildItem.scanned_qty
                                                          scan.qty_remaining = updateChildItem.qty_remaining
                                                          updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                        }else{
                                                          updateChildItem.qty_remaining = updateChildItem.qty_remaining - parseInt(barcode.packing_count)
                                                          updateChildItem.scanned_qty = updateChildItem.scanned_qty + parseInt(barcode.packing_count)
                                                          scan.scanned_qty = updateChildItem.scanned_qty
                                                          scan.qty_remaining = updateChildItem.qty_remaining
                                                          updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                        }
                                                        scanned_items.push(updateChildItem)
                                                        item.child_items.splice(scanAccess , 1 )
                                                        item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                                        this.setState({typeScanState: updateChildItem.sku})
                                                        scanpush = true
                                                        SKU = updateChildItem.sku
                                                        Log_count = barcode.packing_count
                                                        product_name = updateChildItem.name
                                                      }else{
                                                        if(scanpush === false){
                                                          if(type === "item_present"){
                                                            updateChildItem.qty_remaining = updateChildItem.qty_remaining - 1
                                                            updateChildItem.scanned_qty = updateChildItem.scanned_qty + 1
                                                            scan.scanned_qty = updateChildItem.scanned_qty
                                                            scan.qty_remaining = updateChildItem.qty_remaining
                                                            updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                          }else{
                                                            updateChildItem.qty_remaining = updateChildItem.qty_remaining - parseInt(barcode.packing_count)
                                                            updateChildItem.scanned_qty = updateChildItem.scanned_qty + parseInt(barcode.packing_count)
                                                            scan.scanned_qty = updateChildItem.scanned_qty
                                                            scan.qty_remaining = updateChildItem.qty_remaining
                                                            updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                          }
                                                          scanned_items.push(updateChildItem)
                                                          this.setState({typeScanState: updateChildItem.sku})
                                                          scanpush = true
                                                          SKU = updateChildItem.sku
                                                          Log_count = barcode.packing_count
                                                          product_name = updateChildItem.name
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                            })
                                          }else{
                                            if(Barcode === bar_code && updateChildItem.qty_remaining >= parseInt(barcode.packing_count)){
                                              if(updateChildItem.qty_remaining === 1 && scanpush === false ){
                                                if(type === "item_present"){
                                                  updateChildItem.qty_remaining = updateChildItem.qty_remaining - 1
                                                  updateChildItem.scanned_qty = updateChildItem.scanned_qty + 1
                                                  updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                }else{
                                                  updateChildItem.qty_remaining = updateChildItem.qty_remaining - parseInt(barcode.packing_count)
                                                  updateChildItem.scanned_qty = updateChildItem.scanned_qty + parseInt(barcode.packing_count)
                                                  updateChildItem.qty_remaining === 0 && updateProduct.child_items.splice(index , 1 )
                                                }
                                                scanned_items.push(updateChildItem)
                                                item.child_items.splice(scanAccess , 1 )
                                                item.child_items.length === 0 && unscanned_items.splice(scanAccess , 1)
                                                this.setState({typeScanState: updateChildItem.sku})
                                                scanpush = true
                                                SKU = updateChildItem.sku
                                                Log_count = barcode.packing_count
                                                product_name = updateChildItem.name
                                              }else{
                                                if(scanpush === false){
                                                  if(type === "item_present"){
                                                    updateChildItem.qty_remaining = updateChildItem.qty_remaining - 1
                                                    updateChildItem.scanned_qty = updateChildItem.scanned_qty + 1
                                                  }else{
                                                    updateChildItem.qty_remaining = updateChildItem.qty_remaining - parseInt(barcode.packing_count)
                                                    updateChildItem.scanned_qty = updateChildItem.scanned_qty + parseInt(barcode.packing_count)
                                                  }
                                                  scanned_items.push(updateChildItem)
                                                  this.setState({typeScanState: updateChildItem.sku})
                                                  scanpush = true
                                                  SKU = updateChildItem.sku
                                                  Log_count = barcode.packing_count
                                                  product_name = updateChildItem.name
                                                }
                                              }
                                            }
                                          }
                                        }  
                                        if(unscanned_items.length > 0){
                                          if(serialScanAccess){
                                            if(type === "item_present"){
                                              this.responseAction("scan_success")
                                            }else{
                                              if(remain_qty >= parseInt(barcode.packing_count)){
                                                this.responseAction("scan_success")
                                              }else{
                                                this.alertBox(`The multi-pack barcode scanned exceeds the quantity remaining`)
                                              }
                                            }
                                          }
                                        }else{
                                          order_complete_flag = true
                                          // this.responseAction("order_complete")
                                        }
                                        itemFound =  true
                                      }
                                    })
                                  })
                                })
                              }
                            })

                            })
                          }
                        })
                      :
                      item && item.barcodes.map((barcode ) => {
                        let Barcode =  lowercase === true ? barcode.barcode.toLowerCase() : barcode.barcode
                        if(Barcode === bar_code && scanpush === false){
                          let serialScanAccess = false
                          if( enableSerial || item.record_serial === false && item.second_record_serial === false){
                            serialScanAccess =  true
                          }else{
                            if((item.record_serial) || (item.second_record_serial)) {
                              let eventType =  type === "item_present" ? "clickscan" : "barcode"
                              rejectLocalLog = false
                              this.setState({serialRecordPopUpShow: true , type: eventType })
                            }
                          }
                          if(serialScanAccess){
                            if(scanned_items.length > 0 ){
                              scanned_items.map((scan ) => {
                                scan.barcodes.map((scancode) => {
                                  let Scancode =  lowercase === true ? scancode.barcode.toLowerCase() : scancode.barcode
                                  if(type === "item_present" ? item.qty_remaining >= 1 : item.qty_remaining >= parseInt(barcode.packing_count)){
                                    if(Scancode === Barcode && Barcode === bar_code){
                                      if(scan.qty_remaining === 1 && scanpush === false){
                                        if(type === "item_present"){
                                          item.qty_remaining = item.qty_remaining - 1
                                          item.scanned_qty = item.scanned_qty + 1
                                          scan.qty_remaining = item.qty_remaining
                                          scan.scanned_qty = item.scanned_qty
                                        }else{
                                          if(item.qty_remaining > 0){
                                            item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                            item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                            scan.qty_remaining = item.qty_remaining
                                            scan.scanned_qty = item.scanned_qty
                                          }
                                        }
                                        unscanned_items.splice(scanAccess , 1 )
                                        this.setState({typeScanState: item.sku})
                                        scanpush = true
                                        SKU = item.sku
                                        Log_count = barcode.packing_count
                                        product_name = item.name
                                      }else{
                                        if(scan.qty_remaining > 1 && scanpush === false){
                                          if(type === "item_present" && item.qty_remaining > 0){
                                            item.qty_remaining = item.qty_remaining - 1
                                            item.scanned_qty = item.scanned_qty + 1
                                            scan.qty_remaining = item.qty_remaining
                                            scan.scanned_qty = item.scanned_qty
                                          }else{
                                            if(item.qty_remaining > 0){
                                              item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                              item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }
                                          }
                                          this.setState({typeScanState: item.sku})
                                          scanpush = true
                                          SKU = item.sku
                                          Log_count = barcode.packing_count
                                          product_name = item.name
                                        }
                                        if(item.qty_remaining === 0){
                                          unscanned_items.splice(scanAccess , 1 )
                                        }
                                      }
                                    }else{
                                      if(Scancode !== bar_code && item.scanned_qty === 0 ){
                                        if(item.qty_remaining === 1 && scanpush === false){
                                          if(type === "item_present"){
                                            item.qty_remaining = item.qty_remaining - 1
                                            item.scanned_qty = item.scanned_qty + 1
                                            scan.qty_remaining = item.qty_remaining
                                            scan.scanned_qty = item.scanned_qty
                                          }else{
                                            if(item.qty_remaining > 0){
                                              item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                              item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }
                                          }
                                          scanned_items.push(item)
                                          unscanned_items.splice(scanAccess , 1 )
                                          this.setState({typeScanState: item.sku})
                                          scanpush = true
                                          SKU = item.sku
                                          Log_count = barcode.packing_count
                                          product_name = item.name
                                        }else{
                                          if(scanpush === false && item.qty_remaining > 1 ){
                                            if(type === "item_present"){
                                              item.qty_remaining = item.qty_remaining - 1
                                              item.scanned_qty = item.scanned_qty + 1
                                              scan.qty_remaining = item.qty_remaining
                                              scan.scanned_qty = item.scanned_qty
                                            }else{
                                              if(item.qty_remaining > 0){
                                                item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                                item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                                scan.qty_remaining = item.qty_remaining
                                                scan.scanned_qty = item.scanned_qty
                                              }
                                            }
                                            scanned_items.push(item)
                                            this.setState({typeScanState: item.sku})
                                            scanpush = true
                                            SKU = item.sku
                                            Log_count = barcode.packing_count
                                            product_name = item.name
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                              })
                            }else{
                              if(Barcode === bar_code && item.qty_remaining >= parseInt(barcode.packing_count)){ 
                                if(item.qty_remaining === 1 && scanpush === false ){
                                  if(type === "item_present"){
                                    item.qty_remaining = item.qty_remaining - 1
                                    item.scanned_qty = item.scanned_qty + 1
                                  }else{
                                    if(item.qty_remaining > 0){
                                      item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                      item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                    }
                                  }
                                  scanned_items.push(item)
                                  unscanned_items.splice(scanAccess , 1 )
                                  this.setState({typeScanState: item.sku})
                                  scanpush = true
                                  SKU = item.sku
                                  Log_count = barcode.packing_count
                                  product_name = item.name
                                }else{
                                  if(scanpush === false){
                                    if(type === "item_present"){
                                      item.qty_remaining = item.qty_remaining - 1
                                      item.scanned_qty = item.scanned_qty + 1
                                    }else{
                                      if(item.qty_remaining > 0){
                                        item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count)
                                        item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count)
                                      }
                                    }
                                    scanned_items.push(item)
                                    this.setState({typeScanState: item.sku})
                                    scanpush = true
                                    SKU = item.sku
                                    Log_count = barcode.packing_count
                                    product_name = item.name
                                  }
                                }
                              }
                            }
                          }  
                          if(unscanned_items.length > 0 ){
                            if(serialScanAccess){
                              if(type === "item_present"){
                                this.responseAction("scan_success")
                              }else{
                                if(remain_qty >= parseInt(barcode.packing_count)){
                                  this.responseAction("scan_success")
                                }else{
                                  this.alertBox(`The multi-pack barcode scanned exceeds the quantity remaining`)
                                }
                              }
                            }
                             
                          }else{
                            order_complete_flag = true
                            // this.responseAction("order_complete")
                          }
                          itemFound =  true
                        }
                      })
                }
              }
              return item
            })
            if(skipError === false){
              itemFound === false ? this.responseAction("scan_fail") : ""
            }
          }
        }else{
          order_complete_flag = true
          // this.responseAction("order_complete")
        }
        if(this.state.serialRecordInput !== ""){
          temp_order =  updateSerialLog
        }else{
          temp_order["input"] = event_code;
          temp_order["id"] = order_num;
          temp_order["order_item_id"] = unscanned_items.length > 0 ? unscanned_items[0].order_item_id : (scanned_items.length > 0 ? scanned_items[0].order_item_id : "")
          temp_order["time"] =  new Date()
          temp_order["rem_qty"] = remain_qty
          temp_order["SKU"] = SKU ? SKU : unscanned_items.length > 0 && unscanned_items[0].sku
          temp_order["Log_count"] = Log_count
          temp_order["product_name"] = product_name
          temp_order["name"] = this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ""
          temp_order["actionBarcode"] = ActionBarcode
          if(type !== "item_present"){
            temp_order["state"] =  'scanpack.rfp.default'
          }
          temp_order["event"] =  this.state.bar_code ? "regular" : "click_scan"
          event_scanned === true ? temp_order["event"] = "regular" : temp_order["event"]
        }
        // event_scanned === true ? temp_order["event"] = "scanned" : temp_order["event"]
        if(stop_add_log !== true && temp_order !== undefined){
          if(order_complete_flag){
            this.updateLog(temp_order , "order_complete")
          }else{
            this.updateLog(temp_order , Action_Type)
          }
          if(this.state.serialRecordInput === "" && rejectLocalLog){
            localLogs.push(temp_order)
          }
        }
        this.setState({scanned_items: scanned_items , serialRecordInput: "" })
      }
    }
    // this.barcode.current.focus()
    enableSerial = false
  }
  /*SCAN BARCODE METHOD END*/

  /*METHOD TO UPDATE EVERY EVNET LOG START*/
  updateLog = async(logData , type ) =>{
    let temp = []
    let getItem = await AsyncStorage.getItem("logsArray")
    let getTemp = getItem !== "undefined" && JSON.parse(getItem)
    // console.log(JSON.parse(getItem))
    let arr = getTemp ? temp.concat(getTemp) : temp
    arr.push(logData)
    console.log(arr)
    await AsyncStorage.setItem("logsArray" , JSON.stringify(arr))
    try {
      this.barcode.current.focus()
    } catch (error) {
    }
    if(logData && logData.event === "scanned"){
      this.multipleTime()
    }
    if(type === "issue"){
      this.multipleTime()
      this.nav(this.state.order)
    }
    if(type === "RESTART"){
      this.multipleTime()
      this.nav(this.state.order)
    }
    if(type === "order_complete"){
      if(this.state.scan_pack_settings.post_scanning_option === "None"){
        this.responseAction("complete_order")
      }
    }
    // if(type === "tracking"){
    //   // this.multipleTime()
    //   // this.nav(this.state.order)
    //   this.responseAction("complete_order")
    //   this.setState({trackingOrderInput: ""})
    // }
    this.setState({bar_code: ""})
  }
  /*METHOD TO UPDATE EVERY EVNET LOG END*/

  close = (type) => {
    if(this.state.order)
    {
      let scan_pack_settings = this.state.scan_pack_settings
      if(type === "show_customer_notes"){
        scan_pack_settings['show_customer_notes'] = false
      }
      if(type === "show_internal_notes"){
        scan_pack_settings['show_internal_notes'] = false
      }
      if(type === "notes_toPacker"){
        this.setState({notes_toPacker_enable:  false})
      }
      this.setState({scan_pack_settings})
    }
  }

  leaveComponent = async(order , type) => {
   let localList = await AsyncStorage.getItem("storeOrder")
   if(order && order.order && order.order.unscanned_items && order.order.unscanned_items.length > 0){
     let arr = []
     let id = order && order.order && order.order.id
     let updateOrder =  order
     let orders = await AsyncStorage.getItem("storeOrder")
     arr = orders && JSON.parse(orders)
     let updateArr = orders && arr.orders && arr.orders.filter((order , index) => {
      if(order.id === id && updateOrder.order.unscanned_items && updateOrder.order.unscanned_items.length !== 0){
        order.scan_hash.data = updateOrder
      }
     })
     orderSaveSuccess = false;
     let storeDetail = JSON.stringify(arr)
     this.props.SetItem("storeOrder", storeDetail)
     this.props.GetItem("storeOrder")
     this.setState({order: ""})
   }else{
      this.props.SetItem("storeOrder" , localList)
   }
   if(type === "submitLog"){
      this.multipleTime()
    }
  }

  componentWillUnmount(){
    let Order = this.state.order
    searchOrderCheck = false;
    ScanPackSettingsAccess = false;
    GeneralSettingAccess = false;
    orderSaveSuccess = false;
    updateLogSuccess = true;
    if(Order){
      this.leaveComponent(Order)
    }
  }

  hideShipment = () => {
    this.setState({showshipments: false})
  }

  callShipmentOrder = (order) => {
    let shipmentOrder = this.state.shipmentOrder
    if(order.increment_id !== ""){
      callOrderSuccess = true
      shipmentOrder.input = order.increment_id
      this.hitApi(shipmentOrder)
    }
  }

   //according to the plateform call the function
  hitApi = (shipmentOrder) => {
    if(Platform.OS === "ios" || Platform.OS === "android"){
      this.InternetCheck(shipmentOrder)
    }else{
      this.props.SearchScanpackOrder(shipmentOrder)
    }
  }

  /*Check the connection for the ios and android start*/
  InternetCheck = async(shipmentOrder) => {
    const connectionInfo = await NetInfo.fetch();
    if(connectionInfo.isConnected){
      this.props.SearchScanpackOrder(shipmentOrder)
    }else{
      this.setState({errorMessageShow: true})
    }
  }
  /*Check the connection for the ios and android end*/

  enableToggle(e){
    if(this.props.route && this.props.route.params){
      if(e === 1){
        this.props.route.params.item(true)
        this.barcode.current.focus()
      }else{
        this.props.route.params.item(false)
      }
    }
  }

  serialLogUpdate(type , item , recordFrom , order_id , both){
    let temp = {}
    let localLog = {}
    let localLogs = this.state.localLogs
    temp["clicked"] = type === "clickscan" ? true : false
    temp["barcode"] = item.barcodes[0].barcode
    temp["order_id"] = order_id
    temp["order_item_id"] = item.order_item_id
    temp["product_lot_id"] = null
    temp["ask"] = recordFrom === "recordOne" ? true : false
    temp["ask_2"] = recordFrom === "recordTwo" ? true : false
    temp["product_id"] =  item.product_id
    if(both === false){
      temp["is_scan"] = true  
      temp["second_serial"] = false
    }else{
      if(recordFrom === "recordOne"){
        temp["is_scan"] = false
        temp["second_serial"] = false
      }else{
        temp["is_scan"] = true
        temp["second_serial"] = true
      }
    }
    temp["box_id"] = null
    temp["serial"] = this.state.serialRecordInput
    temp["event"] = "serial_scan"
    updateSerialLog = temp
    localLog["order_item_id"] = item && item.order_item_id
    localLog["time"] =  new Date()
    localLog["event"] =  "serial_scan"
    localLog["SKU"] = item.sku ? item.sku : ""
    localLog["name"] = this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ""
    if(both){
      if(recordFrom === "recordTwo"){
        localLogs.push(localLog)
      }
    }else{
      localLogs.push(localLog)
    }
    this.updateSerialLog(temp)
  }

  updateSerialLog = async(logData) =>{
    let temp = []
    let getItem = await AsyncStorage.getItem("logsArray")
    let getTemp = getItem !== "undefined" && JSON.parse(getItem)
    let arr = getTemp ? temp.concat(getTemp) : temp
    arr.push(logData)
    // console.log(arr)
    await AsyncStorage.setItem("logsArray" , JSON.stringify(arr))
    // try {
    //   this.barcode.current.focus()
    // } catch (error) {
    // }
    // if(logData.event === "scanned"){
    //   this.multipleTime()
    // }
    // if(type === "issue"){
    //   this.multipleTime()
    //   this.nav(this.state.order)
    // }
    // if(type === "tracking"){
    //   // this.multipleTime()
    //   // this.nav(this.state.order)
    //   this.responseAction("complete_order")
    //   this.setState({trackingOrderInput: ""})
    // }
  }

  serialRecordFun(){
    let Order = this.state.order && this.state.order.order
    let scanPackSetting = this.state.scan_pack_settings
    let input = this.state.serialRecordInput
    let inputLength = input.length
    let match = false
    if(scanPackSetting.require_serial_lot){
      if(scanPackSetting.valid_prefixes){
        let prefixLength = scanPackSetting.valid_prefixes.length
        let userInput = input.slice(0 , prefixLength)
        if(userInput === scanPackSetting.valid_prefixes){
          match = true
          enableSerial = true
        }else{
          this.alertBox("The Value Scanned does not appear to be a Valid serial or lot number. Please check the 'Require Serial/Lot Prefix' setting in your scan and pack options.")
          this.setState({serialRecordPopUpShow: false , type: "" , serialRecordInput: "" , serialRecordOne: "" , serialRecordTwo: "" })
          match = false
          enableSerial = false
        }
      }
    }else{
      match =  true
      enableSerial = true
    }
    let type = this.state.type
    let item = this.state.order && this.state.order.order && this.state.order.order.unscanned_items && this.state.order.order.unscanned_items[0]
    let order_id = this.state.order && this.state.order.order && this.state.order.order.id
    if(match){
      if(item.child_items && item.child_items.length > 0 && item.child_items[0]){
        if(item.child_items[0].record_serial && item.child_items[0].second_record_serial){
          if(item.child_items[0].record_serial && this.state.serialRecordOne === ""){
            this.setState({serialRecordOne: this.state.serialRecordInput})
            this.serialLogUpdate(type , item.child_items[0], "recordOne" , order_id , true)
            this.setState({serialRecordInput: "" })
          }else{
            this.setState({serialRecordTwo: this.state.serialRecordInput})
            this.serialLogUpdate(type , item.child_items[0], "recordTwo" , order_id , true)
            this.allTypeScan( type , item.child_items[0] )
            this.setState({serialRecordPopUpShow: false , type: "" , serialRecordInput: "" , serialRecordOne: "" , serialRecordTwo: "" })
          }
        }else{
          if(item.child_items[0].record_serial){
            this.setState({serialRecordOne: this.state.serialRecordInput})
            this.serialLogUpdate(type , item.child_items[0], "recordOne" , order_id , false)
          }else{
            this.setState({serialRecordTwo: this.state.serialRecordInput})
            this.serialLogUpdate(type , item.child_items[0], "recordTwo" , order_id , false)
          }
          this.allTypeScan( type , item.child_items[0] )
          this.setState({serialRecordPopUpShow: false , type: "" , serialRecordInput: ""})
        }
      }else{
        if(item.record_serial && item.second_record_serial){
          if(this.state.serialRecordOne === ""){
            this.setState({serialRecordOne: this.state.serialRecordInput})
            this.setState({serialRecordInput: "" })
            this.serialLogUpdate(type , item, "recordOne" , order_id , true)
          }else{
            this.setState({serialRecordTwo: this.state.serialRecordInput})
            enableSerial = true
            this.allTypeScan(type,item)
            this.setState({serialRecordPopUpShow: false , type: "" , serialRecordInput: "" , serialRecordOne: "" , serialRecordTwo: "" })
            this.serialLogUpdate(type , item, "recordTwo" , order_id , true)
          }
        }else{
          if(item.record_serial){
            this.serialLogUpdate(type , item, "recordOne" , order_id , false)
          }else{
            this.serialLogUpdate(type , item, "recordTwo" , order_id , false)
          }
          this.allTypeScan(type,item)
          // enableSerial = true
          this.setState({serialRecordPopUpShow: false , type: "" , serialRecordInput: ""})
        }
      }
    }
  }

  allTypeScan = (type , item , recordSerial) => {
    if(type === "clickscan"){
      this.clickScan(item)
    }
    if(type === "barcode"){
      this.scanBarcode()
      this.setState({bar_code: ""})
    }
    if(type === "scan_all_item"){
      this.scanAllItem("scan_all_item")
    }
    if(type === "submit_type_scan"){
      this.submitTypeScan("submit_type_scan")
    }
  }

  handleSerialRecordInput = (e) =>{
    this.setState({serialRecordInput: e})
  }

  /*Product Detail Nav Start*/
  redirectToItemDetail = (product) => {
    if(product && product.product_id){
      this.props.navigation.navigate("ProductDetail" , {productInfo: product.product_id , orderID: this.state.order.order.id })
    }
  }
  /*Product Detail Nav End*/

  removeFocus(){
    // this.barcode.blur()
    // this.barcode.current.focus()
  }

  render() {
    const config = { velocityThreshold: 1, directionalOffsetThreshold: 80 };
    // const config = { velocityThreshold: 1, directionalOffsetThreshold: 80, gestureIsClickThreshold : 5 };
    const props = this.props
    const Order = this.state.order && this.state.order.order
    const settings = this.state.scan_pack_settings
    return (
      <View style={{height: "100%" , flex: 1}}>
        <React.Fragment>
          {
            Order &&
              <View style={[globalStyles.flex1 , {height: "100%" , position: "relative"}]}>
                {
                  ((Order.notes_toPacker !== null && Order.notes_toPacker !== "") ||
                    (Order.notes_internal !== null && Order.notes_internal !== "") ||
                      (Order.customer_comments !== null && Order.customer_comments !== "") ) &&
                        <View style={{position: "absolute" , zIndex: 10 , width: "94%" , marginRight: "3%" , marginLeft:"3%" , marginTop: 20 }}>
                          {
                            Order.notes_toPacker !== null && Order.notes_toPacker !== "" && this.state.notes_toPackerFlag &&
                              <View style={{flexDirection: "row" , backgroundColor: "#eae672" , padding: 5 , borderRadius: 5 }}>
                                <View style={ Platform.OS === "web" ? {width: "98%"  , alignSelf: "center"} : {width: "94%"  , alignSelf: "center"}}>
                                  <Text style={{marginLeft: 5 , marginRight: 5 }}>{Order.notes_toPacker}</Text>
                                </View>
                                <TouchableOpacity style={ Platform.OS === "web" ? {width: "2%" , backgroundColor: "gray" , borderWidth: 1 , borderRadius: 10 , paddingLeft : 2 , paddingRight: 2 , justifyContent: "center"} : {width: "6%" , backgroundColor: "gray" , borderWidth: 1 , borderRadius: 10 , paddingLeft : 2 , paddingRight: 2   , justifyContent: "center"}} onPress={() => {this.setState({notes_toPackerFlag: false})}}>
                                  <Text style={{textAlign: "center" , color: "#fff" , fontWeight: "bold"}}>X</Text>
                                </TouchableOpacity>
                              </View>  
                          }
                          {
                            Order.notes_internal !== null && Order.notes_internal !== "" && this.state.notes_internalFlag && settings.show_internal_notes &&
                              <View style={{flexDirection: "row" , backgroundColor: "#eae672" , padding: 5 , borderRadius: 5 ,marginTop: 4}}>
                                <View style={ Platform.OS === "web" ? {width: "98%"  , alignSelf: "center"} : {width: "94%"  , alignSelf: "center"}}>
                                  <Text style={{marginLeft: 5 , marginRight: 5}}>{Order.notes_internal}</Text>
                                </View>
                                <TouchableOpacity style={ Platform.OS === "web" ? {width: "2%" , backgroundColor: "gray" , borderWidth: 1 , borderRadius: 10 , paddingLeft : 2 , paddingRight: 2 , justifyContent: "center"} : {width: "6%" , backgroundColor: "gray" , borderWidth: 1 , borderRadius: 10 , paddingLeft : 2 , paddingRight: 2   , justifyContent: "center"}} onPress={() => {this.setState({notes_internalFlag: false})}}>
                                  <Text style={{textAlign: "center" , color: "#fff" , fontWeight: "bold"}}>X</Text>
                                </TouchableOpacity>
                              </View>  
                          }
                          {
                            Order.customer_comments !== null && Order.customer_comments !== "" && this.state.customer_commentsFlag && settings.show_customer_notes &&
                              <View style={{flexDirection: "row" , backgroundColor: "#eae672" , padding: 5 , borderRadius: 5 , marginTop: 4}}>
                                <View style={ Platform.OS === "web" ? {width: "98%"  , alignSelf: "center"} : {width: "94%"  , alignSelf: "center"}}>
                                  <Text style={{marginLeft: 5 , marginRight: 5}}>{Order.customer_comments}</Text>
                                </View>
                                <TouchableOpacity style={ Platform.OS === "web" ? {width: "2%" , backgroundColor: "gray" , borderWidth: 1 , borderRadius: 10 , paddingLeft : 2 , paddingRight: 2 , justifyContent: "center"} : {width: "6%" , backgroundColor: "gray" , borderWidth: 1 , borderRadius: 10 , paddingLeft : 2 , paddingRight: 2   , justifyContent: "center"}} onPress={() => {this.setState({customer_commentsFlag: false})}} >
                                  <Text style={{textAlign: "center" , color: "#fff" , fontWeight: "bold"}}>X</Text>
                                </TouchableOpacity>
                              </View>
                          }
                        </View>
                }
                { //Succes Image , Error Image , Order Complete Image
                  this.state.responseShowAction &&
                    <ResponseView orderCompleteAction={this.state.orderCompleteAction}
                                  scanSuccessAction={this.state.scanSuccessAction}
                                  // scanFailAction={this.state.scanFailAction}
                                  scanFailAction={this.state.scanFailAction}
                                  scanPackSetting={this.state.scan_pack_settings}
                                  generalSetting={this.state.general_settings}
                                  />
                }
                { //Type Scan Count Pop Up
                  this.state.typeScanAlert &&
                    <TypeScanAlert closeAlert={this.closeAlert.bind(this)}
                                   typeScanCount={this.state.typeScanCount}
                                   typeScanCountUpdate={(text) => this.setState({typeScanCount: text})}
                                   submitTypeScan={this.submitTypeScan.bind(this)}
                                   // submitTypeScan={this.serialRecordCheck.bind(this , "submit_type_scan")}
                                   scanPackSetting={this.state.scan_pack_settings}
                                   generalSetting={this.state.general_settings}
                                   />
                }
                { // Add Note Pop Up 
                  this.state.alert &&
                    <Alert closeAlert={this.closeAlert.bind(this)}
                           submitAlert={this.submitAlert.bind(this)}
                           notes_fromPacker={this.state.notes_fromPacker}
                           noteUpdate={(text) => this.setState({notes_fromPacker: text})}
                           scanPackSetting={this.state.scan_pack_settings}
                           generalSetting={this.state.general_settings}
                           />
                }
                { // Notification Pop Up for messages
                  this.state.popUpModelShow &&
                    <PopUpModel closeAlert={this.closeAlert.bind(this)}
                                message={this.state.message}
                                />
                }
                { // Serial Record Pop Up
                  this.state.serialRecordPopUpShow &&
                    <SerialRecord serialRecordFun = {() => this.serialRecordFun()}
                                  serialRecordInput = {this.state.serialRecordInput}
                                  handleSerialRecordInput = {(e) => this.handleSerialRecordInput(e)}
                                  closeAlert={this.closeAlert.bind(this)}
                                  serialFocus={myInput}
                                  />
                }
                { // Click Scan Confirmation Pop Up
                  this.state.clickScanConfirmation &&
                    <ClickScanConfirmationPopUp clickScanConfirmFun = {() => this.clickScanConfirmFun()}
                                                clickScanConfirmInput = {this.state.clickScanConfirmInput}
                                                errorMessageClickScanConfirmation = {this.state.errorMessageClickScanConfirmation}
                                                handleClickScanConfirmInput = {(e) => this.handleClickScanConfirmInput(e)}
                                                closeAlert={this.closeAlert.bind(this)}
                                                serialFocus={myInput}
                                                />
                }
                {
                  this.state.scan_pack_settings.post_scanning_option !== "None" && Order && Order.unscanned_items.length === 0 
                  ?
                    <View style={globalStyles.flex1}>
                      <View style={globalStyles.flexDirectionRow}>
                        {
                          this.state.postScanningFieldLabel &&
                            <Text style={{ marginLeft: 20 , marginTop: 10 , fontWeight: "bold" , fontSize: 15 }}>{this.state.postScanningFieldLabel}</Text>
                        }
                      </View>
                      <View style={globalStyles.flexDirectionRow}>
                        <TextInput {...this.props}
                                     placeholder="Scan"
                                     name="trackingOrder"
                                     autoFocus={true}
                                     ref={this.inputFocus}
                                     value={this.state.trackingOrderInput}
                                     onChangeText={(text) => {this.setState({trackingOrderInput: text})}}
                                     onSubmitEditing={this.trackingSubmit.bind(this , this.state.trackingOrderInput)}
                                     style={styles.inputBox}/>
                      </View>
                      <View style={styles.container}>
                        <ScrollView contentContainerStyle={{ flexGrow:1, justifyContent: 'center' }}>
                        {
                          this.state.postScanningMessageDetail &&
                            <Text style={styles.text}>
                              {this.state.postScanningMessageDetail} 
                            </Text>
                        }
                        </ScrollView>
                      </View>
                    </View>
                  :
                    <React.Fragment>
                    {
                      this.props.route && this.props.route.params && this.state.order &&
                        <View style={{flex: 1}} >
                          {
                            Platform.OS === "web"
                            ?
                            <View style={{flex: 1}}>
                              {
                                windowWidth > 1024
                                ?
                                  <View style={{flex: 1 , flexDirection: "row", backgroundColor: "#292929"}}>
                                    <View style={{flex: 2 }}>
                                      <UnscannedItems {...this.props}
                                                      order={this.state.order}
                                                      scanPackSetting={this.state.scan_pack_settings}
                                                      generalSetting={this.state.general_settings}
                                                      redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                                      />
                                    </View>
                                    <View style={{flex: 6}}>
                                      <NextItems {...this.props}
                                                 order={this.state.order}
                                                 barcode={this.barcode}
                                                 access_token={this.state.token}
                                                 barcodeState={this.state.bar_code}
                                                 removeFocus={() => this.removeFocus()}
                                                 clickScan = {(e) => this.clickScan(e)}
                                                 // clickScan={this.serialRecordCheck.bind(this , "clickscan")}
                                                 scanAllItem={(e) => this.scanAllItem(e)}
                                                 // scanAllItem={this.serialRecordCheck.bind(this , "scan_all_item")}
                                                 scanBarcode={(e) => this.scanBarcode(e)}
                                                 // scanBarcode={this.serialRecordCheck.bind(this , "barcode")}
                                                 stateUpdate={(text) => this.setState({bar_code: text})}
                                                 scanPackSetting={this.state.scan_pack_settings}
                                                 generalSetting={this.state.general_settings}
                                                 activities={props && props.Order && props.Order.activities}
                                                 localLogs={this.state.localLogs}
                                                 restartButton={() => this.restartButton()}
                                                 redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                                 addNote = {() => this.addNote()}
                                                 saveChanges = {() => this.saveChanges()}
                                                 leaveComponent = {(order , type) => this.leaveComponent(order, type)}
                                                 />
                                    </View>
                                    <View style={{flex: 2}}>
                                      <ScannedItems {...this.props}
                                                    order={this.state.order}
                                                    scanPackSetting={this.state.scan_pack_settings}
                                                    generalSetting={this.state.general_settings}
                                                    />
                                    </View>
                                  </View>
                                :
                                <View style={{flex: 1 , backgroundColor: "#292929"}}>
                                    {
                                      this.state.unscannedItemShow === true && this.state.nextItemShow === false &&
                                        <UnscannedItems {...this.props}
                                                        order={this.state.order}
                                                        scanPackSetting={this.state.scan_pack_settings}
                                                        generalSetting={this.state.general_settings}
                                                        navButton={() => {this.setState({unscannedItemShow: false ,nextItemShow: true , scannedItemShow: false }) }}
                                                        redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                                        />
                                    }
                                    {
                                      this.state.unscannedItemShow === false && this.state.nextItemShow === true && this.state.scannedItemShow === false &&
                                        <NextItems {...this.props}
                                                   order={this.state.order}
                                                   barcode={this.barcode}
                                                   access_token={this.state.token}
                                                   barcodeState={this.state.bar_code}
                                                   removeFocus={(e) => this.removeFocus(e)}
                                                   redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                                   clickScan = {(e) => this.clickScan(e)}
                                                   // clickScan={this.serialRecordCheck.bind(this , "clickscan")}
                                                   scanAllItem={(e) => this.scanAllItem(e)}
                                                   // scanAllItem={this.serialRecordCheck.bind(this , "scan_all_item")}
                                                   scanBarcode={(e) => this.scanBarcode(e)}
                                                   // scanBarcode={this.serialRecordCheck.bind(this , "barcode")}
                                                   stateUpdate={(text) => this.setState({bar_code: text})}
                                                   scanPackSetting={this.state.scan_pack_settings}
                                                   generalSetting={this.state.general_settings}
                                                   activities={props && props.Order && props.Order.activities}
                                                   localLogs={this.state.localLogs}
                                                   restartButton={() => this.restartButton()}
                                                   addNote = {() => this.addNote()}
                                                   saveChanges = {() => this.saveChanges()}
                                                   navButtonLeft={() => { this.setState({ unscannedItemShow: true ,nextItemShow: false , scannedItemShow: false }) }}
                                                   navButtonRight={() => { this.setState({ unscannedItemShow: false ,nextItemShow: false , scannedItemShow: true }) }}
                                                   leaveComponent = {(order , type) => this.leaveComponent(order, type)}
                                                   />
                                    }
                                    {
                                      this.state.nextItemShow === false && this.state.scannedItemShow === true &&
                                        <ScannedItems {...this.props}
                                                      order={this.state.order}
                                                      scanPackSetting={this.state.scan_pack_settings}
                                                      generalSetting={this.state.general_settings}
                                                      navButton={() => {this.setState({unscannedItemShow: false ,nextItemShow: true , scannedItemShow: false }) }}
                                                      />
                                    }
                                </View>
                              }
                            </View>  
                            :
                            <Swiper index={1} 
                                    onIndexChanged={(e) => this.enableToggle(e)}
                                    keyboardShouldPersistTaps={'handled'}
                                    loop={false}
                                    horizontal={true}
                                    dotStyle={{display: "none"}}
                                    activeDotStyle={{display: "none"}}>
                              <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                                <UnscannedItems {...this.props}
                                                order={this.state.order}
                                                barcode={this.barcode}
                                                barcodeState={this.state.bar_code}
                                                clickScan = {(e) => this.clickScan(e)}
                                                 // clickScan={this.serialRecordCheck.bind(this , "clickscan")}
                                                scanAllItem={(e) => this.scanAllItem(e)}
                                                 // scanAllItem={this.serialRecordCheck.bind(this , "scan_all_item")}
                                                scanBarcode={(e) => this.scanBarcode(e)}
                                                 // scanBarcode={this.serialRecordCheck.bind(this , "barcode")}
                                                stateUpdate={(text) => this.setState({bar_code: text})}
                                                leaveComponent = {(order , type) => this.leaveComponent(order, type)}           
                                                scanPackSetting={this.state.scan_pack_settings}
                                                generalSetting={this.state.general_settings}
                                                redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                                restartButton={() => this.restartButton()}
                                                addNote = {() => this.addNote()}
                                                saveChanges = {() => this.saveChanges()}
                                                />
                              </View>
                              <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                                <NextItems {...this.props}
                                           order={this.state.order}
                                           barcode={this.barcode}
                                           access_token={this.state.token}
                                           barcodeState={this.state.bar_code}
                                           clickScan = {(e) => this.clickScan(e)}
                                           removeFocus={(e) => this.removeFocus(e)} 
                                           // clickScan={this.serialRecordCheck.bind(this , "clickscan")}
                                           scanAllItem={(e) => this.scanAllItem(e)}
                                           // scanAllItem={this.serialRecordCheck.bind(this , "scan_all_item")}
                                           scanBarcode={(e) => this.scanBarcode(e)}
                                           // scanBarcode={this.serialRecordCheck.bind(this , "barcode")}
                                           stateUpdate={(text) => this.setState({bar_code: text})}
                                           scanPackSetting={this.state.scan_pack_settings}
                                           generalSetting={this.state.general_settings}
                                           activities={props && props.Order && props.Order.activities}
                                           localLogs={this.state.localLogs}
                                           restartButton={() => this.restartButton()}
                                           addNote = {() => this.addNote()}
                                           saveChanges = {() => this.saveChanges()}
                                           leaveComponent = {(order , type) => this.leaveComponent(order, type)}
                                           redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                           />
                              </View>
                              <View style={{flex: 1 , justifyContent: 'center', alignItems: 'center'}}>
                                <ScannedItems {...this.props}
                                              order={this.state.order}
                                              scanPackSetting={this.state.scan_pack_settings}
                                              generalSetting={this.state.general_settings}
                                              redirectToItemDetail={(e) => this.redirectToItemDetail(e)}
                                              />
                              </View>
                            </Swiper>
                          }
                        </View>
                    }
                    </React.Fragment>
                }
              </View>
          }
        </React.Fragment>  
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Order: state.order.order,
    searchOrder: state.scanpack.searchOrder,
    saveData: state.updateAsync.retriveData,
    updateLog: state.saveLog,
    callOrder: state.scanpack.searchOrder,
    generalSettings: state.user.settings,
  }
};

const mapDispatchToProps = {
  GetOrderDetail,
  SearchScanpackOrder,
  GetItem,
  SetItem,
  SubmitLog,
  GetGeneralSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanPackItem)
