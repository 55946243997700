import React, { Component , useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';

export default class SerialRecord extends Component{
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
  }

  componentDidUpdate(){
    // this.typeScanFocus.current.focus()
  }

  render(){
    return (
      <View style={styles.typeScanContainer}>
        <View style={styles.typeScanBox}>
          <View style={globalStyles.flexDirectionRow}>
            <Text style={styles.scanCountText}>
              Product Serial Number
            </Text>
            <TouchableOpacity 
            	onPress={this.props.closeAlert}
            	>
              <Text style={styles.scanCountCloseBtn}>
                X
              </Text>
            </TouchableOpacity>
          </View>
          <TextInput value={this.props.serialRecordInput}
                     onChangeText={this.props.handleSerialRecordInput}
                     placeholder="Please add the serial number of the product to continue"
                     autoFocus={true}
                     ref={this.props.serialFocus}
                     onSubmitEditing={this.props.serialRecordInput !== "" ? this.props.serialRecordFun :  ""}
                     style={styles.typeScanCountInput}>
          </TextInput>
          <TouchableOpacity onPress={this.props.serialRecordInput !== "" ? this.props.serialRecordFun :  ""}
                            style={styles.scanCountSubmitBtnBox}>
            <Text style={styles.scanCountSubmitBtn}>
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
