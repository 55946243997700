import React, { Component } from 'react';
import { View, Text, TextInput , ActivityIndicator , TouchableOpacity , AsyncStorage  } from 'react-native';
import { connect } from 'react-redux';
import { GetProductDetail , UpdateProductInfo } from "../../actions/productAction";
import { SetItem } from "../../actions/updateAsyncAction";
import ProductInfo from "./product_info";
import ScanpackOptions from "./scanpack_options";
import InventoryKitOptions from "./inventory_kit_options";
import ProductActivityLog from "./product_activity_log";
import globalStyles from '../../style/global';

let updateProductInfoSuccess = false
let orderID = 0

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productInfoActive : true ,
      scanPackOptionsActive:  false,
      inventoryKitOptionsActive: false,
      productActivityLogActive: false,
      Product: [],
      visible: false,
      pickerSelectedOptions:[
        {
          label: "On",
          value: "on"
        },
        {
          label: "Off",
          value: "off"
        },
        {
          label: "On with confirmation",
          value: "on_with_confirmation"
        }
      ]
    };
  }

  componentDidMount(){
    this.apiCall()
  }

  // static getDerivedStateFromProps(nextProps){
  //   if(nextProps.product){
  //     return {Product: nextProps.product}
  //   }
  //   return null
  // }

  componentDidUpdate(){
    if(this.props && this.props.product && this.state.Product !== this.props.product){
      this.setState({Product: this.props.product})
    }
    if(updateProductInfoSuccess && this.props && this.props.updateProduct && this.props.updateProduct.scan_pack_product){
      this.setState({Product: this.props.updateProduct.scan_pack_product})
      this.updateProductLocal(this.props.updateProduct.scan_pack_product)
      updateProductInfoSuccess = false
    }
  }

  updateProductLocal = async(productInfo) => {
    if(productInfo){
      let updateOrderID =  productInfo.product_id
      let localOrderData = await AsyncStorage.getItem("storeOrder")
      let order = JSON.parse(localOrderData)
      let newOrder = []
      let pushOrder = {}
      order.orders && order.orders.length > 0 && order.orders.map((order , index) => {
        return(
          order.scan_hash && order.scan_hash.data.order && order.scan_hash.data.order.unscanned_items && order.scan_hash.data.order.unscanned_items.length > 0 && order.scan_hash.data.order.unscanned_items.map((items , index) => {
            if(items.product_type === "single"){
              if(items.product_id === updateOrderID){
                  items.barcodes = productInfo.barcodes
                  items.click_scan_enabled = productInfo.click_scan_enabled
                  items.confirmation = productInfo.confirmation
                  items.custom_product_1 = productInfo.custom_product_1
                  items.custom_product_2 = productInfo.custom_product_2
                  items.custom_product_3 = productInfo.custom_product_3
                  items.custom_product_display_1 = productInfo.custom_product_display_1
                  items.custom_product_display_2 = productInfo.custom_product_display_2
                  items.custom_product_display_3 = productInfo.custom_product_display_3
                  items.images = productInfo.images
                  items.instruction = productInfo.instruction
                  items.location = productInfo.location
                  items.location2 = productInfo.location2
                  items.location3 = productInfo.location3
                  items.name = productInfo.name
                  items.packing_placement = productInfo.packing_placement
                  items.product_id = productInfo.product_id
                  items.record_serial = productInfo.record_serial
                  items.second_record_serial = productInfo.second_record_serial
                  items.skippable = productInfo.skippable
                  items.sku = productInfo.sku
                  items.type_scan_enabled = productInfo.type_scan_enabled
              }
            }else{
              items.child_items && items.child_items.length > 0 && items.child_items.map((child , index) => {
                if(child.product_id === updateOrderID){
                  child.barcodes = productInfo.barcodes
                  child.click_scan_enabled = productInfo.click_scan_enabled
                  child.confirmation = productInfo.confirmation
                  child.custom_product_1 = productInfo.custom_product_1
                  child.custom_product_2 = productInfo.custom_product_2
                  child.custom_product_3 = productInfo.custom_product_3
                  child.custom_product_display_1 = productInfo.custom_product_display_1
                  child.custom_product_display_2 = productInfo.custom_product_display_2
                  child.custom_product_display_3 = productInfo.custom_product_display_3
                  child.images = productInfo.images
                  child.instruction = productInfo.instruction
                  child.location = productInfo.location
                  child.location2 = productInfo.location2
                  child.location3 = productInfo.location3
                  child.name = productInfo.name
                  child.packing_placement = productInfo.packing_placement
                  child.product_id = productInfo.product_id
                  child.record_serial = productInfo.record_serial
                  child.second_record_serial = productInfo.second_record_serial
                  child.skippable = productInfo.skippable
                  child.sku = productInfo.sku
                  child.type_scan_enabled = productInfo.type_scan_enabled
                }
              })
            }
            return items
          })
        )
      })
      order.orders && order.orders.length > 0 && order.orders.map((order , index) => {
        return(
          order.scan_hash && order.scan_hash.data.order && order.scan_hash.data.order.scanned_items && order.scan_hash.data.order.scanned_items.length > 0 && order.scan_hash.data.order.scanned_items.map((items , index) => {
            if(items.product_type === "single"){
              if(items.product_id === updateOrderID){
                  items.barcodes = productInfo.barcodes
                  items.click_scan_enabled = productInfo.click_scan_enabled
                  items.confirmation = productInfo.confirmation
                  items.custom_product_1 = productInfo.custom_product_1
                  items.custom_product_2 = productInfo.custom_product_2
                  items.custom_product_3 = productInfo.custom_product_3
                  items.custom_product_display_1 = productInfo.custom_product_display_1
                  items.custom_product_display_2 = productInfo.custom_product_display_2
                  items.custom_product_display_3 = productInfo.custom_product_display_3
                  items.images = productInfo.images
                  items.instruction = productInfo.instruction
                  items.location = productInfo.location
                  items.location2 = productInfo.location2
                  items.location3 = productInfo.location3
                  items.name = productInfo.name
                  items.packing_placement = productInfo.packing_placement
                  items.product_id = productInfo.product_id
                  items.record_serial = productInfo.record_serial
                  items.second_record_serial = productInfo.second_record_serial
                  items.skippable = productInfo.skippable
                  items.sku = productInfo.sku
                  items.type_scan_enabled = productInfo.type_scan_enabled
              }
            }else{
              items.child_items && items.child_items.length > 0 && items.child_items.map((child , index) => {
                if(child.product_id === updateOrderID){
                  child.barcodes = productInfo.barcodes
                  child.click_scan_enabled = productInfo.click_scan_enabled
                  child.confirmation = productInfo.confirmation
                  child.custom_product_1 = productInfo.custom_product_1
                  child.custom_product_2 = productInfo.custom_product_2
                  child.custom_product_3 = productInfo.custom_product_3
                  child.custom_product_display_1 = productInfo.custom_product_display_1
                  child.custom_product_display_2 = productInfo.custom_product_display_2
                  child.custom_product_display_3 = productInfo.custom_product_display_3
                  child.images = productInfo.images
                  child.instruction = productInfo.instruction
                  child.location = productInfo.location
                  child.location2 = productInfo.location2
                  child.location3 = productInfo.location3
                  child.name = productInfo.name
                  child.packing_placement = productInfo.packing_placement
                  child.product_id = productInfo.product_id
                  child.record_serial = productInfo.record_serial
                  child.second_record_serial = productInfo.second_record_serial
                  child.skippable = productInfo.skippable
                  child.sku = productInfo.sku
                  child.type_scan_enabled = productInfo.type_scan_enabled
                }
              })
            }
            return items
          })
        )
      })
      order.orders && order.orders.map((order , index) => {
        if(order.id === orderID){
          pushOrder = order
        }
      })
      let convertOrder = JSON.stringify(order)

      this.props.SetItem("storeOrder", convertOrder)
      this.props.navigation.navigate('ScanPackItem', {data: pushOrder.scan_hash.data});
      this.setState({visible: false})
    }
  }

  apiCall = () =>{
    let id = this.props.route && this.props.route.params && this.props.route.params.productInfo
    orderID = this.props.route && this.props.route.params && this.props.route.params.orderID
    if(id){
      this.props.GetProductDetail(id)
    }
  }

  changeState = (state) => {
    if(state === "productInfoActive"){
      this.setState({ productInfoActive: true, scanPackOptionsActive: false, inventoryKitOptionsActive: false,
                      productActivityLogActive: false
                    })
    }
    if(state === "scanPackOptionsActive"){
      this.setState({ productInfoActive: false, scanPackOptionsActive: true, inventoryKitOptionsActive: false,
                      productActivityLogActive: false
                    })
    }
    if(state === "inventoryKitOptionsActive"){
      this.setState({ productInfoActive: false, scanPackOptionsActive: false, inventoryKitOptionsActive: true,
                      productActivityLogActive: false
                    })
    }
    if(state === "productActivityLogActive"){
      this.setState({ productInfoActive: false, scanPackOptionsActive: false, inventoryKitOptionsActive: false,
                      productActivityLogActive: true
                    })
    }
  }

  multiBarcode = (value , name , index) => {
    let basicinfo = this.state.Product.basicinfo
    let Product = this.state.Product
    if(name === "is_skippable"){
      basicinfo[name] = value
    }
    if(name === "barcode" || name === "packing_count"){
      if(name === "barcode"){
        Product.barcodes[index].barcode = value
      }
      if(name === "packing_count"){
        Product.barcodes[index].packing_count = value 
      }
    }
    this.setState({Product})
  }

  productInfoHandleChange = (value , name ) => {
    let Product = this.state.Product
    let basicinfo = Product && Product.basicinfo
    if(basicinfo){
      basicinfo[name] = value
    }
    this.setState({Product})
  }

  addMultiBarcode = () => {
    let Product = this.props.product.barcodes
    let newHash = {
          barcode: "",
          packing_count: "1",
          product_id: this.props.product.basicinfo.id
        }
    Product.push(newHash)
    this.setState({Product})
  }

  updateProductSBC = (details , type) => {
    let data = this.state.Product
    if(type === "barcode"){
      data.barcodes = details
    }
    if(type === "sku"){
      data.skus = details
    }
    if(type === "cats"){
      data.cats = details
    }
    this.setState({data})
  }

  inventoryWarehousesHandleChange = (value , name ) => {
    let Product = this.state.Product.inventory_warehouses[0].info
    Product[name] = value
    this.setState({Product})
  }

  updateProduct = () => {
    let Product = this.state.Product
    if(Product){
      let id = Product.basicinfo && Product.basicinfo.id
      if(id){
        Product["app"] = "app"
        let data = Product
        this.props.UpdateProductInfo(id , Product)
        updateProductInfoSuccess = true
      }
    }
  }

  componentWillUnmount(){
    this.setState({data: {} , Product: {}})
    this.props.GetProductDetail(0)
  }

  render() {
    let Product = this.state && this.props.product
    return (
      <React.Fragment>
        {
          this.state.visible
          ?
            <View style={{flex: 1 , justifyContent: "center"}}>
              <ActivityIndicator size="large" />
            </View>
          :
            <View style={globalStyles.flex1}>
            {
              this.props && Product && this.state.Product !== [] &&
                <View style={globalStyles.flex1}>
                  <View style={{flexDirection: "row" , margin: 10 , borderWidth: 1 }}>
                    <TouchableOpacity onPress={this.changeState.bind(this , "productInfoActive")} style={[globalStyles.flex2 , {backgroundColor: this.state.productInfoActive ? "white" : "rgb(51, 101, 153)" , justifyContent: "center"}]}>
                      <Text style={{color: this.state.productInfoActive ? "black" : "white" , padding: 10 , textAlign: "center"}}>
                        Product Info
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.changeState.bind(this , "scanPackOptionsActive")} style={{flex:2 , backgroundColor: this.state.scanPackOptionsActive ? "white" : "rgb(51, 101, 153)" , justifyContent: "center"}}>
                      <Text style={{ color: this.state.scanPackOptionsActive ? "black" : "white" , padding: 10 , textAlign: "center"}}>
                        Scan & Pack Options
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.changeState.bind(this , "inventoryKitOptionsActive")} style={{flex:2 , backgroundColor: this.state.inventoryKitOptionsActive ? "white" : "rgb(51, 101, 153)" , justifyContent: "center"}}>
                      <Text style={{color: this.state.inventoryKitOptionsActive ? "black" : "white" , padding: 10 , textAlign: "center"}}>
                        Inventory/Kit Options
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.changeState.bind(this , "productActivityLogActive")} style={{flex:2 , backgroundColor: this.state.productActivityLogActive ? "white" : "rgb(51, 101, 153)" , justifyContent: "center"}}>
                      <Text style={{color: this.state.productActivityLogActive ? "black" : "white" , padding: 10 , textAlign: "center"}}>
                        Product Activity Log
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{flexDirection: "row", alignSelf: "flex-end" , marginRight: 10 , marginLeft: 10 , padding: 10}}>
                    <TouchableOpacity onPress={() => this.updateProduct()}><Text style={{padding: 10 , backgroundColor: "rgb(51, 101, 153)" , color: "#fff" , fontWeight: "bold" , borderRadius: 20 , marginRight: 10 }}>Save & Close</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}><Text style={{padding: 10 , backgroundColor: "rgb(73, 72, 72)" , color: "#fff" , fontWeight: "bold" , borderRadius: 20 }}>Close </Text></TouchableOpacity>
                  </View>
                  {
                    this.state.productInfoActive &&
                      <ProductInfo basicinfo={Product.basicinfo}
                                   images={Product.images}
                                   navigation={this.props.navigation}
                                   productInfoHandleChange={(value , name) => this.productInfoHandleChange(value , name)}
                                   updateProductSBC ={(details , type) => this.updateProductSBC(details, type)}
                                   {...this.props}
                                   />
                  }
                  {
                    this.state.scanPackOptionsActive &&
                      <ScanpackOptions barcodes={Product.barcodes}
                                       basicinfo={Product.basicinfo}
                                       productInfoHandleChange={(value , name) => this.productInfoHandleChange(value , name)}
                                       multiBarcode={(value , name , index) => this.multiBarcode(value , name , index)}
                                       navigation={this.props.navigation}
                                       addMultiBarcode={() => this.addMultiBarcode()}
                                       pickerSelectedOptions={this.state.pickerSelectedOptions}
                                       />
                  }
                  {
                    this.state.inventoryKitOptionsActive &&
                      <InventoryKitOptions inventoryWarehouses={Product.inventory_warehouses}
                                           navigation={this.props.navigation}
                                           inventoryWarehousesHandleChange={(value , name) => this.inventoryWarehousesHandleChange(value , name)}
                                           />
                  }
                  {
                    this.state.productActivityLogActive &&
                      <ProductActivityLog activities={Product.activities}
                                          navigation={this.props.navigation}
                                          />
                  }
                  <View style={{flexDirection: "row", alignSelf: "flex-end" , marginRight: 10 , marginLeft: 10 , padding: 10}}>
                    <TouchableOpacity onPress={() => this.updateProduct()}><Text style={{padding: 10 , backgroundColor: "rgb(51, 101, 153)" , color: "#fff" , fontWeight: "bold" , borderRadius: 20 , marginRight: 10 }}>Save & Close</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}><Text style={{padding: 10 , backgroundColor: "rgb(73, 72, 72)" , color: "#fff" , fontWeight: "bold" , borderRadius: 20 }}>Close </Text></TouchableOpacity>
                  </View>
                </View>
            }
            </View>
        }
      </React.Fragment>      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product.product,
    updateProduct: state.product.updateProduct
  }
};

const mapDispatchToProps = {
  GetProductDetail,
  UpdateProductInfo,
  SetItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)

