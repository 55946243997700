import React, { Component , useRef } from 'react';
import { View, Dimensions , Text, TextInput, ActivityIndicator, KeyboardAvoidingView ,Image, TouchableOpacity, Platform, ScrollView, Keyboard ,  StyleSheet } from 'react-native';
import keyboard from "../../../assets/keyboard.png"
import { SliderBox } from "react-native-image-slider-box";
import HTMLView from 'react-native-htmlview';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';
import moment from 'moment';
import HTML from 'react-native-render-html';
import GestureRecognizer, {swipeDirections} from 'react-native-swipe-gestures';
import Swiper from 'react-native-swiper/src';
import restart from "../../../assets/restart.png";
import note from "../../../assets/note.png";
import deleteImage from "../../../assets/delete.png";
import toggle from "../../../assets/dotToggle.png";
import savechanges from "../../../assets/savechanges.png";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default class NextItems extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showKeyboard: true,
      scanPackView: true,
      logView: false,
      enableLog: false,
      nextItemPageShow: true,
      activityLogPageShow: false,
      actionBarShow: false,
      localValue: [],
      ascii:[
              97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,
              65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,
              27
            ],
      scannedList: true,
      unscannedList: false,
      logList: false
    }
    this.escFunction = this.escFunction.bind(this);
    this.refVar =  React.createRef()
  }

  escFunction(event){
    let check = this.state.ascii
    if(check.includes(event && event.keyCode) && event.key){
      let local = this.state.localValue
      local.push(event.key)
      this.setState({local})
      let barcode = local.join("")
      this.props.stateUpdate(barcode)
    }
    if(event.keyCode === 13 ) {
      this.props.scanBarcode
      //Do whatever when esc is pressed
    }
  }

  componentDidMount(){
    // if(Platform.OS === "web"){
    //   window.addEventListener("keydown", this.escFunction, false)
    // }
  }

  componentWillUnmount(){
    // if(Platform.OS === "web"){
    //   window.removeEventListener("keydown", this.escFunction, false)
    // }
  }

  handelKeyboard = () =>{
    let showKeyboard = this.state.showKeyboard
    showKeyboard === true ? Keyboard.dismiss : this.props.barcode.current.focus()
    this.setState({showKeyboard: !showKeyboard} , () => { console.log(showKeyboard)} )
  }

  onSwipe = (action) => {
    let scanPackView = this.state.scanPackView
    let logView = this.state.logView
    switch(action){
      case 'SWIPE_UP':{
        this.setState({logView: true , scanPackView: false})
        break;
      }
      case 'SWIPE_DOWN':{
        this.setState({logView: false , scanPackView: true})
        break;
      }
      default : {
        console.log('Undeteceted action');
      }
    }
  }

  enableToggle(e){
    if(e === 0){
      this.props.route.params.item(true)
      this.setState({enableLog: false})
      // this.props.barcode.current.focus()
    }else{
      this.props.route.params.item(false)
      this.setState({enableLog: true})
    }
  }

  orderDetail = (order) => {
    // if(order){
      // this.props.leaveComponent(this.props.order , "submitLog")
      this.props.navigation.navigate("OrderDetail" , {data: order})
    // }
  }

  render(){
    let slider_image = []
    let Order = this.props.order.order
    let activities = this.props.activities
    let localLogs = this.props.localLogs
    const config = { velocityThreshold: 0.1, directionalOffsetThreshold: 100, gestureIsClickThreshold : 5 };
    return (
      <View style={{flex: 1 , width: "100%", height: "100%"}}>
          {
            Platform.OS === "web"
            ?
            <View style={[globalStyles.flex1]}>
              {
                windowWidth > 1024 
                ?
                <View style={globalStyles.flex1}>
                  {
                    this.props.order && Order.unscanned_items[0] ?
                      <View>
                        <View style={globalStyles.backgroundColorWhite}>
                          {
                          Order.unscanned_items[0].product_type === "individual" && Order.unscanned_items[0].child_items.length > 0 
                          ?
                            <View>
                              <View style={globalStyles.positionRelative}>
                                <View style={styles.nowScanningMainView}>
                                    <TouchableOpacity onPress={() => this.orderDetail(Order) }>
                                      <Text numberOfLines={2} ellipsizeMode='tail' style={styles.nowScanningTextView}>
                                        Now Scanning {Order.increment_id}
                                      </Text>
                                    </TouchableOpacity>
                                </View>
                                {/*<View style={{height: 30 , width: "50%" , zIndex: 9 , top: -4 , left: 0 , right: 0 , margin: 0 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "black" , position: "absolute" }}>
                                </View>*/}
                              </View>
                              <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                <View style={styles.actionButtonInnerView}>
                                  <TouchableOpacity style={styles.restartButtonDesign}
                                                    onPress={this.props.restartButton}
                                                    >
                                    <Image style={styles.actionImages} 
                                           source={restart ?  restart : ""} />
                                    {
                                      windowWidth > 375 &&  
                                        <Text style={styles.actionButtonText}>
                                          Restart
                                        </Text>
                                    }
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                    onPress={this.props.addNote}  
                                                    >
                                    <Image style={styles.actionImages} 
                                           source={note ? note : ""} />
                                    {
                                      windowWidth > 375 &&  
                                        <Text style={styles.actionButtonText}>
                                          Note
                                        </Text>
                                    }
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                    onPress={this.props.saveChanges}
                                                    >
                                    <Image style={styles.actionImages} 
                                           source={savechanges ? savechanges : ""} />
                                    {
                                      windowWidth > 375 &&  
                                      <Text style={styles.actionButtonText}>
                                        Save Changes
                                      </Text>
                                    }  
                                  </TouchableOpacity>
                                </View>
                              </View>                          
                              <View style={{padding: 5 , backgroundColor: "#c3d1ef" , display: "flex" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd" }}>
                                <View style={{ flexDirection: "column", flexWrap: "wrap",   alignItems: "flex-start"}}>
                                  <Text numberOfLines={2} ellipsizeMode='tail' style={{ fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                        >
                                        {Order.unscanned_items[0].child_items[0].name}
                                  </Text>
                                </View>
                              </View>
                              <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                <TouchableOpacity onPress={this.props.clickScan.bind(this , Order.unscanned_items[0].child_items[0] )} >
                                 <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#fff" , fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                       >
                                       {Order.unscanned_items[0].child_items[0].sku}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                              <View style={{padding:5 , backgroundColor: "#c1c9db" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd"}}>
                                 <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#000" , fontSize: 16 , textAlign: "left" , paddingBottom: 3 }}>
                                       {Order.unscanned_items[0].child_items[0] && Order.unscanned_items[0].child_items[0].barcodes.length > 0 && Order.unscanned_items[0].child_items[0].barcodes[0].barcode}
                                  </Text>
                              </View>
                              {
                                (this.props && Order && Order.unscanned_items[0].child_items[0].custom_product_1 !== "") || 
                                  (this.props && Order && Order.unscanned_items[0].child_items[0].custom_product_2 !== "") || 
                                    (this.props && Order && Order.unscanned_items[0].child_items[0].custom_product_3 !== "")
                                    ?
                                      <View style={{backgroundColor: "#336599" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5 , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#2e2d2d"}}>
                                        <ScrollView horizontal={true} >
                                            <View style={{width: "100%" , flexDirection: "row"}}>
                                            {
                                              Order.unscanned_items[0].child_items[0].custom_product_1 !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                  C1:
                                                </Text>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                  {Order.unscanned_items[0].child_items[0].custom_product_1}
                                                </Text>
                                              </View>
                                            }
                                            {
                                              Order.unscanned_items[0].child_items[0].custom_product_2 !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                  C2:
                                                </Text>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                  {Order.unscanned_items[0].child_items[0].custom_product_2}
                                                </Text>
                                              </View>
                                            }
                                            {
                                              Order.unscanned_items[0].child_items[0].custom_product_3 !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                  C3:
                                                </Text>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                  {Order.unscanned_items[0].child_items[0].custom_product_3}
                                                </Text>
                                              </View>
                                            }
                                            </View>
                                        </ScrollView>
                                      </View>
                                    :
                                     null
                              }
                              {
                                Order && Order.unscanned_items[0] && (Order.unscanned_items[0].child_items[0].record_serial || Order.unscanned_items[0].child_items[0].second_record_serial)
                                  ?  
                                  <View style={styles.serialNumberNeeded}>
                                     <Text numberOfLines={2} ellipsizeMode='tail' style={styles.serialNumberNeededText}>
                                           Serial number will be needed for this product
                                      </Text>
                                  </View>
                                  :
                                  null
                              }
                              {
                                (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location && Order.unscanned_items[0].child_items[0].location !== null && Order.unscanned_items[0].child_items[0].location !== "") ||
                                  (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location2 && Order.unscanned_items[0].child_items[0].location2 !== null && Order.unscanned_items[0].child_items[0].location2 !== "") ||
                                    (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location3 && Order.unscanned_items[0].child_items[0].location3 !== null && Order.unscanned_items[0].child_items[0].location3 !== "")
                                    ?
                                      <View style={{backgroundColor: "#1c1c1c" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5}}>
                                        <ScrollView horizontal={true} >
                                            <View style={{width: "100%" , flexDirection: "row"}}>
                                            {/*
                                              this.props.scanPackSetting.display_location && Order.unscanned_items[0].child_items[0].location !== null && Order.unscanned_items[0].child_items[0].location !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                  L1:
                                                </Text>
                                                {
                                                  Order.unscanned_items[0].child_items[0].location &&
                                                    <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 20 }}>
                                                      {Order.unscanned_items[0].child_items[0].location}
                                                    </Text>
                                                }
                                              </View>
                                            */}
                                            {
                                              this.props.scanPackSetting.display_location2 &&
                                                Order.unscanned_items[0].child_items[0].location2 !== null &&
                                                  Order.unscanned_items[0].child_items[0].location2 !== "" &&
                                                    <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                      <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5}}>
                                                        L2:
                                                      </Text>
                                                      {
                                                        Order.unscanned_items[0].child_items[0].location2 &&
                                                          <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                            {Order.unscanned_items[0].child_items[0].location2}
                                                          </Text>
                                                      }
                                                    </View>
                                            }
                                            {
                                              this.props.scanPackSetting.display_location3 &&
                                                Order.unscanned_items[0].child_items[0].location3 !== null &&
                                                  Order.unscanned_items[0].child_items[0].location3 !== "" &&
                                                    <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                      <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5}}>
                                                        L3:
                                                      </Text>
                                                      {
                                                        Order.unscanned_items[0].child_items[0].location3 &&
                                                          <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                            {Order.unscanned_items[0].child_items[0].location3}
                                                          </Text>
                                                      }
                                                    </View>
                                            }
                                            </View>
                                        </ScrollView>
                                      </View>
                                    :
                                     null
                              }
                              <View style={[globalStyles.flex2 , globalStyles.positionRelative]}>
                                {
                                    Order.unscanned_items[0].child_items[0].instruction && Order.unscanned_items[0].child_items[0].instruction !== "" 
                                      ?
                                      <View style={[styles.instructionContainerWeb , {height: "auto" , position: "absolute" , backgroundColor: "rgba(28, 43, 116, 0.7)"}]}>
                                        <ScrollView style={styles.instructionBox}>
                                          <View>
                                          {
                                            Order.unscanned_items[0].child_items[0].instruction !== "" &&
                                              <View>
                                                <View style={[styles.textBox ,{ padding: 5 }]}>
                                                  {
                                                    Order.unscanned_items[0].child_items[0].instruction !== "" &&
                                                     <HTML html={`<div style="color: white; margin: 0 ;" ${Order.unscanned_items[0].child_items[0].instruction}</div>`} imagesMaxWidth={Dimensions.get('window').width} />
                                                   }
                                                </View>
                                              </View>
                                          }
                                          </View>
                                        </ScrollView>
                                      </View>
                                      :
                                      null
                                  }
                                <View style={{flex: 2 , justifyContent: "center" , paddingTop: 20 , marginTop: 40 , marginBottom: 20}}>
                                  {
                                    Order && Order.unscanned_items[0] && Order.unscanned_items[0].child_items[0].skippable && 
                                      <View style={styles.blueBox}>
                                        <Text style={styles.blueText}>This product can be skipped, scan the skip code to skip</Text>
                                      </View>
                                  }
                                  <View style={styles.imageContainer} >
                                    {
                                      Order.unscanned_items[0].child_items[0].images.map((image , index) => {
                                        slider_image.push(image.image)
                                      })
                                    }
                                    <View style={{height: 250 , width: 250 }}>
                                      <Image source={slider_image[0] ?  slider_image[0] : ""} style={{height: "100%" , maxWidth: "100%"}}/>                    
                                    </View>
                                    <View style={{flexDirection: "row" , alignItems: "center" , position: "absolute" , right: 0 , backgroundColor: "rgba(255, 255, 255, 0.7)" , bottom: 0 , borderTopLeftRadius: 10 , borderBottomLeftRadius: 10}}>
                                      <View style={globalStyles.flexDirectionClm}>
                                        <Text style={styles.textX}> X </Text>
                                        <Text style={styles.textTimes}>TIMES
                                        </Text>
                                    </View>
                                     {
                                      Order.unscanned_items[0].child_items[0].qty_remaining &&
                                        <Text style={styles.qtyRemainText}>
                                          {Order.unscanned_items[0].child_items[0].qty_remaining}
                                        </Text>
                                     }
                                    </View>
                                    {
                                      Order.unscanned_items[0].child_items[0].qty_remaining &&
                                        <Text style={styles.qtyMoreToScanSecond}>
                                          {Order.unscanned_items[0].child_items[0].qty_remaining} more to scan.
                                        </Text>
                                    }
                                  </View>
                                </View>
                              </View>
                              <View style={globalStyles.flex1}>
                                <View style={[ globalStyles.p_10 , styles.scanningInputButtonBarMainView]}>
                                  <View style={globalStyles.flexDirectionRow}>
                                    <View style={[globalStyles.displayFlex , globalStyles.alignItmCntr , globalStyles.justifyCenter]}>
                                      <View style={styles.scanningInputButtonBarGreenButton}>
                                      </View>
                                    </View>
                                    <TextInput value={this.props.barcodeState}
                                               autoFocus={true}
                                               ref={this.props.barcode}
                                               onSubmitEditing={this.props.scanBarcode}
                                               onChangeText={this.props.stateUpdate}
                                               style={styles.barcodeStateInput} 
                                               onKeyUp={this.props.removeFocus}
                                               placeholder={this.props.barcode ? "Ready For Product Scan" : "Tap Here Before Scanning"}
                                               placeholderTextColor={"#4c4c4b"}
                                              />
                                    <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0].child_items[0]) }
                                                      onLongPress={this.props.scanAllItem}
                                                      delayLongPress={1000}
                                                      style={styles.scanAllItemWeb}>
                                      <Text style={ windowWidth > 375 ? [styles.scanBtn , globalStyles.textAlignCenter] : [styles.scanBtn , styles.scanLessThan375]}>No Barcode</Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </View>
                            </View>
                          :
                            <View>
                              <View style={[globalStyles.positionRelative]}>
                                <View style={styles.nowScanningMainView}>
                                    <TouchableOpacity onPress={() => this.orderDetail(Order) }>
                                      {
                                        Order.increment_id && 
                                          <Text numberOfLines={2} ellipsizeMode='tail' style={styles.nowScanningTextView}>
                                            Now Scanning {Order.increment_id}
                                          </Text>
                                      }
                                    </TouchableOpacity>
                                </View>
                                {/*<View style={{height: 30 , width: "50%" , zIndex: 9 , top: -4 , left: 0 , right: 0 , margin: 0 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "black" , position: "absolute" }}>
                                </View>*/}
                              </View>
                              <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                <View style={styles.actionButtonInnerView}>
                                  <TouchableOpacity style={styles.restartButtonDesign} 
                                                    onPress={this.props.restartButton}
                                                    >
                                    <Image style={styles.actionImages} source={restart ?  restart : ""} />
                                    {
                                      windowWidth > 375 &&
                                        <Text style={styles.actionButtonText}>Restart</Text>
                                    }
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                    onPress={this.props.addNote}
                                                    >
                                    <Image style={styles.actionImages} source={note ? note : ""} />
                                    {
                                      windowWidth > 375 &&
                                      <Text style={styles.actionButtonText}>Note</Text>
                                    }
                                  </TouchableOpacity>
                                  <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                    onPress={this.props.saveChanges}
                                                    >
                                    <Image style={styles.actionImages} 
                                           source={savechanges ? savechanges : ""} />
                                    {
                                      windowWidth > 375 &&
                                        <Text style={styles.actionButtonText}>Save Changes</Text>
                                    }
                                  </TouchableOpacity>
                                </View>
                              </View>
                              <View style={{padding: 5 , backgroundColor: "#c3d1ef" , display: "flex" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd" }}>
                                <View style={{ flexDirection: "column", flexWrap: "wrap",   alignItems: "flex-start"}}>
                                  {
                                    Order.unscanned_items[0].name && 
                                      <Text numberOfLines={2} ellipsizeMode='tail' style={{ fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                        >
                                        {Order.unscanned_items[0].name}
                                      </Text>
                                  }
                                </View>
                              </View>
                              <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                <TouchableOpacity onPress={this.props.clickScan.bind(this , Order.unscanned_items[0] )} >
                                  {
                                    Order.unscanned_items[0].sku && 
                                      <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#fff" , fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                       >
                                           {Order.unscanned_items[0].sku}
                                      </Text>
                                  }
                                </TouchableOpacity>
                              </View>
                              <View style={{padding:5 , backgroundColor: "#c1c9db" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd"}}>
                                  {
                                    Order.unscanned_items[0] && Order.unscanned_items[0].barcodes.length > 0 && Order.unscanned_items[0].barcodes[0].barcode && 
                                      <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#000" , fontSize: 16 , textAlign: "left" , paddingBottom: 3 }}
                                       >
                                           {Order.unscanned_items[0].barcodes[0].barcode}
                                      </Text>
                                  }
                              </View>
                              {
                                (this.props && Order && Order.unscanned_items[0].custom_product_1 !== null) || 
                                  (this.props && Order && Order.unscanned_items[0].custom_product_2 !== null) || 
                                    (this.props && Order && Order.unscanned_items[0].custom_product_3 !== null)
                                    ?
                                      <View style={{backgroundColor: "#336599" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5 , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#2e2d2d"}}>
                                        <ScrollView horizontal={true} >
                                            <View style={{width: "100%" , flexDirection: "row"}}>
                                            {
                                              Order.unscanned_items[0].custom_product_1 !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                  C1:
                                                </Text>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                  {Order.unscanned_items[0].custom_product_1}
                                                </Text>
                                              </View>
                                            }
                                            {
                                              Order.unscanned_items[0].custom_product_2 !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                  C2:
                                                </Text>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                  {Order.unscanned_items[0].custom_product_2}
                                                </Text>
                                              </View>
                                            }
                                            {
                                              Order.unscanned_items[0].custom_product_3 !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                  C3:
                                                </Text>
                                                <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                  {Order.unscanned_items[0].custom_product_3}
                                                </Text>
                                              </View>
                                            }
                                            </View>
                                        </ScrollView>
                                      </View>
                                    :
                                     null
                              }
                              {
                                Order && Order.unscanned_items[0] && (Order.unscanned_items[0].record_serial || Order.unscanned_items[0].second_record_serial)
                                  ?  
                                  <View style={styles.serialNumberNeeded}>
                                     <Text numberOfLines={2} ellipsizeMode='tail' style={styles.serialNumberNeededText}
                                           >
                                           Serial number will be needed for this product
                                      </Text>
                                  </View>
                                  :
                                  null
                              }
                              {
                                (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location && Order.unscanned_items[0].location !== null && Order.unscanned_items[0].location !== "" )||
                                  (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location2 && Order.unscanned_items[0].location2 !== null && Order.unscanned_items[0].location2 !== "") ||
                                    (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location3 && Order.unscanned_items[0].location3 !== null && Order.unscanned_items[0].location3 !== "")
                                    ?
                                      <View style={{backgroundColor: "#1c1c1c" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5}}>
                                        <ScrollView horizontal={true} >
                                            <View style={{width: "100%" , flexDirection: "row"}}>
                                            {/*
                                              this.props.scanPackSetting.display_location && Order.unscanned_items[0].location !== null && Order.unscanned_items[0].location !== "" &&
                                              <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                  L1:
                                                </Text>
                                                <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 20 }}>
                                                  {Order.unscanned_items[0].location}
                                                </Text>
                                              </View>
                                            */}
                                            {
                                              this.props.scanPackSetting.display_location2 &&
                                                Order.unscanned_items[0].location2 !== null && 
                                                  Order.unscanned_items[0].location2 !== "" &&
                                                    <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                      <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15, paddingRight: 5 }}>
                                                        L2:
                                                      </Text>
                                                      <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                        {Order.unscanned_items[0].location2}
                                                      </Text>
                                                    </View>
                                            }
                                            {
                                              this.props.scanPackSetting.display_location3 &&
                                                Order.unscanned_items[0].location3!== null &&
                                                  Order.unscanned_items[0].location3!== "" &&
                                                    <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                      <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5 }}>
                                                        L3:
                                                      </Text>
                                                      <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                        {Order.unscanned_items[0].location3}
                                                      </Text>
                                                    </View>
                                            }
                                            </View>
                                        </ScrollView>
                                      </View>
                                    :
                                     null
                              }
                              <View style={[globalStyles.flex2 , globalStyles.positionRelative]}>
                                {
                                  Order.unscanned_items[0].instruction && Order.unscanned_items[0].instruction !== "" 
                                    ?
                                    <View style={[styles.instructionContainerWeb , {height: "auto" , position: "absolute" , backgroundColor: "rgba(28, 43, 116, 0.7)"}]}>
                                      <ScrollView style={styles.instructionBox}>
                                        <View>
                                        {
                                          Order.unscanned_items[0].instruction !== "" &&
                                            <View>
                                              <View style={[styles.textBox ,{ padding: 5 }]}>
                                                {
                                                  Order.unscanned_items[0].instruction !== "" && 
                                                    <HTML html={`<div style="color: white; margin: 0 ;" ${Order.unscanned_items[0].instruction}</div>`} imagesMaxWidth={Dimensions.get('window').width} />
                                                 }
                                              </View>
                                            </View>
                                        }
                                        </View>
                                      </ScrollView>
                                    </View>
                                    :
                                    null
                                }
                                <View style={{flex: 2 , justifyContent: "center" , paddingTop: 20 , marginTop: 40 , marginBottom: 20}}>
                                  {
                                    Order && Order.unscanned_items[0] && Order.unscanned_items[0].skippable && 
                                      <View style={styles.blueBox}>
                                        <Text style={styles.blueText}>This product can be skipped, scan the skip code to skip</Text>
                                      </View>
                                  }
                                  <View style={styles.imageContainer} >    
                                    {
                                      Order.unscanned_items[0].images.map((image , index) => {
                                        slider_image.push(image.image)
                                      })
                                    }
                                    <View style={{height: 250 , width: 250 }}>
                                      <Image source={slider_image[0] ?  slider_image[0] : ""} style={{height: "100%" , maxWidth: "100%"}}/>                    
                                    </View>
                                    <View style={{flexDirection: "row" , alignItems: "center" , position: "absolute" , right: 0 , backgroundColor: "rgba(255, 255, 255, 0.7)" , bottom: 0 , borderTopLeftRadius: 10 , borderBottomLeftRadius: 10}}>
                                      <View style={globalStyles.flexDirectionClm}>
                                        <Text style={styles.textX}> X </Text>
                                        <Text style={styles.textTimes}>TIMES
                                        </Text>
                                    </View>
                                     <Text style={styles.qtyRemainText}>{Order.unscanned_items[0].qty_remaining} </Text>
                                    </View>
                                    <Text style={styles.qtyMoreToScanSecond}>{Order.unscanned_items[0].qty_remaining} more to scan.</Text>
                                  </View>
                                </View>
                              </View>
                              <View style={globalStyles.flex1}>
                                <View style={[ globalStyles.p_10 , styles.scanningInputButtonBarMainView]}>
                                  <View style={globalStyles.flexDirectionRow}>
                                    <View style={[globalStyles.displayFlex , globalStyles.alignItmCntr , globalStyles.justifyCenter]}>
                                      <View style={styles.scanningInputButtonBarGreenButton}>
                                      </View>
                                    </View>
                                    <TextInput value={this.props.barcodeState}
                                               autoFocus={true}
                                               ref={this.props.barcode}
                                               onSubmitEditing={this.props.scanBarcode}
                                               onChangeText={this.props.stateUpdate}
                                               style={styles.barcodeStateInput}
                                               onKeyUp={this.props.removeFocus}
                                               placeholder={this.props.barcode ? "Ready For Product Scan" : "Tap Here Before Scanning"}
                                               placeholderTextColor={"#4c4c4b"}
                                              />
                                    <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0]) }
                                                      onLongPress={this.props.scanAllItem}
                                                      delayLongPress={1000}
                                                      style={styles.scanAllItemWeb}>
                                      <Text style={windowWidth > 300 ? [styles.scanBtn , globalStyles.textAlignCenter ] : [styles.scanBtn , styles.scanLessThan375]}>No Barcode</Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </View>
                            </View>
                          }  
                          <View style={{backgroundColor: "#292929"}}>
                            <ScrollView>
                              {
                                localLogs && localLogs.slice(0).reverse().map((log , index)=> {
                                  return(
                                      <View key={index} style={[globalStyles.flexDirectionRow, {paddingLeft: 10 , paddingBottom: 0 , paddingTop: 5 , paddingRight: 10 , textAlign: "left"}]}>
                                        <View style={styles.actionBox}>
                                          <Text style={[styles.logDate, {color: "#606060"}]}>
                                            {moment(log.time).format('MMMM Do YYYY, h:mm:ss a')}
                                          </Text>
                                          {
                                            log.event === "regular" &&
                                            <View>
                                              {
                                                log.Log_count !== ""
                                                  ?
                                                  <View>
                                                    <Text style={[styles.logAction , {color: "#606060"}]}>
                                                      {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                    </Text>
                                                    <Text style={[styles.logDate, {color: "#606060"}]}>
                                                      {`Multibarcode count of ${log.Log_count} scanned for product ${log.SKU} `}
                                                    </Text>
                                                  </View>
                                                  :
                                                    log.actionBarcode 
                                                    ?
                                                      <Text style={[styles.logAction, {color: "#606060"}]}>
                                                          {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                      </Text>
                                                    :
                                                      <Text style={[styles.logAction, {color: "#606060"}]}>
                                                          {`INVALID SCAN - Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                      </Text>
                                              }
                                            </View>
                                          }
                                          {
                                            log.event === "click_scan" &&
                                              <Text style={[styles.logAction, {color: "#606060"}]}>
                                                {` Item with SKU: ${log.SKU} has been click scanned - by: ${log.name}`}
                                              </Text>
                                          }
                                          {
                                            log.event === "bulk_scan" &&
                                              <Text style={[styles.logAction, {color: "#606060"}]}>
                                                {`Item ${log.SKU} scanned through Bulk Scan - by: ${log.name}`}
                                              </Text>
                                          }
                                          {
                                            log.event === "type_scan" &&
                                              <View>
                                                <Text style={[styles.logAction, {color: "#606060"}]}>
                                                  {`Item with SKU: ${log.SKU} has been click scanned for a Type-In count - by: ${log.name}`}
                                                </Text>
                                                <Text style={[styles.logAction, {color: "#606060"}]}>
                                                  {`Type-In count of ${log.count} entered for product ${log.SKU}`}
                                                </Text>
                                              </View>
                                          }
                                          {
                                             log.event === "serial_scan" &&
                                              <Text style={[styles.logAction, {color: "#606060"}]}>
                                                {`Product: ${log.SKU} Serial scanned: "check" - by: ${log.name}`}
                                              </Text>
                                          }   
                                        </View>
                                      </View>
                                    )
                                })
                              }
                            </ScrollView>
                          </View>
                        </View>
                      </View>
                      :
                      <View style={{flex: 1 , justifyContent: "center" , alignItems: "center"}}>
                        <Text style={{fontSize: 14 , fontWeight: "bold"}}>No order is present to scan</Text>
                      </View>
                  }  
                </View>
                :
                <React.Fragment>
                {
                  Order && Order.unscanned_items[0] 
                  ?
                  <View style={[globalStyles.flex1 , globalStyles.pb_50]}>
                    <TouchableOpacity onPress={this.props.navButtonLeft} style={{ position: "fixed" , top: "35%" , backgroundColor: "rgba(0 ,0 ,0 , 0.7)" , writingMode: "vertical-rl" ,left: 0 , borderTopRightRadius: 5 , borderBottomRightRadius: 5 , zIndex: 999 , height: 150 , textAlign: "center"}}><Text style={{color: "#fff" , padding: 15 }}>Unscanned Items</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => {this.setState({nextItemPageShow: false , activityLogPageShow: true})}} style={{ position: "fixed" , left: 0 , right: 0 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "#000" , width: "50%" , bottom: 0 , borderTopLeftRadius: 5 , borderTopRightRadius: 5 , zIndex: 999 , textAlign: "center"}}><Text style={{color: "#fff" , padding: 15 }}>Activity Logs</Text></TouchableOpacity>
                    <TouchableOpacity onPress={this.props.navButtonRight} style={{ position: "fixed" , top: "35%" , backgroundColor: "rgba(0 ,0 ,0 , 0.7)" , writingMode: "vertical-rl" ,right: 0 , borderTopLeftRadius: 5 , borderBottomLeftRadius: 5 , zIndex: 999 , height: 150 , textAlign: "center"}}><Text style={{color: "#fff" , padding: 15 }}>Scanned Items</Text></TouchableOpacity>
                    <ScrollView keyboardShouldPersistTaps='handled' style={{height: "100%"}}>
                    <View>
                      <View style={{backgroundColor: "#fff"}}>
                        {
                          this.props.order && Order.unscanned_items && Order.unscanned_items[0] && Order.unscanned_items[0].product_type === "individual" && Order.unscanned_items[0].child_items && Order.unscanned_items[0].child_items.length > 0
                            ?   
                              <View>
                                <View style={[globalStyles.positionRelative]}>
                                  <View style={styles.nowScanningMainView}>
                                      <TouchableOpacity onPress={() => this.orderDetail(Order) }>
                                        <Text numberOfLines={2} ellipsizeMode='tail' style={styles.nowScanningTextView}>Now Scanning {Order.increment_id}</Text>
                                      </TouchableOpacity>
                                  </View>
                                  {/*<View style={{height: 30 , width: "50%" , zIndex: 9 , top: -4 , left: 0 , right: 0 , margin: 0 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "black" , position: "absolute" }}>
                                  </View>*/}
                                </View>
                                <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                  <View style={styles.actionButtonInnerView}>
                                    <TouchableOpacity style={styles.restartButtonDesign}
                                                      onPress={this.props.restartButton}
                                                      >
                                      <Image style={styles.actionImages} source={restart ?  restart : ""} />
                                      {
                                        windowWidth > 375 &&
                                          <Text style={styles.actionButtonText}>Restart</Text>
                                      }
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noteSaveButtonDesign} 
                                                      onPress={this.props.addNote}
                                                      >
                                      <Image style={styles.actionImages} source={note ? note : ""} />
                                      {
                                        windowWidth > 375 &&
                                        <Text style={styles.actionButtonText}>Note</Text>
                                      }
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                      onPress={this.props.saveChanges}
                                                      >
                                      <Image style={styles.actionImages} 
                                             source={savechanges ? savechanges : ""} />
                                      {
                                        windowWidth > 375 &&  
                                          <Text style={styles.actionButtonText}>Save Changes</Text>
                                      }
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                <View style={{padding: 5 , backgroundColor: "#c3d1ef" , display: "flex" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd" }}>
                                  <View style={{ flexDirection: "column", flexWrap: "wrap",   alignItems: "flex-start"}}>
                                    <Text numberOfLines={2} ellipsizeMode='tail' style={{ fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                          >
                                          {Order.unscanned_items[0].child_items[0].name}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                  <TouchableOpacity onPress={this.props.clickScan.bind(this , Order.unscanned_items[0].child_items[0] )} >
                                   <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#fff" , fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                         >
                                         {Order.unscanned_items[0].child_items[0].sku}
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                                <View style={{padding:5 , backgroundColor: "#c1c9db" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd"}}>
                                   <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#000" , fontSize: 16 , textAlign: "left" , paddingBottom: 3 }}
                                         >
                                         {Order.unscanned_items[0].child_items[0].barcodes.length > 0 && Order.unscanned_items[0].child_items[0].barcodes[0].barcode}
                                    </Text>
                                </View>
                                {
                                  (this.props && Order && Order.unscanned_items[0].child_items[0].custom_product_1 !== null) || 
                                    (this.props && Order && Order.unscanned_items[0].child_items[0].custom_product_2 !== null) || 
                                      (this.props && Order && Order.unscanned_items[0].child_items[0].custom_product_3 !== null)
                                      ?
                                        <View style={{backgroundColor: "#336599" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5 , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#2e2d2d"}}>
                                          <ScrollView horizontal={true} >
                                              <View style={{width: "100%" , flexDirection: "row"}}>
                                              {
                                                Order.unscanned_items[0].child_items[0].custom_product_1 !== "" &&
                                                  <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                      C1:
                                                    </Text>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                      {Order.unscanned_items[0].child_items[0].custom_product_1}
                                                    </Text>
                                                  </View>
                                              }
                                              {
                                                Order.unscanned_items[0].child_items[0].custom_product_2 !== "" &&
                                                  <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                      C2:
                                                    </Text>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                      {Order.unscanned_items[0].child_items[0].custom_product_2}
                                                    </Text>
                                                  </View>
                                              }
                                              {
                                                Order.unscanned_items[0].child_items[0].custom_product_3 !== "" &&
                                                  <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                      C3:
                                                    </Text>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                      {Order.unscanned_items[0].child_items[0].custom_product_3}
                                                    </Text>
                                                  </View>
                                              }
                                              </View>
                                          </ScrollView>
                                        </View>
                                      :
                                       null
                                }
                                {
                                  Order && Order.unscanned_items[0] && (Order.unscanned_items[0].child_items[0].record_serial || Order.unscanned_items[0].child_items[0].second_record_serial)
                                    ?  
                                    <View style={styles.serialNumberNeeded}>
                                       <Text numberOfLines={2} ellipsizeMode='tail' style={styles.serialNumberNeededText}
                                             >
                                             Serial number will be needed for this product
                                        </Text>
                                    </View>
                                    :
                                    null
                                }
                                {
                                  (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location && Order.unscanned_items[0].child_items[0].location !== null && Order.unscanned_items[0].child_items[0].location !== "") ||
                                    (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location2 && Order.unscanned_items[0].child_items[0].location2 !== null && Order.unscanned_items[0].child_items[0].location2 !== "") ||
                                      (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location3 && Order.unscanned_items[0].child_items[0].location3 !== null && Order.unscanned_items[0].child_items[0].location3 !== "")
                                      ?
                                        <View style={{backgroundColor: "#1c1c1c" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5}}>
                                          <ScrollView horizontal={true} >
                                              <View style={{width: "100%" , flexDirection: "row"}}>
                                              {/*
                                                this.props.scanPackSetting.display_location && Order.unscanned_items[0].child_items[0].location !== null && Order.unscanned_items[0].child_items[0].location !== "" &&
                                                <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                  <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                    L1:
                                                  </Text>
                                                  <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 20 }}>
                                                    {Order.unscanned_items[0].child_items[0].location}
                                                  </Text>
                                                </View>
                                              */}
                                              {
                                                this.props.scanPackSetting.display_location2 &&
                                                  Order.unscanned_items[0].child_items[0].location2 !== null &&
                                                    Order.unscanned_items[0].child_items[0].location2 !== "" &&
                                                      <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5 }}>
                                                          L2:
                                                        </Text>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                          {Order.unscanned_items[0].child_items[0].location2}
                                                        </Text>
                                                      </View>
                                              }
                                              {
                                                this.props.scanPackSetting.display_location3 &&
                                                  Order.unscanned_items[0].child_items[0].location3 !== null &&
                                                    Order.unscanned_items[0].child_items[0].location3 !== "" &&
                                                      <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5 }}>
                                                          L3:
                                                        </Text>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                          {Order.unscanned_items[0].child_items[0].location3}
                                                        </Text>
                                                      </View>
                                              }
                                              </View>
                                          </ScrollView>
                                        </View>
                                      :
                                       null
                                }
                                <View style={[globalStyles.flex2 , globalStyles.positionRelative]}>
                                  {
                                    Order.unscanned_items[0].child_items[0].instruction && Order.unscanned_items[0].child_items[0].instruction !== "" 
                                      ?
                                      <View style={[styles.instructionContainerWeb , {height: "auto" , position: "absolute" , backgroundColor: "rgba(28, 43, 116, 0.7)"}]}>
                                        <ScrollView style={styles.instructionBox}>
                                          <View>
                                          {
                                            Order.unscanned_items[0].child_items[0].instruction !== "" &&
                                              <View>
                                                <View style={[styles.textBox ,{ padding: 5 }]}>
                                                  {
                                                    Order.unscanned_items[0].child_items[0].instruction !== "" && 
                                                      <HTML html={`<div style="color: white; margin: 0 ;" ${Order.unscanned_items[0].child_items[0].instruction}</div>`} imagesMaxWidth={Dimensions.get('window').width} />
                                                   }
                                                </View>
                                              </View>
                                          }
                                          </View>
                                        </ScrollView>
                                      </View>
                                      :
                                      null
                                  }
                                  <View style={{flex: 2 , justifyContent: "center" , paddingTop: 20 , paddingBottom: 60 ,marginTop: 10 , marginBottom: 60}}>
                                    {
                                      Order && Order.unscanned_items[0] && Order.unscanned_items[0].child_items[0].skippable && 
                                        <View style={styles.blueBox}>
                                          <Text style={styles.blueText}>This product can be skipped, scan the skip code to skip</Text>
                                        </View>
                                    }
                                    <View style={styles.imageContainer}>
                                      {
                                        Order.unscanned_items[0].child_items[0].images.map((image , index) => {
                                          slider_image.push(image.image)
                                        })
                                      }
                                      <Image source={slider_image[0] ?  slider_image[0] : ""} style={{height: 250, width: 250}}/>                    
                                      <View style={{flexDirection: "row" , alignItems: "center" , position: "absolute" , right: 0 , backgroundColor: "rgba(255, 255, 255, 0.7)" , bottom: 0 , borderTopLeftRadius: 10 , borderBottomLeftRadius: 10}}>
                                        <View style={globalStyles.flexDirectionClm}>
                                          <Text style={styles.textX}> X </Text>
                                          <Text style={styles.textTimes}>TIMES
                                          </Text>
                                      </View>
                                       <Text style={styles.qtyRemainText}>{Order.unscanned_items[0].child_items[0].qty_remaining} </Text>
                                      </View>
                                      <Text style={{ position: "absolute" ,
                                                     backgroundColor: "rgba(255, 255, 255, 0.7)" ,
                                                     width: "auto" ,
                                                     paddingLeft: 5  ,
                                                     paddingRight: 5 ,
                                                     borderTopLeftRadius: 10 ,
                                                     borderTopRightRadius: 10 ,
                                                     bottom: 0 ,
                                                     fontSize: 20 ,
                                                     textAlign: "center" ,
                                                     fontWeight: "500" ,
                                                     color: "#000"}}
                                                     >
                                                      {Order.unscanned_items[0].child_items[0].qty_remaining} more to scan.
                                      </Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={globalStyles.flex1}>
                                  <View style={[ globalStyles.p_10 , styles.scanningInputButtonBarMainView]}>
                                    <View style={globalStyles.flexDirectionRow}>
                                      <View style={[globalStyles.displayFlex , globalStyles.alignItmCntr , globalStyles.justifyCenter]}>
                                        <View style={styles.scanningInputButtonBarGreenButton}>
                                        </View>
                                      </View>
                                      <TextInput value={this.props.barcodeState}
                                                 autoFocus={true}
                                                 ref={this.props.barcode}
                                                 onSubmitEditing={this.props.scanBarcode}
                                                 onChangeText={this.props.stateUpdate}
                                                 style={styles.barcodeStateInput}
                                                 onKeyUp={this.props.removeFocus}
                                                 placeholder={this.props.barcode ? "Ready For Product Scan" : "Tap Here Before Scanning"}
                                                 placeholderTextColor={"#4c4c4b"}
                                                />
                                      <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0].child_items[0]) }
                                                        onLongPress={this.props.scanAllItem}
                                                        delayLongPress={1000}
                                                        style={styles.scanAllItemWeb}>
                                        <Text style={windowWidth > 375 ? [styles.scanBtn , globalStyles.textAlignCenter ] : [styles.scanBtn , styles.scanLessThan375]}>No Barcode</Text>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                                <View style={{backgroundColor: "#292929"}}>
                                  <ScrollView style={{maxHeight: 400}}>
                                    {
                                      localLogs && localLogs.slice(0).reverse().map((log , index)=> {
                                        return(
                                            <View key={index} style={[globalStyles.flexDirectionRow, {paddingLeft: 10 , paddingBottom: 0 , paddingTop: 5 , paddingRight: 10 , textAlign: "left"}]}>
                                              <View style={styles.actionBox}>
                                                <Text style={[styles.logDate, {color: "#606060"}]}>
                                                  {moment(log.time).format('MMMM Do YYYY, h:mm:ss a')}
                                                </Text>
                                                {
                                                  log.event === "regular" &&
                                                  <View>
                                                    {
                                                      log.Log_count !== ""
                                                        ?
                                                        <View>
                                                          <Text style={[styles.logAction , {color: "#606060"}]}>
                                                            {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                          </Text>
                                                          <Text style={[styles.logDate, {color: "#606060"}]}>
                                                            {`Multibarcode count of ${log.Log_count} scanned for product ${log.SKU} `}
                                                          </Text>
                                                        </View>
                                                        :
                                                        log.actionBarcode 
                                                        ?
                                                          <Text style={[styles.logAction, {color: "#606060"}]}>
                                                              {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                          </Text>
                                                        :
                                                          <Text style={[styles.logAction, {color: "#606060"}]}>
                                                              {`INVALID SCAN - Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                          </Text>
                                                    }
                                                  </View>
                                                }
                                                {
                                                  log.event === "click_scan" &&
                                                    <Text style={[styles.logAction, {color: "#606060"}]}>
                                                      {` Item with SKU: ${log.SKU} has been click scanned - by: ${log.name}`}
                                                    </Text>
                                                }
                                                {
                                                  log.event === "bulk_scan" &&
                                                    <Text style={[styles.logAction, {color: "#606060"}]}>
                                                      {`Item ${log.SKU} scanned through Bulk Scan - by: ${log.name}`}
                                                    </Text>
                                                }
                                                {
                                                  log.event === "type_scan" &&
                                                    <View>
                                                      <Text style={[styles.logAction, {color: "#606060"}]}>
                                                        {`Item with SKU: ${log.SKU} has been click scanned for a Type-In count - by: ${log.name}`}
                                                      </Text>
                                                      <Text style={[styles.logAction, {color: "#606060"}]}>
                                                        {`Type-In count of ${log.count} entered for product ${log.SKU}`}
                                                      </Text>
                                                    </View>
                                                }

                                              </View>
                                            </View>
                                          )
                                      })
                                    }
                                  </ScrollView>
                                </View>
                              </View>
                            :
                              <View>
                                <View style={globalStyles.positionRelative}>
                                  <View style={styles.nowScanningMainView}>
                                    <TouchableOpacity onPress={() => this.orderDetail(Order) }>  
                                      <Text numberOfLines={2} ellipsizeMode='tail' style={styles.nowScanningTextView}>Now Scanning {Order.increment_id}</Text>
                                    </TouchableOpacity>
                                  </View>
                                  {/*<View style={{height: 30 , width: "50%" , zIndex: 9 , top: -4 , left: 0 , right: 0 , margin: 0 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "black" , position: "absolute" }}>
                                  </View>*/}
                                </View>
                                <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                  <View style={styles.actionButtonInnerView}>
                                    <TouchableOpacity style={styles.restartButtonDesign} 
                                                      onPress={this.props.restartButton}
                                                      >
                                      <Image style={styles.actionImages} source={restart ? restart : ""} />
                                      {
                                        windowWidth > 375 &&
                                          <Text style={styles.actionButtonText}>Restart</Text>
                                      }
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                      onPress={this.props.addNote}
                                                      >
                                      <Image style={styles.actionImages} source={note ? note : ""} />
                                      {
                                        windowWidth > 375 && 
                                        <Text style={styles.actionButtonText}>Note</Text>
                                      }
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                      onPress={this.props.saveChanges}
                                                      >
                                      <Image style={styles.actionImages}
                                             source={savechanges ? savechanges : ""} />
                                      {
                                        windowWidth > 375 &&  
                                          <Text style={styles.actionButtonText}>Save Changes</Text>
                                      }
                                    </TouchableOpacity>
                                  </View>
                                </View>
                                <View style={{padding: 5 , backgroundColor: "#c3d1ef" , display: "flex" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd" }}>
                                  <View style={{ flexDirection: "column", flexWrap: "wrap",   alignItems: "flex-start"}}>
                                    <Text numberOfLines={2} ellipsizeMode='tail' style={{ fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                          >
                                          {Order.unscanned_items[0].name}
                                    </Text>
                                  </View>
                                </View>
                                <View style={{padding:5 , backgroundColor: "#336599" ,borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#d3dcf3"}}>
                                  <TouchableOpacity onPress={this.props.clickScan.bind(this , Order.unscanned_items[0] )} >
                                   <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#fff" , fontWeight: "bold" , fontSize: 16 , textAlign: "left" }}
                                         >
                                         {Order.unscanned_items[0].sku}
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                                <View style={{padding:5 , backgroundColor: "#c1c9db" , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#3f7ebd"}}>
                                   <Text numberOfLines={2} ellipsizeMode='tail' style={{ color: "#000" , fontSize: 16 , textAlign: "left" , paddingBottom: 3 }}
                                         >
                                         {Order.unscanned_items[0].barcodes.length > 0 && Order.unscanned_items[0].barcodes[0].barcode}
                                    </Text>
                                </View>
                                {
                                  (this.props && Order && Order.unscanned_items[0].custom_product_1 !== null) || 
                                    (this.props && Order && Order.unscanned_items[0].custom_product_2 !== null) || 
                                      (this.props && Order && Order.unscanned_items[0].custom_product_3 !== null)
                                      ?
                                        <View style={{backgroundColor: "#336599" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5 , borderBottomWidth: 5 , borderStyle: "solid" , borderColor: "#2e2d2d"}}>
                                          <ScrollView horizontal={true} >
                                              <View style={{width: "100%" , flexDirection: "row"}}>
                                              {
                                                Order.unscanned_items[0].custom_product_1 !== "" &&
                                                  <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                      C1:
                                                    </Text>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                      {Order.unscanned_items[0].custom_product_1}
                                                    </Text>
                                                  </View>
                                              }
                                              {
                                                Order.unscanned_items[0].custom_product_2 !== "" &&
                                                  <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                      C2:
                                                    </Text>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                      {Order.unscanned_items[0].custom_product_2}
                                                    </Text>
                                                  </View>
                                              }
                                              {
                                                Order.unscanned_items[0].custom_product_3 !== "" &&
                                                  <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 15 }}>
                                                      C3:
                                                    </Text>
                                                    <Text style={{ color: "#fff" , fontWeight: "bold" , fontSize: 18 , marginLeft: 5 , textTransform: "capitalize"}}>
                                                      {Order.unscanned_items[0].custom_product_3}
                                                    </Text>
                                                  </View>
                                              }
                                              </View>
                                          </ScrollView>
                                        </View>
                                      :
                                       null
                                }
                                {
                                  Order && Order.unscanned_items[0] && (Order.unscanned_items[0].record_serial || Order.unscanned_items[0].second_record_serial)
                                    ?  
                                    <View style={styles.serialNumberNeeded}>
                                       <Text numberOfLines={2} ellipsizeMode='tail' style={styles.serialNumberNeededText}
                                             >
                                             Serial number will be needed for this product
                                        </Text>
                                    </View>
                                    :
                                    null
                                }
                                {
                                  (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location && Order.unscanned_items[0].location !== null && Order.unscanned_items[0].location !== "") ||
                                    (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location2 && Order.unscanned_items[0].location2 !== null && Order.unscanned_items[0].location2 !== "") ||
                                      (this.props && this.props.scanPackSetting && this.props.scanPackSetting.display_location3 && Order.unscanned_items[0].location3 !== null && Order.unscanned_items[0].location3 !== "")
                                      ?
                                        <View style={{backgroundColor: "#1c1c1c" ,flexDirection: "row", display: "flex" , alignItems: "center" , padding: 5}}>
                                          <ScrollView horizontal={true} >
                                              <View style={{width: "100%" , flexDirection: "row"}}>
                                              {/*
                                                this.props.scanPackSetting.display_location && Order.unscanned_items[0].location !== null && Order.unscanned_items[0].location !== "" &&
                                                <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                  <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 }}>
                                                    L1:
                                                  </Text>
                                                  <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                    {Order.unscanned_items[0].location}
                                                  </Text>
                                                </View>
                                              */}
                                              {
                                                this.props.scanPackSetting.display_location2 && 
                                                  Order.unscanned_items[0].location2 !== null &&
                                                    Order.unscanned_items[0].location2 !== "" &&
                                                      <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5 }}>
                                                          L2:
                                                        </Text>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                          {Order.unscanned_items[0].location2}
                                                        </Text>
                                                      </View>
                                              }
                                              {
                                                this.props.scanPackSetting.display_location3 &&
                                                  Order.unscanned_items[0].location3!== null &&
                                                    Order.unscanned_items[0].location3!== "" &&
                                                      <View style={{flexDirection: "row" , marginRight: 10 , alignItems: "baseline"}}>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 15 , paddingRight: 5 }}>
                                                          L3:
                                                        </Text>
                                                        <Text style={{ color: "#bfcded" , fontWeight: "bold" , fontSize: 18 }}>
                                                          {Order.unscanned_items[0].location3}
                                                        </Text>
                                                      </View>
                                              }
                                              </View>
                                          </ScrollView>
                                        </View>
                                      :
                                       null
                                }
                                <View style={[globalStyles.flex2 , globalStyles.positionRelative]}>
                                  {
                                      Order.unscanned_items[0].instruction && Order.unscanned_items[0].instruction !== "" 
                                        ?
                                        <View style={[styles.instructionContainerWeb , {height: "auto" , position: "absolute" , backgroundColor: "rgba(28, 43, 116, 0.7)"}]}>
                                          <ScrollView style={styles.instructionBox}>
                                            <View>
                                            {
                                              Order.unscanned_items[0].instruction !== "" &&
                                                <View>
                                                  <View style={[styles.textBox ,{ padding: 5 }]}>
                                                    {
                                                      Order.unscanned_items[0].instruction !== "" &&
                                                        <HTML html={`<div style="color: white; margin: 0 ;" ${Order.unscanned_items[0].instruction}</div>`} imagesMaxWidth={Dimensions.get('window').width} />
                                                     }
                                                  </View>
                                                </View>
                                            }
                                            </View>
                                          </ScrollView>
                                        </View>
                                        :
                                        null
                                    }
                                  <View style={{flex: 2 , justifyContent: "center" , paddingTop: 20 , marginTop: 20 , marginBottom: 60}}>
                                    {
                                      Order && Order.unscanned_items[0] && Order.unscanned_items[0].skippable && 
                                        <View style={styles.blueBox}>
                                          <Text style={styles.blueText}>This product can be skipped, scan the skip code to skip</Text>
                                        </View>
                                    }
                                    <View style={styles.imageContainer} >    
                                      {
                                        Order.unscanned_items[0].images.map((image , index) => {
                                          slider_image.push(image.image)
                                        })
                                      }
                                      <Image source={slider_image[0] ?  slider_image[0] : ""} style={{height: 250, width: 250}}/>                    
                                      <View style={{flexDirection: "row" , alignItems: "center" , position: "absolute" , right: 0 , backgroundColor: "rgba(255, 255, 255, 0.7)" , bottom: 0 , borderTopLeftRadius: 10 , borderBottomLeftRadius: 10}}>
                                        <View style={globalStyles.flexDirectionClm}>
                                          <Text style={styles.textX}> X </Text>
                                          <Text style={styles.textTimes}>TIMES
                                          </Text>
                                      </View>
                                       <Text style={styles.qtyRemainText}>{Order.unscanned_items[0].qty_remaining} </Text>
                                      </View>
                                      <Text style={styles.qtyMoreToScanSecond}>{Order.unscanned_items[0].qty_remaining} more to scan.</Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={globalStyles.flex1}>
                                  <View style={[ globalStyles.p_10 , styles.scanningInputButtonBarMainView]}>
                                    <View style={globalStyles.flexDirectionRow}>
                                      <View style={[globalStyles.displayFlex , globalStyles.alignItmCntr , globalStyles.justifyCenter]}>
                                        <View style={styles.scanningInputButtonBarGreenButton}>
                                        </View>
                                      </View>
                                      <TextInput value={this.props.barcodeState}
                                                 autoFocus={true}
                                                 ref={this.props.barcode}
                                                 onSubmitEditing={this.props.scanBarcode}
                                                 onChangeText={this.props.stateUpdate}
                                                 style={styles.barcodeStateInput}
                                                 onKeyUp={this.props.removeFocus}
                                                 placeholder={this.props.barcode ? "Ready For Product Scan" : "Tap Here Before Scanning"}
                                                 placeholderTextColor={"#4c4c4b"}
                                                />
                                      <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0]) }
                                                        onLongPress={this.props.scanAllItem}
                                                        delayLongPress={1000}
                                                        style={styles.scanAllItemWeb}>
                                        <Text style={ windowWidth > 375 ? [styles.scanBtn , globalStyles.textAlignCenter ] : [styles.scanBtn , {textAlign: "center" , fontSize: 10 }]}>No Barcode</Text>
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                                <View style={{backgroundColor: "#292929"}}>
                                  <ScrollView style={{maxHeight: 400}}>
                                    {
                                      localLogs && localLogs.slice(0).reverse().map((log , index)=> {
                                        return(
                                            <View key={index} style={[globalStyles.flexDirectionRow, {paddingLeft: 10 , paddingBottom: 0 , paddingTop: 5 , paddingRight: 10 , textAlign: "left"}]}>
                                              <View style={styles.actionBox}>
                                                <Text style={[styles.logDate, {color: "#606060"}]}>
                                                  {moment(log.time).format('MMMM Do YYYY, h:mm:ss a')}
                                                </Text>
                                                {
                                                  log.event === "regular" &&
                                                  <View>
                                                    {
                                                      log.Log_count !== ""
                                                        ?
                                                        <View>
                                                          <Text style={[styles.logAction , {color: "#606060"}]}>
                                                            {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                          </Text>
                                                          <Text style={[styles.logDate, {color: "#606060"}]}>
                                                            {`Multibarcode count of ${log.Log_count} scanned for product ${log.SKU} `}
                                                          </Text>
                                                        </View>
                                                        :
                                                        log.actionBarcode 
                                                          ?
                                                            <Text style={[styles.logAction, {color: "#606060"}]}>
                                                                {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                            </Text>
                                                          :
                                                            <Text style={[styles.logAction, {color: "#606060"}]}>
                                                                {`INVALID SCAN - Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                            </Text>
                                                    }
                                                  </View>
                                                }
                                                {
                                                  log.event === "click_scan" &&
                                                    <Text style={[styles.logAction, {color: "#606060"}]}>
                                                      {` Item with SKU: ${log.SKU} has been click scanned - by: ${log.name}`}
                                                    </Text>
                                                }
                                                {
                                                  log.event === "bulk_scan" &&
                                                    <Text style={[styles.logAction, {color: "#606060"}]}>
                                                      {`Item ${log.SKU} scanned through Bulk Scan - by: ${log.name}`}
                                                    </Text>
                                                }
                                                {
                                                  log.event === "type_scan" &&
                                                    <View>
                                                      <Text style={[styles.logAction, {color: "#606060"}]}>
                                                        {`Item with SKU: ${log.SKU} has been click scanned for a Type-In count - by: ${log.name}`}
                                                      </Text>
                                                      <Text style={[styles.logAction, {color: "#606060"}]}>
                                                        {`Type-In count of ${log.count} entered for product ${log.SKU}`}
                                                      </Text>
                                                    </View>
                                                }

                                              </View>
                                            </View>
                                          )
                                      })
                                    }
                                  </ScrollView>
                                </View>
                              </View>   
                        }
                      </View>
                    </View>  
                    </ScrollView>
                  </View>
                  :
                  <View style={{flex: 1 , justifyContent: "center" , alignItems: "center"}}>
                    <Text style={{fontSize: 14 , fontWeight: "bold"}}>No order is present to scan</Text>
                  </View>
                }
                </React.Fragment>  
              }
            </View>
            :
            <View>
              <View style={{height: "100%" , width: "100%"}}>
                  <View style={styles.nextItemsMain}>
                    {
                      this.props.order && Order.unscanned_items[0] &&
                        <View style={styles.RFPScreen}>
                          <View style={{width: "100%", flex: 1 , backgroundColor: "#666666" }}>
                            <View style={styles.actionButtonsView}>
                              <View style={{position: "relative"}}>
                                <TouchableOpacity onPress={() => this.orderDetail(Order)} style={{marginRight: 45 , marginLeft: 45}}>
                                  <Text numberOfLines={1} 
                                        ellipsizeMode='tail'
                                        style={[styles.nowScanningTextView , {textAlign: "center", margin: 8}]}>
                                    Order {Order.increment_id}
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => { let actionBarShow = !this.state.actionBarShow
                                                                    this.setState({actionBarShow})
                                                                }
                                                          }
                                                   style={{position: "absolute" , right: 10}}>
                                  <Image style={[styles.nowScanningTextView , {textAlign: "center", marginTop: 8 , marginBottom: 8}]} source={toggle ? toggle : ""} />
                                </TouchableOpacity>
                              </View>
                              {
                                this.state.actionBarShow &&  
                                  <View style={styles.actionButtonInnerView}>
                                    <TouchableOpacity style={styles.restartButtonDesign} 
                                                      onPress={this.props.restartButton}
                                                      >
                                      <View style={{display: "flex", flexDirection: "row" , alignItems: "center"}}>
                                        <Image style={styles.actionImages} 
                                               source={restart ?  restart : ""} />
                                        {
                                          windowWidth > 375 &&
                                            <React.Fragment>
                                              <View style={{flexDirection: "column" , alignItems: "center"}}> 
                                              <Text style={styles.actionButtonText}>Restart</Text>
                                              <Text style={styles.actionButtonText}>Order</Text>
                                              </View>
                                            </React.Fragment>
                                        }
                                      </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                        onPress={this.props.saveChanges}
                                                        >
                                      <View style={{display: "flex", flexDirection: "row" , alignItems: "center"}}>

                                        <Image style={styles.actionImages} 
                                               source={deleteImage ? deleteImage : ""} />
                                        {
                                          windowWidth > 375 && 
                                            <React.Fragment>
                                              <View style={{flexDirection: "column" , alignItems: "center"}}> 
                                              <Text style={styles.actionButtonText}>Remove</Text>
                                              <Text style={styles.actionButtonText}>Item</Text>
                                              </View>
                                            </React.Fragment>
                                        }
                                      </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noteSaveButtonDesign}
                                                      onPress={this.props.addNote}
                                                      >
                                      <View style={{display: "flex", flexDirection: "row" , alignItems: "center"}}>
                                        <Image style={styles.actionImages}
                                               source={note ?  note : ""} />
                                        {
                                          windowWidth > 375 &&
                                            <React.Fragment>
                                              <View style={{flexDirection: "column" , alignItems: "center", marginLeft: 2}}> 
                                              <Text style={styles.actionButtonText}>Add</Text>
                                              <Text style={styles.actionButtonText}>Note</Text>
                                              </View>
                                            </React.Fragment>
                                        }
                                      </View>
                                    </TouchableOpacity>
                                  </View>
                              }
                            </View>
                            <View style={{backgroundColor:"#2c2c2c" , padding: 10}}>
                              <View style={{flexDirection: "row"}}>
                                <View style={{display: "flex" , alignItems: "center" , justifyContent: "center"}}>
                                  <View style={{height: 10, width: 10, backgroundColor: "#5f80ff", borderRadius: 30, marginRight: 10 , shadowOffset:{  width: 0,  height: 0,  }, shadowColor: '#fff', shadowOpacity: 1.0, }}>
                                  </View>
                                </View>
                                <TextInput value={this.props.barcodeState}
                                           autoFocus={true}
                                           ref={this.props.barcode}
                                           onSubmitEditing={this.props.scanBarcode}
                                           onChangeText={this.props.stateUpdate}
                                           style={{flex:15, paddingLeft: 20 , padding: 5, backgroundColor: "#cbcbca", fontSize: 15, fontWeight: "bold", color: "black", width: "100%", textAlign: "left", borderWidth: 1, borderColor: "#cbcbca", borderRadius: 5}}
                                           onKeyUp={this.props.removeFocus}
                                           placeholder={this.props.barcode ? "Ready For Product Scan" : "Tap Here Before Scanning"}
                                           placeholderTextColor={"#4c4c4b"}
                                          />
                                {
                                  Order.unscanned_items[0].product_type === "individual" && Order.unscanned_items[0].child_items.length > 0 
                                  ?
                                    <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0].child_items[0]) }
                                                      onLongPress={this.props.scanAllItem}
                                                      delayLongPress={1000}
                                                      style={{flex: 4, marginLeft: 5, backgroundColor: "#41970f", textTransform: "uppercase", justifyContent:"center", alignItems: "center", padding: 5, borderRadius: 5}}>
                                      <Text style={{fontWeight: "bold", fontSize: 20, color: "#fff", textShadowColor:'#000', textShadowOffset:{width: 1, height: 1}, textAlign: "center", fontSize: 20 }}
                                            >
                                              Pass
                                      </Text>
                                    </TouchableOpacity>
                                  :
                                    <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0]) }
                                                      onLongPress={this.props.scanAllItem}
                                                      delayLongPress={1000}
                                                      style={{flex: 4, marginLeft: 5, backgroundColor: "#41970f", textTransform: "uppercase", justifyContent:"center", alignItems: "center", padding: 5, borderRadius: 5}}>
                                      <Text style={{fontWeight: "bold", fontSize: 20, color: "#fff", textShadowColor:'#000', textShadowOffset:{width: 1, height: 1}, textAlign: "center", fontSize: 20 }}
                                            >
                                              Pass
                                      </Text>
                                    </TouchableOpacity>
                                }  
                              </View>
                            </View>
                            {
                              Order.unscanned_items[0].product_type === "individual" && Order.unscanned_items[0].child_items.length > 0 
                                ?
                                <React.Fragment>
                                  {
                                    Order.unscanned_items[0].child_items[0].instruction !== "" && Order.unscanned_items[0].child_items[0].instruction !== null && 
                                      <View style={{height: 70}}>
                                        <ScrollView>
                                        {
                                          Order.unscanned_items[0].child_items[0].instruction !== "" &&
                                            <View style={{zIndex: 10, backgroundColor: "#01126c", width: "100%", justifyContent: "center", alignSelf: "center", alignItems: "center" }}>
                                              <View style={{width: "100%"}}>
                                                <View>
                                                {
                                                  Order.unscanned_items[0].child_items[0].instruction !== "" &&
                                                    <View>
                                                      <View style={{padding: 5 , color: "white", fontWeight: "bold"}}>
                                                        {
                                                          Order.unscanned_items[0].child_items[0].instruction !== "" &&
                                                            <HTML html={`<div style="color: white;">${Order.unscanned_items[0].child_items[0].instruction}</div>`} imagesMaxWidth={Dimensions.get('window').width} />
                                                         }
                                                      </View>
                                                    </View>
                                                }
                                                </View>
                                              </View>
                                            </View>
                                        }
                                        </ScrollView>
                                      </View>
                                  }
                                  <View style={{flexDirection: "row" , backgroundColor: "#292929" }}>
                                    <View style={{flex: 15}}>
                                      <TouchableOpacity onPress={() => this.props.redirectToItemDetail(Order.unscanned_items[0].child_items[0])} 
                                                        style={{flex: 16 , backgroundColor: "#fff"}}
                                                        >
                                        <Text numberOfLines={3}
                                              ellipsizeMode='tail'
                                              style={Order.unscanned_items[0].child_items[0].name &&
                                                      Order.unscanned_items[0].child_items[0].name.length <= 32 
                                                      ? 
                                                        {fontSize: 22}
                                                      : 
                                                        {fontSize: 16} 
                                                    }>
                                          {Order.unscanned_items[0].child_items[0].name}
                                        </Text>
                                      </TouchableOpacity>  
                                      <View style={{backgroundColor: "#d3def4" , flex: 5 , paddingTop: 3 , paddingBottom: 3 }}>
                                        <Text style={{fontSize: 22}}
                                              numberOfLines={1}
                                              ellipsizeMode='tail'>
                                          {
                                            Order.unscanned_items[0].child_items[0] &&
                                              Order.unscanned_items[0].child_items[0].barcodes.length > 0 &&
                                                Order.unscanned_items[0].child_items[0].barcodes[0].barcode
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                    
                                    <View style={{flex: 5 , backgroundColor: "#d3def4" , borderBottomRightRadius: 12 , borderTopRightRadius: 12 }}>
                                      <View style={{backgroundColor: "#fff" , position: "relative" ,  zIndex: 9 ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 5  , borderColor: "#3f5163"}}>
                                          {
                                            Order.unscanned_items[0].child_items[0].images.map((image , index) => {
                                              slider_image.push(image.image)
                                            })
                                          }
                                          <Image source={{uri: slider_image[0] ?  slider_image[0] : ""}} 
                                                 style={{height: 90 , width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                      </View>
                                    </View>
                                  </View>
                                  <View style={{flexDirection: "row" ,  backgroundColor: "#292929" , zIndex: -1}}>
                                    <View style={{flex: 15 , flexDirection: "column"}}>
                                      <View style={{height: 80 , backgroundColor: "#666666"}}>
                                        <View style={{backgroundColor: "#000"}}>
                                          <View style={{height: 40 , padding: 8 , justifyContent: "center" , backgroundColor: "#336598" , borderBottomRightRadius: 10}}>
                                            <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0].child_items[0]) }>
                                              <Text numberOfLines={1}
                                                    ellipsizeMode='tail' 
                                                    style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}
                                                    >
                                                      {Order.unscanned_items[0].child_items[0].sku}
                                              </Text>
                                            </TouchableOpacity>
                                          </View>
                                        </View>
                                        {
                                          Order.unscanned_items[0].child_items[0].location !== undefined &&
                                            Order.unscanned_items[0].child_items[0].location !== null &&
                                              Order.unscanned_items[0].child_items[0].location !== "" &&
                                                <View style={{flexDirection: "row" , height: 40 , padding: 8 , alignItems: "center" , backgroundColor: "#000" , borderTopLeftRadius: 10 , borderBottomLeftRadius: 10}}>
                                                  <Text style={{fontSize: 14 , fontWeight: "bold" , color: "#fff" , paddingTop: 2}}>L1 : </Text>
                                                  <Text numberOfLines={1}
                                                        ellipsizeMode='tail'
                                                        style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}
                                                        >
                                                          {Order.unscanned_items[0].child_items[0].location}
                                                  </Text>
                                                </View>
                                        }    
                                      </View>  
                                    </View>
                                    
                                    <View style={{flex: 5 , backgroundColor: "#000" , borderBottomRightRadius: 20 , justifyContent: "center" }}>
                                      <Text style={{textAlign: "center", fontSize: 25 , color: "#fff" , fontWeight: "bold"}}>
                                         {Order.unscanned_items[0].child_items[0].scanned_qty} / {Order.unscanned_items[0].child_items[0].qty_remaining + Order.unscanned_items[0].child_items[0].scanned_qty}
                                      </Text>
                                      <Text style={{textAlign: "center", fontSize: 20 , color: "#fff" , fontWeight: "bold"}}>
                                        Scanned
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={{backgroundColor: "#666666"}}>
                                    {
                                      Order.unscanned_items && 
                                        Order.unscanned_items[0] &&
                                          Order.unscanned_items[0].child_items &&
                                            Order.unscanned_items[0].child_items[0] &&
                                              Order.unscanned_items[0].child_items[0].location2 !== null &&
                                                Order.unscanned_items[0].child_items[0].location2 !== "" &&
                                                  <View style={{flexDirection: "row" , height: 40 , padding: 8 , alignItems: "center"  ,backgroundColor: "#282828" , borderRadius: 10 , marginTop: 2 , marginBottom: 1}}>
                                                    <Text style={{fontSize: 14 , fontWeight: "bold" , color: "#fff" , paddingTop: 2}}>L2 : </Text>
                                                    <Text numberOfLines={1} 
                                                          ellipsizeMode='tail'
                                                          style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}
                                                          >
                                                          {Order.unscanned_items[0].child_items[0].location2}
                                                    </Text>
                                                  </View>
                                    }
                                    {
                                      Order.unscanned_items &&
                                        Order.unscanned_items[0] &&
                                          Order.unscanned_items[0].child_items &&
                                            Order.unscanned_items[0].child_items[0] &&
                                              Order.unscanned_items[0].child_items[0].custom_product_1 !== null &&
                                                Order.unscanned_items[0].child_items[0].custom_product_1 !== "" &&
                                                  <View style={{flexDirection:"row", height: 40 , padding: 8 , alignItems: "center" , backgroundColor: "#454444" , borderRadius: 10 , marginTop: 1 , marginBottom: 2}}>
                                                    <Text style={{fontSize: 14 , fontWeight: "bold" , color: "#fff" , paddingTop: 2}}>C1: </Text>
                                                    <Text numberOfLines={1}
                                                          ellipsizeMode='tail'
                                                          style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}
                                                          >
                                                            {Order.unscanned_items[0].child_items[0].custom_product_1}
                                                    </Text>
                                                  </View>
                                    }
                                  </View>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  {
                                    Order.unscanned_items[0].instruction !== "" && Order.unscanned_items[0].instruction !== null &&  
                                      <View style={{height: 70}}>
                                        <ScrollView>
                                        {
                                          Order.unscanned_items[0].instruction !== "" &&
                                          <View style={{zIndex: 10, backgroundColor: "#01126c", width: "100%", justifyContent: "center", alignSelf: "center", alignItems: "center" }}>
                                            <View style={{width: "100%"}}>
                                              <View>
                                              {
                                                Order.unscanned_items[0].instruction !== "" &&
                                                  <View>
                                                    <View style={{padding: 5 , color: "white", fontWeight: "bold"}}>
                                                      {
                                                        Order.unscanned_items[0].instruction !== "" &&
                                                          <HTML html={`<div style="color: white;">${Order.unscanned_items[0].instruction}</div>`} imagesMaxWidth={Dimensions.get('window').width} />
                                                       }
                                                    </View>
                                                  </View>
                                              }
                                              </View>
                                            </View>
                                          </View>
                                        }
                                        </ScrollView>
                                      </View>
                                  }
                                  <View style={{flexDirection: "row" , backgroundColor: "#292929" }}>
                                    <View style={{flex: 15}}>
                                      <TouchableOpacity onPress={() => this.props.redirectToItemDetail(Order.unscanned_items[0])} 
                                                        style={{flex: 16 , backgroundColor: "#fff"}}
                                                        >
                                        <Text numberOfLines={3} 
                                              style={ Order.unscanned_items[0].name &&
                                                        Order.unscanned_items[0].name.length <= 32 
                                                        ? 
                                                          {fontSize: 20}
                                                        : 
                                                          {fontSize: 16} 
                                                    } 
                                              ellipsizeMode='tail'>
                                                {Order.unscanned_items[0].name}
                                        </Text>
                                      </TouchableOpacity>  
                                      <View style={{backgroundColor: "#d3def4" , flex: 5 , padding: 3 }}>
                                        <Text style={{fontSize: 22 , paddingBottom: 3}}
                                              numberOfLines={1}
                                              ellipsizeMode='tail'
                                              >
                                          {
                                            Order.unscanned_items[0] &&
                                              Order.unscanned_items[0].barcodes.length > 0 &&
                                                Order.unscanned_items[0].barcodes[0].barcode
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                    
                                    <View style={{flex: 5 , backgroundColor: "#d3def4" , borderBottomRightRadius: 12 , borderTopRightRadius: 12 }}>
                                      <View style={{backgroundColor: "#fff" , position: "relative" ,  zIndex: 9 ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 5  , borderColor: "#3f5163"}}>
                                          {
                                            Order.unscanned_items[0].images.map((image , index) => {
                                              slider_image.push(image.image)
                                            })
                                          }
                                          <Image source={{uri: slider_image[0] ?  slider_image[0] : ""}} 
                                                 style={{height: 90 , width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                      </View>
                                    </View>
                                  </View>
                                  <View style={{flexDirection: "row" ,  backgroundColor: "#292929" , zIndex: -1}}>
                                    <View style={{flex: 15 , flexDirection: "column"}}>
                                      <View style={{height: 80 , backgroundColor: "#666666"}}>
                                        <View style={{backgroundColor: "#000"}}>
                                          <View style={{height: 40 , padding: 8 , justifyContent: "center" , backgroundColor: "#336598" , borderBottomRightRadius: 10}}>
                                            <TouchableOpacity onPress={() => this.props.clickScan(Order.unscanned_items[0]) } >
                                              <Text numberOfLines={1} ellipsizeMode='tail' style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}>{Order.unscanned_items[0].sku}</Text>
                                            </TouchableOpacity>
                                          </View>
                                        </View>
                                        {
                                          Order.unscanned_items[0].location !== null &&
                                            Order.unscanned_items[0].location !== "" &&
                                              <View style={{flexDirection: "row" , height: 40 , padding: 8 , alignItems: "center" , backgroundColor: "#000" , borderTopLeftRadius: 10 , borderBottomLeftRadius: 10}}>
                                                <Text style={{fontSize: 14 , fontWeight: "bold" , color: "#fff" , paddingTop: 2}}>L1 : </Text>
                                                <Text numberOfLines={1} ellipsizeMode='tail' style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}>{Order.unscanned_items[0].location}</Text>
                                              </View>
                                        }
                                      </View>  
                                    </View>
                                    
                                    <View style={{flex: 5 , backgroundColor: "#000" , borderBottomRightRadius: 20 , justifyContent: "center" }}>
                                      <Text style={{textAlign: "center", fontSize: 25 , color: "#fff" , fontWeight: "bold"}}>
                                         {Order.unscanned_items[0].scanned_qty} / {Order.unscanned_items[0].qty_remaining + Order.unscanned_items[0].scanned_qty}
                                      </Text>
                                      <Text style={{textAlign: "center", fontSize: 20 , color: "#fff" , fontWeight: "bold"}}>
                                        Scanned
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={{backgroundColor: "#666666"}}>
                                    {
                                      Order.unscanned_items && 
                                        Order.unscanned_items[0] && 
                                          Order.unscanned_items[0].location2 !== null &&
                                            Order.unscanned_items[0].location2 !== "" &&
                                              <View style={{flexDirection: "row" , height: 40 , padding: 8 , alignItems: "center"  ,backgroundColor: "#282828" , borderRadius: 10 , marginTop: 2 , marginBottom: 1}}>
                                                <Text style={{fontSize: 14 , fontWeight: "bold" , color: "#fff" , paddingTop: 2}}>L2 : </Text>
                                                <Text style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}>{Order.unscanned_items[0].location2}</Text>
                                              </View>
                                    }
                                    {
                                      Order.unscanned_items && 
                                        Order.unscanned_items[0] && 
                                          Order.unscanned_items[0].custom_product_1 !== null &&
                                            Order.unscanned_items[0].custom_product_1 !== "" &&
                                              <View style={{flexDirection:"row", height: 40 , padding: 8 , alignItems: "center" , backgroundColor: "#454444" , borderRadius: 10 , marginTop: 1 , marginBottom: 2}}>
                                                <Text style={{fontSize: 14 , fontWeight: "bold" , color: "#fff" , paddingTop: 2}}>C1: </Text>
                                                <Text style={{fontSize: 22 , fontWeight: "bold" , color: "#fff"}}>{Order.unscanned_items[0].custom_product_1}</Text>
                                              </View>
                                    }
                                  </View>
                                </React.Fragment>
                            }    
                            <View style={{backgroundColor: "#2e4862"}}>
                              <View style={{flexDirection: "row" , height: 40 , backgroundColor: "#2e4862" , borderRadius: 10 , marginTop: 1 , marginBottom: 1}}>
                                <TouchableOpacity onPress={() => {this.setState({unscannedList: false , scannedList: true , logList: false}) }} 
                                                  style={
                                                          this.state.scannedList 
                                                          ? 
                                                            {flex: 2 , justifyContent: "center" , alignItems: "center" , backgroundColor: "#000" , color: "#fff" , borderRadius: 10 } 
                                                          : 
                                                            {flex: 2 , justifyContent: "center" , alignItems: "center"}
                                                          }
                                                        >
                                  <Text style={{fontSize: 18 , fontWeight: "bold" , color: "#fff" , padding: 4 , alignItems: "center"}}>NEXT UP</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {this.setState({unscannedList: true , scannedList: false , logList: false}) }} 
                                                  style={
                                                          this.state.unscannedList 
                                                          ? 
                                                            {flex: 2 , justifyContent: "center" , alignItems: "center" , backgroundColor: "#000" , color: "#fff" , borderRadius: 10 } 
                                                          : 
                                                            {flex: 2 , justifyContent: "center" , alignItems: "center"}
                                                          }>
                                  <Text style={{fontSize: 18 , fontWeight: "bold" , color: "#fff" , padding: 4}}>SCANNED</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {this.setState({unscannedList: false , scannedList: false , logList: true}) }} 
                                                  style={
                                                          this.state.logList 
                                                          ? 
                                                            {flex: 2 , justifyContent: "center" , alignItems: "center" , backgroundColor: "#000" , color: "#fff" , borderRadius: 10 } 
                                                          : 
                                                            {flex: 2 , justifyContent: "center" , alignItems: "center"}
                                                          }>
                                  <Text style={{fontSize: 18 , fontWeight: "bold" , color: "#fff" , padding: 4}}>SCAN LOG</Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                            <ScrollView>
                              {
                                this.state.scannedList &&
                                <React.Fragment>
                                {
                                  Order.unscanned_items && Order.unscanned_items.length > 0
                                  ?
                                    Order.unscanned_items.map((item , index) => {
                                      return(
                                          <View>
                                            {
                                              ( item && item.product_type === "single" ) || (item && item.product_type === "depends")
                                                ?
                                                <View key={index} style={{flexDirection: "row" , margin: 2 , borderRadius:30 , borderWidth: 4  , backgroundColor: "#336598"  , borderColor: "black" , padding:10}}>
                                                  <View style={{flex: 20}}>
                                                    <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)} 
                                                                      style={{backgroundColor: "#fff" , padding: 5 , borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                                      <Text numberOfLines={2} 
                                                            ellipsizeMode='tail'
                                                            style={item.name && item.name.length <= 32 ? {fontSize: 20, width: "75%"} : {fontSize: 16, width: "75%"}}>
                                                        {item.name}
                                                      </Text>
                                                    </TouchableOpacity>  
                                                    <Text numberOfLines={1}
                                                          ellipsizeMode='tail'
                                                          style={{color: "#fff" , fontSize: 20 , fontWeight: "600", width: "75%"}}
                                                          >
                                                            {item.sku}
                                                    </Text>
                                                    <View style={{backgroundColor: "#95b0e5" , paddingLeft: 3 , paddingRight: 3}}> 
                                                      <View style={{flexDirection: "row", width: "70%"}}>
                                                      {
                                                        item.location !== undefined && item.location !== "" &&
                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                            <Text>L1:</Text>
                                                            <Text numberOfLines={1}
                                                                  ellipsizeMode='tail'
                                                                  style={{fontSize: 20 , fontWeight: "bold" , paddingRight: 10}}
                                                                  >
                                                                    {item.location}
                                                            </Text>
                                                          </View>
                                                      }
                                                      {
                                                        item.custom_product_1 !== undefined && item.custom_product_1 !== "" && 
                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                            <Text>C1:</Text>
                                                            <Text numberOfLines={1}
                                                                  ellipsizeMode='tail'
                                                                  style={{fontSize: 20 , fontWeight: "bold"}}
                                                                  >
                                                                    {item.custom_product_1}
                                                            </Text>
                                                          </View>
                                                      }
                                                      </View>
                                                    </View>
                                                    <View style={{backgroundColor: "#d3def4" , padding: 3 , borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                                                      <Text style={{fontSize: 20 , width: "75%"}}
                                                            numberOfLines={1}
                                                            ellipsizeMode='tail'
                                                            >
                                                        {
                                                          item &&
                                                            item.barcodes.length > 0 &&
                                                              item.barcodes[0].barcode
                                                        }
                                                      </Text>
                                                    </View>
                                                  </View>
                                                  
                                                  <View style={{flex: 5 , borderBottomRightRadius: 12 , borderTopRightRadius: 12, position: "absolute" , right: 6, width: "25%" , height: "100%", bottom: 0 , top: 10}}>
                                                    <View style={{backgroundColor: "#fff" ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 8  , borderColor: "#336598"}}>
                                                        <Text style={{position: "absolute" , textAlign: "center", fontSize: 18 , fontWeight: "600" , backgroundColor: "#fff" , opacity: 0.7, bottom: 0 , right: 0 , left: 0 , marginLeft: "auto" , marginRight: "auto" , zIndex: 999}}>
                                                          {item.scanned_qty}/{item.qty_remaining+item.scanned_qty}
                                                        </Text>
                                                        <Image source={{uri: item.images.length > 0 ? item.images[0].image : ""}} 
                                                               style={{height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                                    </View>
                                                  </View>
                                                </View>
                                                :
                                                <View key={index} style={{margin: 2 , borderRadius: 30 ,borderWidth: 4 , borderColor: "black" , backgroundColor: "#3f5163" , padding:10}}>
                                                  <View style={{flexDirection: "row"}}>
                                                      <View style={{height: 5 , flex: 8 , backgroundColor: "#fff" , borderRadius: 3 , position: "relative"}}>
                                                        <Text style={{fontSize: 18 , fontWeight: "bold" , position: "absolute" , left: "50%" , top: -7 , marginLeft: "auto" , marginRight: "auto" , backgroundColor: "#3f5163" , paddingLeft: 4 , paddingRight: 4 , color: "#fff"}}>
                                                          KIT
                                                        </Text>
                                                      </View>
                                                      <View style={{flex: 2}}>
                                                        <Text style={{textAlign: "center" , color: "#fff" , fontSize: 18 , marginTop: -6 , fontWeight: "bold"}}>{item.scanned_qty}/{item.qty_remaining+item.scanned_qty}</Text>
                                                      </View>
                                                  </View>
                                                  <View style={{flexDirection: "row"}}>
                                                    <View style={{flex: 8}}>
                                                      <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}>
                                                        <Text numberOfLines={2}
                                                              ellipsizeMode='tail'
                                                              style={ item.name && item.name.length <= 32 ? {fontSize: 22} : {fontSize: 16} ,{ color: "#fff" , fontWeight: "600" , padding: 3 }}>
                                                          {item.name}
                                                        </Text>
                                                      </TouchableOpacity>  
                                                      <Text numberOfLines={1} ellipsizeMode='tail' style={{fontSize: 20 , color: "#fff" , fontWeight: "600" , padding: 3 }}>{item.sku}</Text>
                                                    </View>
                                                    {
                                                      item.images.length > 0 && item.images[0].image &&
                                                        <View style={{flex: 2 , borderRadius: 12 , borderWidth: 12  , borderColor: "#3f5163" , maxHeight: 80}}>
                                                          <Image source={{uri: item.images.length > 0 ? item.images[0].image : ""}} 
                                                                 style={{height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12 }} />
                                                        </View>
                                                    }
                                                    
                                                  </View>
                                                  {
                                                    item && item.child_items && item.child_items.length > 0 && item.child_items.map((childItem , index) => {
                                                        return(
                                                          <View key={index} style={{flexDirection: "row" , margin: 4 , backgroundColor: "#fff" , borderBottomRightRadius: 20 , borderTopRightRadius: 20}}>
                                                            <View style={{flex: 20}}>
                                                              <View style={{backgroundColor: "#fff"}}></View>
                                                              <TouchableOpacity onPress={() => this.props.redirectToItemDetail(childItem)}
                                                                                style={{backgroundColor: "#fff" , padding: 5 , paddingTop: 10 }}>
                                                                <Text numberOfLines={2}
                                                                      ellipsizeMode='tail'
                                                                      style={childItem.name && childItem.name.length <= 32 
                                                                              ? 
                                                                                {fontSize: 22 , width: "75%"}
                                                                              : 
                                                                                {fontSize: 16 , width: "75%"}}>
                                                                  {childItem.name}
                                                                </Text>
                                                              </TouchableOpacity>  
                                                              <Text numberOfLines={1} ellipsizeMode='tail' style={{backgroundColor: "#3f5163", color: "#fff" , fontSize: 20 , fontWeight: "600"}}>{childItem.sku}</Text>
                                                              <View style={{backgroundColor: "#a4b4c5" , height: 25}}>
                                                                <View style={{flexDirection: "row", width: "70%"}}>
                                                                  {
                                                                    childItem && 
                                                                      childItem.location !== null && 
                                                                        childItem.location !== "" &&
                                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                                            <Text>L1:</Text>
                                                                            <Text numberOfLines={1}
                                                                                  ellipsizeMode='tail'
                                                                                  style={{fontSize: 20 , fontWeight: "bold" , paddingRight: 10}}
                                                                                  >
                                                                                    {childItem.location}
                                                                            </Text>
                                                                          </View>
                                                                  }
                                                                  {
                                                                    childItem && 
                                                                      childItem.custom_product_1 !== null &&
                                                                        childItem.custom_product_1 !== "" &&
                                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                                            <Text>C1:</Text>
                                                                            <Text numberOfLines={1}
                                                                                  ellipsizeMode='tail'
                                                                                  style={{fontSize: 20 , fontWeight: "bold"}}
                                                                                  >
                                                                                    {childItem.custom_product_1}
                                                                            </Text>
                                                                          </View>
                                                                  }
                                                                </View>
                                                              </View>
                                                              <View style={{backgroundColor: "#cdd7e2" , padding: 3 , borderBottomRightRadius: 20}}>
                                                                <Text style={{ fontSize: 20, width: "75%"}}
                                                                      numberOfLines={1}
                                                                      >
                                                                    {
                                                                      childItem &&
                                                                        childItem.barcodes.length > 0 &&
                                                                          childItem.barcodes[0].barcode
                                                                    }
                                                                </Text>
                                                              </View>
                                                            </View>
                                                            <View style={{flex: 5 , borderBottomRightRadius: 12 , paddingTop: 10 ,  position: "absolute" , right: 0, width: "25%" , height: "100%", bottom: 0 , top: 0}}>
                                                              <View style={{backgroundColor: "#fff" ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 8  , borderColor: "#3f5163"}}>
                                                                  <Text style={{position: "absolute" , textAlign: "center", fontSize: 18 , fontWeight: "600" , backgroundColor: "#fff" , opacity: 0.7, bottom: 0 , right: 0 , left: 0 , marginLeft: "auto" , marginRight: "auto" , zIndex: 999}}>
                                                                    {childItem.scanned_qty}/{childItem.qty_remaining+childItem.scanned_qty}
                                                                  </Text>
                                                                  <Image source={{uri: childItem.images.length > 0 ? childItem.images[0].image : ""}} 
                                                                         style={{height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12}} />
                                                              </View>
                                                            </View>
                                                          </View>
                                                          )
                                                     })
                                                  }
                                                </View>
                                            }
                                          </View>
                                        )
                                    })
                                  :
                                    <View style={globalStyles.p_10}>
                                      <Text style={styles.scannedItemNameText}>
                                        No more item remaining to scan
                                      </Text>
                                    </View>
                                }
                                </React.Fragment>    
                              }
                              {
                                this.state.unscannedList &&
                                <React.Fragment>
                                  {
                                    Order.scanned_items && Order.scanned_items.length > 0
                                    ?
                                    Order.scanned_items.map((item , index) => {
                                      return(
                                          <View>
                                            {
                                              <View key={index} style={{flexDirection: "row" , margin: 2 , borderRadius:30 , borderWidth: 4  , backgroundColor: "#336598"  , borderColor: "black" , padding:10}}>
                                                <View style={{flex: 20}}>
                                                  <TouchableOpacity onPress={() => this.props.redirectToItemDetail(item)}
                                                                    style={{backgroundColor: "#fff" , padding: 5 , borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                                    <Text numberOfLines={2}
                                                          ellipsizeMode='tail'
                                                          style={ item.name && item.name.length <= 32 
                                                                  ? 
                                                                    {fontSize: 20 , width: "75%"}
                                                                  : 
                                                                    {fontSize: 16, width: "75%"}
                                                                }
                                                          >
                                                          {item.name}
                                                    </Text>
                                                  </TouchableOpacity>  
                                                  <Text numberOfLines={1}
                                                        ellipsizeMode='tail'
                                                        style={{color: "#fff" , fontSize: 20 , fontWeight: "600" , width: "75%"}}
                                                        >
                                                          {item.sku}
                                                  </Text>
                                                  <View style={{backgroundColor: "#95b0e5" , paddingLeft: 3 , paddingRight: 3}}> 
                                                    <View style={{flexDirection: "row", width: "70%"}}>
                                                      {
                                                        item && item.location !== null && item.location !== "" && 
                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                            <Text>L1:</Text>
                                                            <Text numberOfLines={1}
                                                                  ellipsizeMode='tail'
                                                                  style={{fontSize: 20 , fontWeight: "bold" , paddingRight: 10}}
                                                                  >
                                                                    {item.location}
                                                            </Text>
                                                          </View>
                                                      }
                                                      {
                                                        item && item.custom_product_1 !== null && item.custom_product_1 !== "" &&
                                                          <View style={{flex: 1 , flexDirection: "row" , alignItems: "baseline" , height: 25}}>
                                                            <Text>C1:</Text>
                                                            <Text numberOfLines={1}
                                                                  ellipsizeMode='tail'
                                                                  style={{fontSize: 20 , fontWeight: "bold"}}
                                                                  >
                                                                    {item.custom_product_1}
                                                            </Text>
                                                          </View>
                                                      }
                                                    </View>
                                                  </View>
                                                  <View style={{backgroundColor: "#d3def4" , padding: 3 , borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                                                    <Text style={{fontSize: 20 , width: "75%"}}
                                                          numberOfLines={1}
                                                          ellipsizeMode='tail'
                                                          >
                                                        {
                                                          item &&
                                                            item.barcodes.length > 0 &&
                                                              item.barcodes[0].barcode
                                                        }
                                                    </Text>
                                                  </View>
                                                </View>
                                                
                                                <View style={{flex: 5 , borderBottomRightRadius: 12 , borderTopRightRadius: 12 , position: "absolute" , right: 6, width: "25%" , height: "100%", bottom: 0 , top: 10}}>
                                                  <View style={{backgroundColor: "#fff" ,  shadowColor: "#000", shadowOffset: { width: -5, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 3, borderRadius:12 , borderWidth: 8  , borderColor: "#336598"}}>
                                                      <Text style={{position: "absolute" , textAlign: "center", fontSize: 18 , fontWeight: "600" , backgroundColor: "#fff" , opacity: 0.7, bottom: 0 , right: 0 , left: 0 , marginLeft: "auto" , marginRight: "auto" , zIndex: 999}}>
                                                        {item.scanned_qty}/{item.qty_remaining+item.scanned_qty}
                                                      </Text>
                                                      <Image source={{uri: item.images.length > 0 ? item.images[0].image : ""}} 
                                                             style={{height: "100%" , width: "100%" , resizeMode: 'contain' , borderRadius: 12}
                                                                    } />
                                                  </View>
                                                </View>
                                              </View>
                                            }
                                          </View>
                                        )
                                    })
                                    :
                                    <View style={globalStyles.p_10}>
                                      <Text style={styles.scannedItemNameText}>
                                        No Record
                                      </Text>
                                    </View>
                                  }  
                                </React.Fragment>  
                              }
                              {
                                this.state.logList &&
                                  <React.Fragment>
                                    {
                                      localLogs && localLogs.length > 0
                                      ?  
                                        localLogs && localLogs.slice(0).reverse().map((log , index)=> {
                                          return(
                                              <View key={index} style={[globalStyles.flexDirectionRow, {paddingLeft: 10 , paddingBottom: 0 , paddingTop: 5 , paddingRight: 10 , textAlign: "left" , borderBottomWidth: 1, borderBottomColor: "#000"}]}>
                                                <View style={styles.actionBox}>
                                                  <Text style={[styles.logDate, {color: "#000"}]}>
                                                    {moment(log.time).format('MMMM Do YYYY, h:mm:ss a')}
                                                  </Text>
                                                  {
                                                    log.event === "regular" &&
                                                    <View>
                                                      {
                                                        log.Log_count !== ""
                                                          ?
                                                          <View>
                                                            <Text style={[styles.logAction , {color: "#000"}]}>
                                                              {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                            </Text>
                                                            <Text style={[styles.logDate, {color: "#000"}]}>
                                                              {`Multibarcode count of ${log.Log_count} scanned for product ${log.SKU} `}
                                                            </Text>
                                                          </View>
                                                          :
                                                            log.actionBarcode 
                                                            ?
                                                              <Text style={[styles.logAction, {color: "#000"}]}>
                                                                  {`Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                              </Text>
                                                            :
                                                              <Text style={[styles.logAction, {color: "#000"}]}>
                                                                  {`INVALID SCAN - Product with barcode: ${log.input} and sku: ${log.SKU} scanned - by: ${log.name}`}
                                                              </Text>
                                                      }
                                                    </View>
                                                  }
                                                  {
                                                    log.event === "click_scan" &&
                                                      <Text style={[styles.logAction, {color: "#000"}]}>
                                                        {` Item with SKU: ${log.SKU} has been click scanned - by: ${log.name}`}
                                                      </Text>
                                                  }
                                                  {
                                                    log.event === "bulk_scan" &&
                                                      <Text style={[styles.logAction, {color: "#000"}]}>
                                                        {`Item ${log.SKU} scanned through Bulk Scan - by: ${log.name}`}
                                                      </Text>
                                                  }
                                                  {
                                                    log.event === "type_scan" &&
                                                      <View>
                                                        <Text style={[styles.logAction, {color: "#000"}]}>
                                                          {`Item with SKU: ${log.SKU} has been click scanned for a Type-In count - by: ${log.name}`}
                                                        </Text>
                                                        <Text style={[styles.logAction, {color: "#000"}]}>
                                                          {`Type-In count of ${log.count} entered for product ${log.SKU}`}
                                                        </Text>
                                                      </View>
                                                  }
                                                  {
                                                     log.event === "serial_scan" &&
                                                      <Text style={[styles.logAction, {color: "#000"}]}>
                                                        {`Product: ${log.SKU} Serial scanned: "check" - by: ${log.name}`}
                                                      </Text>
                                                  }   
                                                </View>
                                              </View>
                                            )
                                        })
                                      :
                                        <View style={globalStyles.p_10}>
                                          <Text style={styles.scannedItemNameText}>
                                            No Record
                                          </Text>
                                        </View>
                                    }
                                  </React.Fragment>
                              }
                            </ScrollView>
                          </View>
                        </View>
                    }
                  </View>
              </View>
            </View>
          }
      </View>
    );
  }
}  