import axios from 'axios';
import { PRODUCT_DETAIL , GET_ALL_PRODUCT , GET_SEARCH_PRODUCT , UPDATE_PRODUCT_INFO } from '../constants';
import { AsyncStorage } from 'react-native';

export function GetProductDetail(id) {
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'get',
          url: `${url}/products/${id}.json`,
          headers: {'Authorization': `Bearer ${access_token}`}
        })
        .then((data) => {
          dispatch({
                    type: PRODUCT_DETAIL,
                    payload: data.data.product
                  })
        })
        .catch((error) => {
        })
  }
}

export function GetAllProduct(data){
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'get',
          url: `${url}/products.json?filter=${data.filter}&sort=${data.sort}&order=${data.order}&is_kit=${data.is_kit}&limit=${data.limit}&offset=${data.offset}`,
          headers: {'Authorization': `Bearer ${access_token}`}
        })
        .then((res) => {
          dispatch({
                    type: GET_ALL_PRODUCT,
                    payload: res.data.products
                  })
        })
        .catch((error) => {
        })
  }
}

export function GetSearchProduct(data){
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
          method: 'get',
          url: `${url}/products/search.json?search=${data.search}&sort=${data.sort}&order=${data.order}&is_kit=${data.is_kit}&limit=${data.limit}&offset=${data.offset}`,
          headers: {'Authorization': `Bearer ${access_token}`}
        })
        .then((res) => {
          dispatch({
                    type: GET_SEARCH_PRODUCT,
                    payload: res.data.products
                  })
        })
        .catch((error) => {
        })
  }
}

export function UpdateProductInfo(ID, data) {
  return async(dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
            method: 'put',
            url: `${url}/products/${ID}.json`,
            headers: {'Authorization': `Bearer ${access_token}`},
            data: data
          })
          .then( async (res) => {
              dispatch({
                    type: UPDATE_PRODUCT_INFO,
                    payload: res.data,
                    time: new Date()
                  })
            })
          .catch((error) => {
          })
  }
}
