import React from 'react';
import Main from './src/components/main';
import { Provider } from 'react-redux';
import configureStore from './src/store/configureStore';

const store = configureStore()

export default function App(){
  return (
    <Provider store = {store}>
      <Main/>
    </Provider>
  );
}
