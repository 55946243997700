import React from 'react';
import { View , Image } from 'react-native';
import orderComplete from "../../../assets/_order_complete.png";
import scanFail from "../../../assets/_scan_fail_whoops.png";
import scanSuccess from "../../../assets/_scan_success.png";
import styles from '../../style/scanpack';

export default function ResponseView(props){
  return (
    <View style={styles.responseView}>
      <View style={styles.responseBox}>
        {
          props.orderCompleteAction &&
            <Image
              name="IMAGE_SUCCESS"
              style={styles.responseBox}
              source={orderComplete}
            />
        }    
        {
          props.scanSuccessAction && 
            <Image
              name="IMAGE_SUCCESS"
              style={styles.responseBox}
              source={scanSuccess}
            />
        }
        {
          props.scanFailAction && 
            <Image
              name="IMAGE_SUCCESS"
              style={styles.responseBox}
              source={scanFail} 
            />   
        }
      </View>
    </View>
  );
}
