import React, { Component , useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';

export default class TypeScanAlert extends Component{
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
  }
  componentDidUpdate(){
    this.typeScanFocus.current.focus()
  }

  render(){
    return (
      <View style={styles.typeScanContainer}>
        <View style={styles.typeScanBox}>
          <View style={globalStyles.flexDirectionRow}>
            <Text style={styles.scanCountText}>
              Type Scan Count
            </Text>
            <TouchableOpacity onPress={this.props.closeAlert}>
              <Text style={styles.scanCountCloseBtn}>
                X
              </Text>
            </TouchableOpacity>
          </View>
          <TextInput value={this.props.typeScanCount}
                     onChangeText={this.props.typeScanCountUpdate}
                     autoFocus={true}
                     ref={this.typeScanFocus}
                     onSubmitEditing={this.props.typeScanCount !== "" ? this.props.submitTypeScan :  ""}
                     style={styles.typeScanCountInput}>
          </TextInput>
          <TouchableOpacity onPress={this.props.typeScanCount !== "" ? this.props.submitTypeScan :  ""}
                            style={styles.scanCountSubmitBtnBox}>
            <Text style={styles.scanCountSubmitBtn}>
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
