import { SUBMIT_LOG } from '../constants';

const initialState = {};

 const saveLogReducer = (state = initialState, action) => {
  switch(action.type) {
    case SUBMIT_LOG:
      return { ...state, logs:action.payload , updateLog: true , time: action.time };
    default:
      return state;
  }
}

export default saveLogReducer;
