import React, { Component , useRef } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';

export default class ClickScanConfirmationPopUp extends Component{
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
  }

  componentDidUpdate(){
    // this.typeScanFocus.current.focus()
  }

  render(){
    return (
      <View style={styles.typeScanContainer}>
        <View style={styles.typeScanBox}>
          <View style={globalStyles.flexDirectionRow}>
            <Text style={styles.scanCountText}>
              Confirmation Code
            </Text>
            <TouchableOpacity onPress={this.props.closeAlert}>
              <Text style={styles.scanCountCloseBtn}>
                X
              </Text>
            </TouchableOpacity>
          </View>
          {
            this.props.errorMessageClickScanConfirmation &&
            <Text style={{padding: 5 , fontSize: 12 , color: "#fff" , fontWeight: "bold" , textAlign: "center"}}>
              Please enter valid confirmation code to continue
            </Text>
          }
          <TextInput value={this.props.clickScanConfirmInput}
                     onChangeText={this.props.handleClickScanConfirmInput}
                     placeholder="Please acknowledge with your confirmation code to continue"
                     autoFocus={true}
                     ref={this.props.serialFocus}
                     onSubmitEditing={this.props.clickScanConfirmFun}
                     style={styles.typeScanCountInput}>
          </TextInput>
          <TouchableOpacity onPress={this.props.clickScanConfirmFun}
                            style={styles.scanCountSubmitBtnBox}>
            <Text style={styles.scanCountSubmitBtn}>
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
